import { useApp } from "../helpers/app-context";
import { Chatbot } from "../pages/chatbot/Chatbot";
import { Sidebar } from "./Sidebar";
import { ReactComponent as ChatButtonIcon } from "../assets/icons/chat-button.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/menu.svg";
import { MobileSidebar } from "./MobileSidebar";
import { Logo } from "./Logo";
import { Notification } from "../pages/dashboard/components/Notification";
import { Navigation } from "./Navigation";
import { getUser } from "../helpers/utils";

const MobileHeader = ({ onMenuClick }) => {
  const user = getUser();
  return (
    <div className="h-[58px] fixed top-0 z-[9] left-0 w-full flex items-start justify-between px-5 shadow-[0px_4px_10px_0px_rgba(134,133,141,0.12)] bg-white">
      <div className="flex items-center gap-2.5">
        <button type="button" onClick={onMenuClick}>
          <MenuIcon />
        </button>
        <Logo className="!text-[18px] sm:!w-[22px] md:!text-[25px] !leading-0 !tracking-[-1.033px]" />
      </div>
      <div className="flex items-center gap-2.5">
        <Notification />
        <div className="flex items-center justify-center gap-2.5">
          <div className="h-6 justify-start items-center gap-2.5 flex">
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className="text-black text-[14px] font-semibold">
                {user?.firstName}
              </div>
            </div>
          </div>
          <div className="w-8 h-8 rounded-full bg-[#D8DEF6] flex items-center justify-center p-3">
            <span className="text-black">
              {user?.firstName?.[0]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export function Page({ children }: { children: React.ReactNode }) {
  const {
    setSidebarExpanded,
    sidebarExpanded,
    isChatBotOpen,
    setIsChatBotOpen,
    isMobileSidebarOpen,
    setIsMobileSidebarOpen,
  } = useApp() as {
    isMobileSidebarOpen: boolean;
    setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarExpanded: boolean;
    isChatBotOpen: boolean;
    setIsChatBotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  return (
    <div className="flex pt-[58px] bg-[#F9F7F6] lg:pt-0 w-full shadow-[0px_2px_4px_0px_#00000029]">
      <div className="lg:hidden">
        <MobileHeader onMenuClick={() => setIsMobileSidebarOpen((p) => !p)} />
      </div>
      <div className="hidden lg:block">
        <Sidebar
          sidebarExpanded={sidebarExpanded}
          setSidebarExpanded={setSidebarExpanded}
        />
      </div>
      {children}
      {isChatBotOpen && <Chatbot />}
      {!isChatBotOpen && (
        <button
          className="fixed bottom-3 right-3 w-fit"
          type="button"
          onClick={() => setIsChatBotOpen((p) => !p)}
        >
          <ChatButtonIcon />
        </button>
      )}
      {isMobileSidebarOpen && <MobileSidebar />}
    </div>
  );
}