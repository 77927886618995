import { useEffect, useMemo, useRef, useState } from "react";
import { Card } from "../../../../components/Card";
import CustomInput from "../../../../components/Input";
import { Rating } from "../../../../components/Rating";
import { ReactComponent as Shadow } from "../../../assets/images/onboarding-shadow.svg";
import { ActionButtons } from "../ActionButtons";
import { PriceInput } from "../../../../components/PriceInput";
import { Select } from "../../../../components/MultiSelect";
import { ChipInput } from "../../../../components/ChipInput";
import { HotelCard } from "./components/HotelCard";
import { Search } from "../../../../components/Search";
import { usePlaces } from "./usePlaces";
import { validateCompetitorSetForm1 } from "../../useValidation";
import { useOnboardingSave } from "../../useOnboardingSave";
import { calcCrow, getUser } from "../../../../helpers/utils";
import { useDebounce } from "../../../../helpers/useDebounce";
import Spinner from "../../../../components/Spinners";
import { api } from "../../../../helpers/api";

export function Screen1Form({ setForm, form, isReview = false }) {
  // const { searchText, setSearchText, loading, places } = usePlaces();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const placeRef = useRef<any>(null);
  const [placeList, setPlaces] = useState<any[]>([]);
  const [hotelData, setHotelData] = useState<any>({});
  const debounceSearch = useDebounce(searchText, 500);
  const user = getUser();

  // @ts-expect-error
  placeRef.current = window.google?.maps?.places;

  const handleAddHotel = (hotel) => {
    setForm((prev) => {
      const isHotelAlreadySelected = (prev.competitors || []).some(
        (selectedHotel) => selectedHotel.id === hotel.id
      );
      if (isHotelAlreadySelected) return prev;

      return {
        ...prev,
        competitors: [...(prev.competitors || []), hotel],
      };
    });
  };

  const handleRemoveHotel = (hotel) => {
    setForm((prev) => ({
      ...prev,
      competitors: (prev.competitors || []).filter(
        (selectedHotel) => selectedHotel.id !== hotel.id
      ),
    }));
  };

  const fetchPlaces = async (search = "") => {
    const hotel = await api.getHotelData(user?.id);
    //console.log("HOTEL DATA FROM  HOTEL", hotel);
    if (placeRef.current && placeRef.current.PlacesService) {
      
      const sourceLocation = {
        lat: hotel?.hotel?.latitude || "30.4191947",
        lng: hotel?.hotel?.longitude || "-87.2117292",
      };
      try {
        setLoading(true);
        const service = placeRef.current.Place.searchByText;
        // @ts-expect-error
        let center = new window.google.maps.LatLng(
          sourceLocation.lat,
          sourceLocation.lng
        );
        const request = {
          textQuery: search || "hotel",
          fields: ["displayName", "location", "photos", "formattedAddress"],
          includedType: "lodging",
          locationBias: {
            lat: center.lat(),
            lng: center.lng(),
          },
          language: "en-US",
          region: "us",
          useStrictTypeFiltering: false,
        };
        const { places } = await service(request);
        const list = places.map((place) => {
          const lat = place.location?.lat();
          const lng = place.location?.lng();
          return {
            id: place.id,
            name: place.displayName,
            imageUrl: place.photos?.[0]?.Eg,
            address: place.formattedAddress,
            latitude: lat,
            longitude: lng,
            distance: calcCrow(
              sourceLocation.lat,
              sourceLocation.lng,
              lat,
              lng
            ),
          };
        });
        setLoading(false);
        setPlaces(list);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const fetchHotelData = async () => {
    try {
      const res = await api.getHotelData(user?.id);
      setHotelData(res);
    } catch (error) {
     //console.log(error);
    }
  };

  useEffect(() => {
    fetchHotelData();
  }, []);

  useEffect(() => {
    if (hotelData) {
      fetchPlaces();
    }
  }, [hotelData]);

  useEffect(() => {
    fetchPlaces(debounceSearch);
    // setSearchText(debounceSearch);
  }, [debounceSearch]);

  const competitors = useMemo(() => {
    return form.competitors || [];
  }, [form]);

  return (
    <>
      <div className="flex flex-col gap-3 [&_label]:!text-neutral-700">
        {!isReview && (
          <div className="text-xl lg:text-2xl font-semibold">
            Competitive set
          </div>
        )}
        <p className=" text-neutral-700 text-sm leading-[22px]">
          For the best results, Please select 4-6 Hotels that you complete most
          closely
        </p>
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-0">
          <div className="flex-1 flex flex-col gap-[14px]">
            <div className="pr-3 relative [&_input]:pr-6">
              <Search
                value={searchText}
                onChange={(value) => setSearchText(value)}
              />
              {loading && (
                <Spinner className="!text-primary absolute right-6 top-3" />
              )}
            </div>
            <div className="flex flex-col gap-2 h-[calc(100dvh-450px)] pb-2 overflow-auto pr-3">
              {placeList
                .filter(
                  (hotel) =>
                    competitors.findIndex(
                      (selectedHotel) => selectedHotel.id === hotel.id
                    ) === -1
                )
                ?.map((hotel) => (
                  <HotelCard
                    key={hotel.id}
                    hotel={hotel}
                    onAdd={() => handleAddHotel(hotel)}
                  />
                ))}
            </div>
          </div>
          <div className="flex-1 flex flex-col bg-[#F6F9F8] border border-neutral-300 rounded-[8px] p-4 pr-0 gap-2.5">
            <h3 className="text-[13px] leading-[20.8px] text-black">
              Your selected competitors
            </h3>
            <div className="flex flex-col gap-2 pr-4 overflow-auto max-h-[calc(100dvh-450px)] lg:h-[calc(100dvh-450px)]">
              {competitors.map((hotel) => (
                <HotelCard
                  key={hotel.id}
                  hotel={hotel}
                  onRemove={() => handleRemoveHotel(hotel)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function Screen1({ onBack, onNext, setForm, form }) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const handleNext = async () => {
    onSave(form);
  };
  const { isValid, errors } = validateCompetitorSetForm1(form);

  return (
    <Card className="lg:max-w-[670px] lg:w-[670px] !p-6 lg:!p-8">
      <Screen1Form setForm={setForm} form={form} />

      <ActionButtons
        onBack={onBack}
        onNext={handleNext}
        loading={saving}
        disabled={!isValid}
      />
    </Card>
  );
}
