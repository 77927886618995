import { useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { useApp } from "../../helpers/app-context";
import { getUser } from "../../helpers/utils";
import { Notification } from "../dashboard/components/Notification";
import { CalendarView } from "./components/CalendarView";
import { Summary } from "./components/Summary";
import { PriceAdjustmentModal } from "./components/PriceAdjustmentModal";
import { api } from "../../helpers/api";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";

export function Calendar() {
  const queryClient = useQueryClient();
  const user = getUser();
  const [isPriceAdjustmentModalOpen, setIsPriceAdjustmentModalOpen] =
    useState(false);
  const { isChatBotOpen } = useApp() as { isChatBotOpen: boolean };

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const userId = user?.id;

  const fetchData = async (day) => {
    try {
      setIsLoading(true);
      const dayMetrics = await api.getDayMetrics(userId, {
        type: "Summary",
        date: moment(day).format("YYYY-MM-DD"),
      });
      console.log(dayMetrics, "daymetrics");
      setData(dayMetrics);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    }
  };

  const handleSelect = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  return (
    <Page>
      <div
        className={`pt-4 pb-8 pl-4 pr-4 md:h-[calc(100dvh-62px)] overflow-auto lg:h-[100dvh] flex-1 ${isChatBotOpen ? "w-[calc(100vw-350px)]" : ""
          } 1xl:w-[unset]`}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-[16px] lg:text-[18px] tall:lg:text-[20px] tall:xl:text-[20px] font-semibold text-black">Calendar View</h1>
          <div className="hidden lg:block">
            <Notification />
          </div>
        </div>
        <div className="w-[97%] mx-auto"></div>
        <div className="flex flex-col md:flex-row items-start gap-4 mt-3.5">
          <div className="w-full md:flex-1">
            <CalendarView onSelect={handleSelect} selectedDate={selectedDate} />
          </div>
          <div className="oveflow-y-auto max-h-[50vh] flex flex-col items-start justify-start">
            <Summary
              selectedDate={selectedDate}
              onOpenPriceAdjustmentModal={() =>
                setIsPriceAdjustmentModalOpen(true)
              }
              data={data}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {isPriceAdjustmentModalOpen && (
        <PriceAdjustmentModal
          selectedDate={selectedDate}
          onClose={() => setIsPriceAdjustmentModalOpen(false)}
          data={data}
          onUpdate={() => {
            queryClient.invalidateQueries({
              queryKey: ["calendarData"],
            });
            fetchData(selectedDate);
            console.log("UPDATED FOR PRICE MODAL", selectedDate, data);
          }}
        />
      )}
    </Page>
  );
}