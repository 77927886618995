import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";
import { Panel } from "../../../components/Panel";
import { MonthSelect } from "./MonthSelect";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { YearFilter } from "./YearFilter";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import useResponsive from "../../../helpers/useResponsive";
import Spinner from "../../../components/Spinners";

const getDates = (month?: string | number, year?: string | number) => {
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    month: `${currentMonth + 1}`,
    year: `${currentYear}`,
  };
};

// const COLORS = [
//   "#985A5C",
//   "#694A4D",
//   "#B6B6BD",
//   "#985A5C",
//   "#D4C9B7",
//   "#985A5C",
//   "#985A5C",
// ];
const COLORS = [  
  "#E0DAE5",
  "#E8CCBF",
  "#D87EDF",
  "#D7CECD",
  ]

const directColors = ["#e2e8e9", "#d7dedf", "#ccd4d5", "#c1cacc"]

const OtaColors = ["#d4ceda", "#c8c1cf", "#bbb3c4", "#aea5b9"]

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 2}
        outerRadius={outerRadius + 2}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, data }: any) => {
  const summaryData = payload?.[0]?.payload;
  const totalBookings = data?.bookingsCountByChannel?.reduce(
    (acc, curr) => acc + Number(curr.count || 0),
    0
  );
  const percentage = ((summaryData?.count / totalBookings) * 100).toFixed(0);
  return (
    <div className="z-[9999] p-2.5 bg-white rounded-lg shadow border border-[#f4f4f6] justify-start items-center gap-1.5 inline-flex">
      <div
        className="w-2 h-2 bg-[#2F3D37] rounded-lg"
        style={{
          backgroundColor: summaryData?.fill,
        }}
      />
      <div className="text-[#525253] text-xs font-medium font-['Inter']">
        {summaryData?.payload?.channel} ({percentage}%)
      </div>
    </div>
  );
};

export function SummaryChart() {
  const { is2XLDesktop, isQHD } = useResponsive();

  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [filter, setFilter] = useState<any>({ ...getDates() });
  const containerRef = useRef(null);
  const [data, setData] = useState<any>({});

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "SourceSummary",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      console.log("Seg metrics", metrics);
      setData(metrics);
    } catch (error) {
     //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (month: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(month, prev.year) }));
  };

  const handleYearChange = (year: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(prev.month, year) }));
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setActiveIndex(undefined);
      }
    }

    // Bind the event listener
    document.addEventListener("mouseover", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseover", handleClickOutside);
    };
  }, []);

  return (
    <Panel
      label="Segmentation"
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={filter.month}
            onChange={handleMonthChange}
          />
          <YearFilter
            disabled={false}
            value={filter.year}
            onChange={handleYearChange}
          />
        </div>
      }
      className="h-[310px] tall3:h-[360px] tall3XL:h-[410px] qhd1:h-[600px] flex flex-col tall3XL:[&_.header]:min-h-[50px]"
      contentClassName="h-full"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="grow-[1] flex flex-col h-full" ref={containerRef}>
          {/* <div className="border-b border-[#E5E7EB] w-[90%] mx-auto"></div> */}
          <div className="text-left w-full px-3 !pl-4 pt-1 text-[#8b8b91] text-2xs font-medium">
          {/* {data?.otaCommissionSaved < 0 ? "You spent " : "You saved "}
            <span
              className={`font-bold ${data?.otaCommissionSaved < 0
                  ? "text-[#3984FF]"
                  : "text-[#05c168]"
                }`}
            >
              {commaSeparatedNumber(Math.abs(data?.otaCommissionSaved) || 0, false)}
            </span>{" "}
            {data?.otaCommissionSaved < 0 ? "more " : "less "}
            in OTA comms. vs. last year  */}
          </div>

          <div className="relative w-full px-5 pb-4 h-full items-center flex justify-center">
            <div className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 flex-col justify-center items-center gap-[5px] flex">
              <div className="text-center text-black text-xl font-extrabold leading-none">
                {commaSeparatedNumber(
                  Number(data?.directBookingsPercentage || 0),
                  true
                )}
              </div>
              <div className="text-xs !text-[#000] font-medium">
                Direct Bookings
              </div>
              <div
                className={`px-[5px] pt-1 pb-[5px] text-right text-2xs leading-[11px] tracking-[-0.66px] rounded-[3px] border justify-center items-center flex ${data?.directBookingsPercentageChange < 0
                    ? "text-[#000] border-[#DACEC1] bg-[#EEE4D8]"
                    : "text-[#000] border-[#DACEC1] bg-[#EEE4D8]"
                  }`}
              >
                {/* {(data?.otaBookingsComparePercentage || 0) < 0
                  ? "-"
                  : (data?.otaBookingsComparePercentage || 0) > 0
                  ? "+"
                  : ""} */}
                {data?.directBookingsPercentageChange || 0}% vs STLY
              </div>
            </div>
            <PieChart
              width={isQHD ? 500 : is2XLDesktop ? 275 : 200}
              height={isQHD ? 500 : is2XLDesktop ? 275 : 200}
            >
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data?.bookingsCountByChannel || []}
                cx={isQHD ? 250 : is2XLDesktop ? 135 : 95}
                cy={isQHD ? 250 : is2XLDesktop ? 135 : 100}
                innerRadius={isQHD ? 150 : is2XLDesktop ? 90 : 60}
                outerRadius={isQHD ? 180 : is2XLDesktop ? 115 : 75}
                fill="#D4C9B7"
                paddingAngle={0}
                dataKey="count"
                //   startAngle={360}
                //   endAngle={0}
                direction="anticlockwise"
                onMouseEnter={(_, index) => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(undefined)}
              >
                {[...(data?.bookingsCountByChannel || [])]
                  //.sort((a, b) => (a.source === b.source ? 0 : a.source === "OTA" ? -1 : 1))
                  .map((entry, index) => (
                    console.log("ENTRY", entry),
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.channel === "Booking.com" ? "#E0DAE5" :
                      entry.channel === "Expedia" ? "#E8CCBF" :
                      entry.channel === "Website/Booking Engine" ? "#d7dedf" :
                      entry.channel === "Direct" ? "#d7dedf" :
                      entry.source === "OTA" ? OtaColors[index % OtaColors.length] :
                      entry.source === "Direct" ? directColors[index % directColors.length] :
                      "#D7CECD"}
                    strokeWidth={0}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip data={data} />} />
            </PieChart>
          </div>
        </div>
      )}
    </Panel>
  );
}
