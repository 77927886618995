import { useState } from "react";
import { getUser } from "../../helpers/utils";
import { useApp } from "../../helpers/app-context";
import { Page } from "../../components/Page";
import { Notification } from "../dashboard/components/Notification";
import { MarketingFunnel } from "./components/MarketingFunnel";
import { MarketingSummary } from "./components/MarketingSummary";
import { ChannelPerformance } from "./components/ChannelPerformance";
import { GuestReviewChart } from "./components/GuestReviewChart/GuestReviewChart";
import { FeederMarkets } from "./components/FeederMarkets/FeederMarkets";
import { TopTrafficChannels } from "./components/TopTrafficChannels";
import { TopReferralSources } from "./components/TopReferralSources";
import { WebsiteVisitor } from "./components/WebsiteVisitor";
import { AdwordsPerformance } from "./components/AdwordsPerformance";
import { WebsiteTrafficDevice } from "./components/WebsiteTrafficDevice";
import { WebsiteTrafficByTime } from "./components/WebsiteTrafficByDay";
import { MarketingSummaryShort } from "./components/MarketingSummaryShort";
import { MarketingPieChart } from "./components/MarketingPieChart";

export function Marketing() {
  const user = getUser();
  const [isPriceAdjustmentModalOpen, setIsPriceAdjustmentModalOpen] =
    useState(false);
  const { isChatBotOpen } = useApp() as { isChatBotOpen: boolean };
  return (
    <Page>
      <div
        className={`pr-8 pl-8 pt-4 pb-5 h-[calc(100vh-20px)] lg:h-[100vh] flex-1 h-[100%] overflow-auto gap-4 ${isChatBotOpen ? "w-[calc(100vw-50px)]" : ""
          } 1xl:w-[unset]`}

         //className={`pb-5 h-[calc(100vh-20px)] overflow-auto lg:h-[100vh] flex-1 ${isChatBotOpen ? "w-[calc(100vw-350px)]" : ""
        //  } 1xl:w-[unset]`} 
      >
        <MarketingSummaryShort />

        <div className="flex flex-col gap-4 mt-[1em]">
          <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4 h-[22.444444444444446vw] tall3xl:h-[26.444444444444446vw]">
          <div className="w-full h-full overflow-auto">
              <MarketingPieChart type="WebsiteTrafficByDevice" />
            </div>
            <div className="w-full h-full overflow-auto">
              <MarketingPieChart type="WebsiteTrafficByTime" />
            </div>
            <div className="w-full h-full overflow-auto">
              <MarketingPieChart type="VisitorDemographics" />
            </div>
            <div className="w-full h-full overflow-auto">
              <AdwordsPerformance />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4 items-start h-[22.444444444444446vw] tall3xl:h-[26.444444444444446vw]">
          <div className="w-full h-full overflow-auto">
              <MarketingPieChart type="TopTrafficChannels" />
            </div>
            <div className="w-full h-full overflow-auto">
              <MarketingPieChart type="PageViews" />
            </div>       
            <div className="w-full h-full overflow-auto">
              <GuestReviewChart />
            </div>
            <div className="w-full h-full overflow-auto">
              <FeederMarkets />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}