import { Link } from "react-router-dom";
import { ColorChip } from "../../../components/ColorChip";
import { Panel } from "../../../components/Panel";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import {
  commaSeparatedNumber,
  getNumberWithSign,
  getPercentage,
} from "../../../helpers/utils";
import moment from "moment";
import Spinner from "../../../components/Spinners";

function CompetitorView({
  hotelName,
  rate,
  varRatePercentage,
  type,
  className,
}) {
  const growth = commaSeparatedNumber(Number(varRatePercentage || 0), true);
  const price =
    typeof rate === "number" ? commaSeparatedNumber(rate, false) : 0;

  return (
    <div
      className={`self-stretch py-0.5 border-b border-[#f4f4f6] justify-start items-center flex ${className}`}
    >
      <div className="w-[154px] text-ellipsis overflow-hidden whitespace-nowrap h-8 p-3 justify-start items-center gap-2.5 flex">
        <div className="text-right text-[#000] text-2xs font-medium  leading-[11px]">
          {hotelName}
        </div>
      </div>
      <div className="px-2.5 py-3 flex-1 justify-center items-center gap-2.5 flex">
        <div className="text-[#000] text-2xs font-medium  leading-[11px]">
          {price}
        </div>
      </div>
      <div className="flex-1 flex justify-end pr-3">
        <ColorChip
          text={`${growth === "-" ? "-" : `${growth} vs. Base Price`}`}
          type={type}
          className="min-w-[112px] !px-[2px]"
        />
      </div>
    </div>
  );
}

export function DailySummary({ date, data, isLoading, onClose = () => {} }) {
  const handleClose = () => {
    console.log('Close button clicked'); // Debug log
    onClose();
  };

  return (
    <Panel
      actions={
        <div className="flex items-center gap-2">
          <div className="text-center text-[#8b8b91] text-2xs font-medium leading-[11px]">
            {moment(date).format("MMM DD, YYYY")}
          </div>
          <button
            onClick={handleClose}
            className="p-2 hover:bg-gray-100 rounded-full"
            aria-label="Close daily summary"
            type="button"
          >
            <CloseIcon className="w-4 h-4" />
          </button>
        </div>
      }
      label="Daily Summary"
      className="fixed md:relative top-0 bottom-0 left-0 right-0 z-[999] shadow-lg !bg-[#F9F7F6] md:w-[480px]"
      contentClassName="h-[calc(100vh-100px)] md:h-[unset]"
    >
      {isLoading ? (
        <div className="flex justify-center h-full items-center md:w-[324px] md:h-[300px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <>
          <div className="p-3 w-full grid grid-cols-3 items-center gap-2">
            <div className="flex flex-col justify-start items-center gap-2.5 bg-white rounded-[8px] p-4 border border-[#E6E6E6]">
              <div className="text-[#000] text-xs font-medium">Occupancy</div>
              <div className="text-black text-base font-bold leading-normal">
                {commaSeparatedNumber(data?.totalOccupancy || 0, true)}
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-2.5 bg-white rounded-[8px] p-4 border border-[#E6E6E6]">
              <div className="text-[#000] text-xs font-medium">ADR</div>
              <div className="text-black text-base font-bold leading-normal">
                {commaSeparatedNumber(data?.adr || 0, false)}
              </div>
            </div>
            <div className="flex flex-col justify-end items-center gap-2.5 bg-white rounded-[8px] p-4 border border-[#E6E6E6]">
              <div className="text-[#000] text-xs font-medium">Revenue</div>
              <div className="text-black text-base font-bold leading-normal">
                {commaSeparatedNumber(data?.totalRevenue || 0, false)}
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start items-start flex max-h-[30vh] overflow-auto">
            <div className="w-full py-0.5 border-b border-[#f4f4f6] justify-start items-center flex">
              <div className="w-[154px] p-3 justify-start items-center gap-2.5 flex">
                <div className="text-[#000] text-2xs font-medium  leading-[11px]">
                  Avg. Days before Arrival
                </div>
              </div>
              <div className="px-2.5 py-3 justify-center flex-1 items-center gap-2.5 flex">
                <div className="text-[#000] text-2xs font-medium  leading-[11px]">
                  {(data?.avgLengthArrival || 0) === 0
                    ? "-"
                    : `${data?.avgLengthArrival || 0} days`}
                </div>
              </div>
              <div className="flex-1 flex justify-end pr-3">
                <ColorChip
                  text={
                    getNumberWithSign(
                      Number(data?.avgLengthArrivalPercentagePrevYear || 0),
                      true
                    ) === "-"
                      ? "-"
                      : `${getNumberWithSign(
                          Number(data?.avgLengthArrivalPercentagePrevYear || 0),
                          true
                        )} vs STLY`
                  }
                  type={
                    Number(data?.avgLengthArrivalPercentagePrevYear || 0) === 0
                      ? "grey"
                      : data?.avgLengthArrivalPercentagePrevYear < 0
                      ? "purple"
                      : "green"
                  }
                  className="min-w-[72px]"
                />
              </div>
            </div>
            <div className="w-full py-0.5 border-b border-[#8b8b91] justify-start items-center flex">
              <div className="w-[154px] p-3 justify-start items-center gap-2.5 flex">
                <div className="text-[#000] text-2xs font-medium  leading-[11px]">
                  Avg. Length of Stay
                </div>
              </div>
              <div className="px-2.5 py-3 justify-center flex-1 items-center gap-2.5 flex">
                <div className="text-[#000] text-2xs font-medium  leading-[11px]">
                  {(data?.avgLengthStay || 0) === 0
                    ? "-"
                    : `${data?.avgLengthStay || 0} days`}
                </div>
              </div>
              <div className="flex-1 flex justify-end pr-3">
                <ColorChip
                  text={
                    getNumberWithSign(
                      Number(data?.avgLengthStayPercentagePrevYear || 0),
                      true
                    ) === "-"
                      ? "-"
                      : `${getNumberWithSign(
                          Number(data?.avgLengthStayPercentagePrevYear || 0),
                          true
                        )} vs STLY`
                  }
                  type={
                    Number(data?.avgLengthArrivalPercentagePrevYear || 0) === 0
                      ? "grey"
                      : data?.avgLengthStayPercentagePrevYear < 0
                      ? "purple"
                      : "green"
                  }
                  className="min-w-[72px]"
                />
              </div>
            </div>

            <div className="w-full py-0.5 border-b border-[#8b8b91] justify-start items-center flex">
              <div className="w-[154px] p-3 justify-start items-center gap-2.5 flex">
                <div className="text-right text-[#646466] text-2xs font-bold leading-[11px]">
                  My Base Room Price
                </div>
              </div>
              <div className="px-2.5 flex-1 py-3 justify-center items-center gap-2.5 flex">
                <div className="text-[#646466] text-2xs font-bold leading-[11px]">
                  {commaSeparatedNumber(data?.baseRoomTypeRate || 0, false)}
                </div>
              </div>
              <div className="flex-1 pr-3" />
            </div>
            {(data?.competitorPrices || [])?.map((competitor, i) => (
              <CompetitorView
                key={i}
                {...competitor}
                className={
                  (data?.competitorPrices || []).length - 1 === i
                    ? "!border-b-[#8B8C91]"
                    : ""
                }
                type={
                  Number(competitor.ratePercentagePrevYear || 0) === 0
                    ? "grey"
                    : competitor.ratePercentagePrevYear < 0
                    ? "purple"
                    : "green"
                }
              />
            ))}
          </div>
          <div className="w-full flex-col pt-2 pb-2 justify-center items-center gap-2 flex">
            <div className="text-center">
              <span className="text-neutral-700 text-xs font-medium leading-3">
                See & edit more details in the{" "}
              </span>
              <Link
                to={`/calendar?date=${moment(date).format("YYYY-MM-DD")}`}
                className="text-[#2F3D37] text-xs font-medium leading-3"
              >
                Calendar View
              </Link>
            </div>
            <div className="text-2xs px-3 flex flex-wrap text-[#333134] tracking-[-0.11px] italic">
              Note: this year {moment(date).format("MMMM DD")} is a{" "}
              {data?.currentYearDay}, but last year it fell on a{" "}
              {data?.lastYearDay}.
            </div>
          </div>
        </>
      )}
    </Panel>
  );
}