import { useMediaQuery } from "react-responsive";

export default function useResponsive() {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isXLDesktop = useMediaQuery({ minWidth: 1280 });
  const is1XLDesktop = useMediaQuery({ minWidth: 1440 });
  const is2XLDesktop = useMediaQuery({ minWidth: 1536 });
  const is2KDesktop = useMediaQuery({ minWidth: 2200 });
  const isMobileLandScape = useMediaQuery({ maxWidth: 767 });
  const isTall2 = useMediaQuery({ maxHeight: 800 });
  const isTall2XLDesktop = useMediaQuery({ maxHeight: 900 });
  const isTall3XLDesktop = useMediaQuery({ minHeight: 1000, minWidth: 1920 });
  const isQHD = useMediaQuery({ minHeight: 1200, minWidth: 2260 });
  const isQHD2 = useMediaQuery({ minHeight: 1280, minWidth: 2560 });

  return {
    isDesktop,
    isTablet,
    isMobile,
    isXLDesktop,
    is1XLDesktop,
    is2XLDesktop,
    isMobileLandScape,
    isTall2XLDesktop,
    isAnyMobile: isMobile || isMobileLandScape,
    isSmallDevice: isMobile || isTablet || isMobileLandScape,
    isQHD,
    isQHD2,
    isTall3XLDesktop,
    isTall2,
    is2KDesktop,
  };
}
