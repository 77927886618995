import React, { useEffect, useMemo, useState } from "react";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { api } from "../../../../helpers/api";
import {
  formatNumber,
  getPercentage,
  getUser,
} from "../../../../helpers/utils";
import { YearFilter } from "../../../dashboard/components/YearFilter";
import Spinner from "../../../../components/Spinners";
import { MonthSelect } from "../../../dashboard/components/MonthSelect";

const getDates = (month?: string | number, year?: string | number) => {
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    month: `${currentMonth + 1}`,
    year: `${currentYear}`,
  };
};

export function FeederMarkets() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [filter, setFilter] = useState<any>({
    ...getDates(),
    type: "adr_index",
  });

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "FeederMarket",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const response = await api.getAnalytics(userId, {
        startDate: filter.startDate,
        endDate: filter.endDate,
        analyticsType: "FeederMarkets"
      });
      setData(response || {});
    } catch (error) {
     //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (month: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(month, prev.year) }));
  };

  const handleYearChange = (year: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(prev.month, year) }));
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const markets = useMemo(() => {
    return data?.map((market: any) => ({
      country: market?.country,
      region: market?.region,
      city: market?.city,
      sessions: market?.sessions
    }));
  }, [data]);

  return (
    <Panel
      label="Top Feeder Markets"
      className="h-full flex flex-col"
      contentClassName="h-[calc(100%-50px)] tall3:h-full"
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={filter.month}
            onChange={handleMonthChange}
          />
          <YearFilter
            filter={filter}
            setFilter={setFilter}
            disabled={false}
            onChange={handleYearChange}
            value={filter.year}
          />
        </div>
      }
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[350px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="h-full overflow-y-auto overscroll-none max-h-[40vh] tall3XL:max-h-[unset] mb-2 tall3XL:pb-[unset] tall3XL:overflow-[unset]">
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />

            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 text-neutral-800 bg-neutral-300 [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 ">
                <td className="text-center !text-[#000]">City</td>
                <td className="text-center !text-[#000]">Region</td>
                <td className="text-center !text-[#000]">Country</td>
                
                
                <td className="text-center !text-[#000]">Visitors</td>
              </tr>
            </thead>
            <tbody>
              {markets.map((market) => (
                <tr
                  key={market.city}
                  className="text-2xs [&_td]:text-neutral-700 [&_td]:py-3.5  [&_td]:border-b [&_td]:border-b-[#F4F4F6]"
                >
                  <td className="tracking-[-0.44px] text-center">
                    {market.city || "-"}
                  </td>
                  <td className="tracking-[-0.44px] text-center">
                    {market?.region || "-"}
                  </td>
                  <td className="tracking-[-0.44px] text-center">
                    {market?.country || "-"}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                    {market.sessions}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Panel>
  );
}
