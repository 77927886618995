import { useState } from "react";
import {
  DropdownIndicatorProps,
  default as ReactSelect,
  components,
  Props,
} from "react-select";
import { Checkbox } from "./Checkbox";
import { ReactComponent as CaretDownIcon } from "../assets/icons/caret-down.svg";
import { ReactComponent as CheckMarkIcon } from "../assets/icons/check-mark.svg";

const SingleOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-2.5">
        <label className="text-[#1D1E1B] text-sm">{props.label}</label>
        {props.isSelected && (
          <CheckMarkIcon className="[&_path]:stroke-primary w-3 h-3" />
        )}
      </div>
    </components.Option>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="flex items-center gap-2">
          <Checkbox
            name="selct"
            id="select"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M3.08301 3.0835L8.91664 8.91713"
          stroke="#8B8C91"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.91699 3.0835L3.08336 8.91713"
          stroke="#8B8C91"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.MultiValueRemove>
  );
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export function Select({
  isMulti = true,
  value,
  onChange,
  data,
  ...filterProps
}: {
  isMulti?: boolean;
  value: unknown;
  onChange: (value: unknown) => void;
  data: { value: string; label: string }[];
} & Props) {
  const [state, setState] = useState({ optionSelected: null });

  const handleChange = (selected) => {
    setState({
      optionSelected: selected,
    });
  };

  return (
    <div>
      <ReactSelect
        classNames={{
          control: (state) =>
            state.isFocused
              ? "!border !shadow-none !border-primary"
              : "border-neutral-400",
          option: (state) =>
            `cursor-pointer !bg-transparent ${
              state.isSelected
                ? "!bg-transparent !text-black hover:!bg-neutral-400"
                : "hover:!bg-neutral-400"
            }`,
          multiValue: () =>
            "!rounded !bg-[#F6F9F8] border border-[#F6F9F8] !h-6 !items-center !text-neutral-700 !text-2xs [&>div]:!text-2xs",
          singleValue: () => "!text-sm",
          multiValueRemove: () =>
            "!bg-transparent hover:[&_path]:!stroke-primary",
          indicatorSeparator: () => "hidden",
        }}
        options={data}
        isMulti={isMulti}
        closeMenuOnSelect={isMulti ? false : true}
        hideSelectedOptions={false}
        isClearable={false}
        components={{
          Option: isMulti ? Option : SingleOption,
          MultiValueRemove,
          DropdownIndicator,
        }}
        onChange={(value) => onChange(value)}
        value={value}
        // Hide dropdown list  when select any item
        // closeMenuOnSelect={true}

        //Selected Item Remove in dropdown list
        // hideSelectedOptions={true}
        {...filterProps}
      />
    </div>
  );
}
