export const gradientColors = [
  {
    id: 1,
    stroke: "#D7DEDF",
    stop1: "#D7DEDF",
    stop2: "#EEF1F1",
    bg: "rgba(236, 243, 255, 1)",
    textStyle: "text-[#000]",
  },
  {
    id: 2,
    stroke: "#E0DAE5",
    stop1: "#E0DAE5",
    stop2: "#F2F0F4",
    bg: "rgba(237, 249, 242, 1)",
    textStyle: "text-[#000]",
  },
  {
    id: 3,
    stroke: "rgba(182, 182, 189, 1)",
    stop1: "#E3DEDC",
    stop2: "#EFEDED",
    bg: "rgba(244, 244, 246, 1)",
    textStyle: "text-[#000]",
  },
  {
    id: 4,
    stroke: "#FFC0DF",
    stop1: "#FFE0F0",
    stop2: "rgba(255, 239, 247, 0.00)",
    bg: "rgba(255, 239, 247, 1)",
    textStyle: "text-[#0B252A]",
  },
];