import { Card } from "../../../../components/Card";
import { ActionButtons } from "../ActionButtons";
import { useEffect, useState } from "react";
import { getUser } from "../../../../helpers/utils";
import { api } from "../../../../helpers/api";
import { ShowToast } from "../../../../components/toast";
import { OnboardingSidebar } from "../OnboardingSidebar";
import { OnboardingView } from "../OnboardingView";
import { Checkbox } from "../../../../components/Checkbox";
import Spinner from "../../../../components/Spinners";
import moment from "moment";

export function OtaRates({ onBack, onNext, form, setForm, step }) {
  const [rates, setRates] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);
  const user = getUser();
  const [loading, setLoading] = useState(false);
    

  useEffect(() => {
    //Initializaiton of otaRatePlans if they dont exists
    if (!form.otaRatePlans) {
      setForm(prev => ({
        ...prev,
        otaRatePlans: []
      }));
    }
   //console.log("Form Ota Rates",form);
    // Fire and forget sync bookings call
    
    
   //console.log("Past Sync Bookings");
    setLoading(true);
    api
      .getHotelData(user?.id)
      .then((res) => {
       //console.log(res);
        setRates([...(res?.ratePlans || [])]);
        setLoading(false);
      })
      .catch((error) => {
       //console.log(error);
        setLoading(false);
      });
  }, [user?.id]);

  const handleBack = () => {
    onBack();
  };

  const handleSkip = () => {
    onNext();
  };

  const handleNext = async () => {
    try {
     //console.log("Form HandleNext",form);
      
      if (form.otaRatePlans.length > 0) {
        setSaving(true);
        const response = await api.putUserProfile(user.id, {
          otaRatePlans: form.otaRatePlans,
        });
        setSaving(false);
        if ([200, 201].includes(response.status)) {
          onNext();
        } else {
          ShowToast({
            type: "error",
            message: "Something went wrong",
          });
        }
      }
     //console.log("Past If",form);
    } catch (error: any) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <>
      <OnboardingSidebar title="Rate plans">
        <p className="text-xs leading-[16.8px] text-black mt-2">
          Select the rate plans that control your room prices on OTAs (e.g.,
          Booking.com, Expedia). These rates will allow us to send optimal,
          competitive pricing to OTAs, ensuring your direct booking rates remain
          more attractive.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Rate plans"
        infoView={
          <>
            <p className="text-xs leading-[16.8px] text-black mt-2">
              We need to connect to your PMS to seamlessly gather & sync real
              time information
            </p>
          </>
        }
      >
        <Card className="lg:max-w-[670px] lg:w-[650px]">
          <div className="flex flex-col">
            <span className="text-2xl font-semibold text-black">
              Rate plans
            </span>
            <span className="text-sm text-[#646466]">
              Please select the rate plans that control room prices for OTAs.
            </span>
          </div>
          <div className="flex flex-col gap-2 my-5 max-h-[300px] overflow-y-auto">
            {loading && (
              <div className="flex justify-center items-center">
                <Spinner className="!text-primary" />
              </div>
            )}
            {!loading && rates.length === 0 && (
              <div className="h-[153px] rounded-[7px] border border-[#E5E7EB] flex-col justify-center items-center flex">
                <div className="text-center">
                  <span className="text-[#646466] text-sm font-normal font-['Inter'] leading-snug">
                    No rate plan added to your PMS.{" "}
                  </span>
                  <a
                    href="https://docs.google.com/document/d/15000000000000000000000000000000000000000000000000/edit?usp=sharing"
                    target="_blank"
                    className="text-[#2F3D37] text-sm font-medium font-['Inter'] leading-snug"
                    rel="noreferrer"
                  >
                    Learn how to add one.
                  </a>
                </div>
              </div>
            )}
            {rates.map((rate) => (
              <div
                key={rate.id}
                onClick={(e) => {

                  setForm((prev) => {
                    
                    if (prev.otaRatePlans.includes(rate.pmsId)) {
                      return {
                        ...prev,
                        otaRatePlans: prev.otaRatePlans.filter(
                          (name) => name !== rate.pmsId
                        ),
                      };
                    }
                    return {
                      ...prev,
                      otaRatePlans: [...prev.otaRatePlans, rate.pmsId],
                    };
                  });
                }}
                className="h-11 w-full p-3 bg-gradient-to-b from-white to-[#fdfdfd] rounded-lg shadow border border-[#E5E7EB] justify-start items-center gap-2.5 inline-flex"
              >
                <div
                  className="justify-start items-center gap-2 flex"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    checked={form.otaRatePlans.includes(rate.pmsId)}
                    onChange={(e) => {
                      setForm((prev) => {
                        
                        if (prev.otaRatePlans.includes(rate.pmsId)) {
                          return {
                            ...prev,
                            otaRatePlans: prev.otaRatePlans.filter(
                              (pmsId) => pmsId !== rate.pmsId
                            ),
                          };
                        }
                        return {
                          ...prev,
                          otaRatePlans: [...prev.otaRatePlans, rate.pmsId],
                        };
                      });
                    }}
                   
                    name={rate.pmsId}
                    id={rate.pmsId}
                  />
                  <div className="text-[#1d1e1b] text-sm font-normal font-['Inter'] leading-tight">
                    {rate.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <ActionButtons
            onBack={handleBack}
            onNext={handleNext}
            disabled={saving || form?.otaRatePlans?.length === 0}
            onSkip={handleSkip}
            
            loading={saving}
          />
        </Card>
      </OnboardingView>
    </>
  );
}
