import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { calendarTypes } from "../consts";
import { TypeTag } from "./TypeTag";
import { useEffect, useRef, useState } from "react";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { useQuery } from "@tanstack/react-query";

export const typeStyles = {
  specialEvent: "bg-[#FAF3F0]",
  priceOverride: "bg-[#E8F1F2]",
};
export const typeBarStyles = {
  specialEvent: "bg-[#D5E1E2]",
  priceOverride: "bg-[#D5E1E2]",
};

const localizer = momentLocalizer(moment);

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    // convert by view month
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev", toolbar.date);
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const renderBackButton = () => {
    return (
      <button
        className="border bg-[#F5F4EE] hover:opacity-70 rounded border-[#D8D3DD] h-6 w-6 shadow-[0px_2px_15px_0px_rgba(33,31,84,0.06)] flex justify-center items-center"
        onClick={goToBack}
        type="button"
      >
        <RightArrow className="rotate-180 w-4 h-4 [&_path]:stroke-black" />
      </button>
    );
  };

  const renderNextButton = () => {
    return (
      <button
        className="border bg-[#F5F4EE] hover:opacity-70 rounded border-[#D8D3DD] h-6 w-6 shadow-[0px_2px_15px_0px_rgba(33,31,84,0.06)] flex justify-center items-center"
        onClick={goToNext}
        type="button"
      >
        <RightArrow className="w-4 h-4 [&_path]:stroke-black" />
      </button>
    );
  };

  return (
    <div className="flex lg:flex-row flex-col justify-between items-center px-4 lg:px-5 pb-4 flex-wrap gap-4">
      <div className="w-full lg:w-auto flex flex-1 lg:flex-none justify-between items-center gap-4">
        <div className="lg:hidden">{renderBackButton()}</div>
        <div className="flex flex-col">
          <div className="text-[#161938] text-base font-semibold leading-normal">
            {toolbar.localizer.format(toolbar.date, "MMM YYYY")}
          </div>
        </div>
        <div className="lg:hidden">{renderNextButton()}</div>
        <div className="hidden lg:flex items-center gap-1.5">
          {renderBackButton()}
          {renderNextButton()}
        </div>
      </div>
      <div className="w-full lg:w-auto grid grid-cols-2 lg:flex lg:flex-wrap gap-2">
        {Object.values(calendarTypes).map((type) => (
          <TypeTag key={type} type={type} />
        ))}
      </div>
    </div>
  );
};

const CustomDateCellWrapper =
  ({ data }, handleSelect, selectedDate, selectedMonthDate) =>
  ({ children, ...rest }, ...props) => {
    const cellDate = moment(rest.value);
   //console.log("CUSTOM CELL WRAPPER", data, selectedDate);
    const hasEvent = data?.find((event) => {
      const eventDate = moment(event.date);
      return eventDate.isSame(cellDate, "day");
    });
   //console.log("HAS EVENT", hasEvent, selectedDate, selectedMonthDate);
    const isCurrentMonth = cellDate.isSame(selectedMonthDate, "month");
    const isSelected = cellDate.isSame(selectedDate, "day");
   //console.log("HAS PRICE EVENT", hasEvent, cellDate.format("YYYY-MM-DD"), rest, selectedDate, selectedMonthDate);
    return (
      <div
        className={`relative rbc-day-bg cursor-pointer ${
          hasEvent?.isPriceOverride
            ? typeStyles["priceOverride"]
            : hasEvent?.isSpecialEvent
            ? typeStyles["specialEvent"]
            : ""
        }`}
        onClick={() => handleSelect(cellDate.format("YYYY-MM-DD"))}
      >
        {children}
        <div
          className={`absolute right-1.5 top-1.5 flex justify-center items-center font-medium text-2xs w-6 h-6 ${
            isCurrentMonth
              ? isSelected
                ? "text-white bg-black p-1.5 rounded"
                : "text-black"
              : "text-black/20"
          }`}
        >
          {cellDate.format("D")}
        </div>
        {hasEvent?.isPriceOverride && (
          <>
            <div
              className={`absolute top-0 left-0 w-[2px] h-full ${
                typeBarStyles.priceOverride || ""
              }`}
            />
          </>
        )}
        {hasEvent?.isSpecialEvent && (
          <>
            <div
              className={`absolute top-0 w-[2px] h-full ${
                hasEvent?.isPriceOverride ? "left-2.5" : "left-0"
              } ${typeBarStyles.specialEvent || ""}`}
            />
            {hasEvent?.isPriceOverride && (
              <div
                className={`absolute top-0 right-0 left-3 h-full ${typeStyles["specialEvent"]}`}
              />
            )}
          </>
        )}
      </div>
    );
  };

export function CalendarView({ onSelect: handleSelect, selectedDate }) {
  const user = getUser();
  const isCalled = useRef(false);
  const [selectedMonthDate, setSelectedMonthDate] = useState(moment().format());
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(null);

  const startDate = moment(selectedMonthDate)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment(selectedMonthDate).endOf("month").format("YYYY-MM-DD");

  const { data, isLoading } = useQuery({
    queryKey: ["calendarData", startDate, endDate],
    queryFn: () => api.getCalendarData(user.id, startDate, endDate),
    enabled: !!user?.id,
  });

  const calendarData = data || [];
 //console.log("CALENDAR DATA", calendarData);
  const handleDateSelect = (date) => {
    const selectedEvent = calendarData.find((event) => 
      moment(event.date).isSame(moment(date), 'day')
    );
   //console.log("SELECTED DATE HERE HERE", date, selectedEvent);
    if (selectedEvent?.isPriceOverride) {
     //console.log("SELECTED EVENT", "WORKING");
      setSelectedEventData(selectedEvent);
      setShowPricingModal(true);
    }

    handleSelect(date);
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        views={["month"]}
        defaultView="month"
        onNavigate={(date) => setSelectedMonthDate(date)}
        selected={selectedDate}
        components={{
          toolbar: (toolbar) => {
            setTimeout(() => {
              if (!isCalled.current && toolbar) {
                toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                toolbar.onNavigate("next");
                isCalled.current = true;
              }
            }, 100);
            return CustomToolbar(toolbar);
          },
          dateCellWrapper: CustomDateCellWrapper(
            {
              data: [...calendarData],
            },
            handleDateSelect,
            selectedDate,
            selectedMonthDate
          ),
        }}
        formats={{
          monthHeaderFormat: "MMM YYYY",
        }}
        style={{ height: "calc(100dvh - 110px)" }}
      />
      
      {/* {showPricingModal && selectedEventData && (
        <div className="fixed right-0 top-0 h-full w-96 bg-white shadow-lg">
          <button 
            className="absolute top-4 right-4"
            onClick={() => setShowPricingModal(false)}
          >
            Close
          </button>
          <div className="p-4">
            <h2>Pricing Adjustment</h2>
            {/* Add your pricing adjustment form/content here 
          </div>
        </div>
      )} */}
    </div>
  );
}
