import { useCallback, useEffect, useRef, useState } from "react";
import { Panel } from "../../../components/Panel";
import { MonthSelect } from "./MonthSelect";
import moment from "moment";
import { ColorChip } from "../../../components/ColorChip";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { YearFilter } from "./YearFilter";
import Spinner from "../../../components/Spinners";
import useResponsive from "../../../helpers/useResponsive";

const getDates = (month?: string | number, year?: string | number) => {
 //console.log("getDates DRS", month, year);
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    month: `${currentMonth + 1}`,
    year: `${currentYear}`,
  };
};

export function DetailedRevenueSummary() {
  const [sizes, setSizes] = useState<{
    containerWidth: number;
    containerHeight: number;
  }>({
    containerWidth: -1,
    containerHeight: -1,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const setContainerSize = useCallback(
    (newWidth: number, newHeight: number) => {
      setSizes((prevState) => {
        const roundedWidth = Math.round(newWidth);
        const roundedHeight = Math.round(newHeight);
        if (
          (prevState.containerWidth === roundedWidth &&
            prevState.containerHeight === roundedHeight) ||
          (prevState.containerHeight > 0 && roundedHeight === 0)
        ) {
          return prevState;
        }
        return {
          containerWidth: roundedWidth,
          containerHeight: roundedHeight,
        };
      });
    },
    []
  );  
  const { isMobile } = useResponsive();
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({ ...getDates() });
  const [data, setData] = useState<any>({});
  const [calHeight, setCalHeight] = useState(0);

  const handleMonthChange = (month: string) => {
   //console.log("MONTH CHANGE", month, filter.year);
    setFilter((prev) => ({ ...prev, ...getDates(month, prev.year) }));
  };

  const handleYearChange = (year: string) => {
   //console.log("year CHANGE", year, filter.year);

    setFilter((prev) => ({ ...prev, ...getDates(prev.month, year) }));
  };
  const handleResize = () => {
    const { width: containerWidth = 0, height: containerHeight = 0 } =
      containerRef?.current?.getBoundingClientRect() || {};
    setContainerSize(containerWidth, containerHeight);
    const cal = document.getElementById("calendar-view");
    if (cal) {
     //console.log(cal.getBoundingClientRect());
      setCalHeight(cal.getBoundingClientRect().height);
    }
  };

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "DetailedRevenueSummary",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
     //console.log("FILTERS for DRS", filters);
      const metrics = await api.getMetrics(userId, filters);

      setData(metrics);
    } catch (error) {
     //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let callback = (entries: ResizeObserverEntry[]) => {
      const { width: containerWidth, height: containerHeight } =
        entries[0].contentRect;
      setContainerSize(containerWidth, containerHeight);
      // onResizeRef.current?.(containerWidth, containerHeight);
    };
    const observer = new ResizeObserver(callback);

    const { width: containerWidth = 0, height: containerHeight = 0 } =
      containerRef?.current?.getBoundingClientRect() || {};
    setContainerSize(containerWidth, containerHeight);
    if (containerRef?.current) {
      observer.observe(containerRef?.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    // implement resize event listner
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // biome-i  gnore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  return (
    <>
      <Panel
        label="Detailed Revenue Summary"
        actions={
          <div className="flex items-center gap-1.5">
            
            { filter.year !== "LTM" && (
              <MonthSelect
                disabled={false}
                value={filter.month}
                onChange={handleMonthChange}
              />
            )}
            <YearFilter
              filter={filter}
              setFilter={setFilter}
              disabled={false}
              onChange={handleYearChange}
              value={filter.year}
            />
          </div>
        }
        showNavigation={isMobile}
        className="max-w-[calc(100vw-70px)] md:max-w-[unset]"
        contentClassName="w-full h-[calc(100%-50px)] overflow-y-hidden"
        // className="h-full overflow-y-hidden min-w-[460px] tall3XL:h-[501px] 2k:h-[601px]" // Changed max-h to h to maintain consistent height
        // contentClassName="h-[calc(100%-50px)] overflow-y-hidden"
        // style={{
        //   ...(calHeight > 0 && {
        //     height: `${calHeight}px`,
        //   }),
        // }}
        // className="max-w-[calc(100vw-70px)] md:max-w-[unset]"
        // contentClassName="w-full"
        style={{
          ...(calHeight > 0 && {
            height: `${calHeight}px`,
          }),
        }}
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-[350px]">
            <Spinner className="!text-primary" />
          </div>
        ) : (
          <>
            <table
              className="w-full relative h-[calc(100%-60px)]"
              cellSpacing={12}
            >
              <colgroup>
                <col style={{ width: "21%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead className="w-full">
                <tr className="sticky top-0 text-neutral-800 bg-neutral-300 [&_td]:text-2xs [&_td]:font-medium [&_td]:py-[11px]  ">
                  <td></td>
                  <td className="text-right !text-[#000]">Actual</td>
                  <td className="text-right !text-[#000]">Budget</td>
                  <td className="text-center whitespace-nowrap !text-[#000]">Var. ($)</td>
                  <td className="text-center whitespace-nowrap !text-[#000]">Var. (%)</td>
                  <td className="whitespace-nowrap text-right !text-[#000]">Prior year</td>
                  <td className="text-center whitespace-nowrap !text-[#000]">Var. ($)</td>
                  <td className="text-center whitespace-nowrap !text-[#000]">Var. (%)</td>
                </tr>
              </thead>
              <tbody>
                {/** check for percentage */}
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-[11px]   [&_td]:border-b [&_td]:border-b-neutral-300">
                  <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">OCC. %</td>
                  <td className="tracking-[-0.44px] text-right">
                    {commaSeparatedNumber(data?.occupance?.current || 0, true)}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-right">
                    {commaSeparatedNumber(data?.occupance?.budget || 0, true)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.occupance?.varPrice || 0,
                          true
                        )}`}
                        type={
                          data?.occupance?.varPrice === 0
                            ? "grey"
                            : data?.occupance?.varPrice < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.occupance?.varPercentage || 0,
                          true
                        )}`}
                        type={
                          data?.occupance?.varPercentage === 0
                            ? "grey"
                            : data?.occupance?.varPercentage < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] text-right !text-[#635F66]">
                    {commaSeparatedNumber(data?.occupance?.prevYear || 0, true)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.occupance?.varPricePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.occupance?.varPricePrevYear === 0
                            ? "grey"
                            : data?.occupance?.varPricePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] pr-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.occupance?.varPercentagePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.occupance?.varPercentagePrevYear === 0
                            ? "grey"
                            : data?.occupance?.varPercentagePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-[11px]   [&_td]:border-b [&_td]:border-b-neutral-300">
                  <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">ADR</td>
                  <td className="tracking-[-0.44px] text-right">
                    {commaSeparatedNumber(data?.adr?.current, false)}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-right">
                    {commaSeparatedNumber(data?.adr?.budget, false)}
                  </td>
                  <td className="tracking-[-0.44px] pl-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.adr?.varPrice,
                          false
                        )}`}
                        type={
                          data?.adr?.varPrice === 0
                            ? "grey"
                            : data?.adr?.varPrice < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.adr?.varPercentage || 0,
                          true
                        )}`}
                        type={
                          data?.adr?.varPercentage === 0
                            ? "grey"
                            : data?.adr?.varPercentage < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] text-right !text-[#635F66]">
                    {commaSeparatedNumber(data?.adr?.prevYear, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.adr?.varPricePrevYear,
                          false
                        )}`}
                        type={
                          data?.adr?.varPricePrevYear === 0
                            ? "grey"
                            : data?.adr?.varPricePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] pr-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.adr?.varPercentagePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.adr?.varPercentagePrevYear === 0
                            ? "grey"
                            : data?.adr?.varPercentagePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-[11px]   [&_td]:border-b [&_td]:border-b-[#B6B6BD]">
                  <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">REVPAR</td>
                  <td className="tracking-[-0.44px] text-right">
                    {commaSeparatedNumber(data?.revpar?.current, false)}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-right">
                    {commaSeparatedNumber(data?.revpar?.budget, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.revpar?.varPrice,
                          false
                        )}`}
                        type={
                          data?.revpar?.varPrice === 0
                            ? "grey"
                            : data?.revpar?.varPrice < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.revpar?.varPercentage || 0,
                          true
                        )}`}
                        type={
                          data?.revpar?.varPercentage === 0
                            ? "grey"
                            : data?.revpar?.varPercentage < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] text-right !text-[#635F66]">
                    {commaSeparatedNumber(data?.revpar?.prevYear, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.revpar?.varPricePrevYear,
                          false
                        )}`}
                        type={
                          data?.revpar?.varPricePrevYear === 0
                            ? "grey"
                            : data?.revpar?.varPricePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] pr-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.revpar?.varPercentagePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.revpar?.varPercentagePrevYear === 0
                            ? "grey"
                            : data?.revpar?.varPercentagePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-[11px]   [&_td]:border-b [&_td]:border-b-neutral-300">
                  <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">
                    ROOMS REVENUE
                  </td>
                  <td className="tracking-[-0.44px] text-right">
                    {commaSeparatedNumber(data?.revenue?.current, false)}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-right">
                    {commaSeparatedNumber(data?.revenue?.budget, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.revenue?.varPrice,
                          false
                        )}`}
                        type={
                          data?.revenue?.varPrice === 0
                            ? "grey"
                            : data?.revenue?.varPrice < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.revenue?.varPercentage || 0,
                          true
                        )}`}
                        type={
                          data?.revenue?.varPercentage === 0
                            ? "grey"
                            : data?.revenue?.varPercentage < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] text-right !text-[#635F66]">
                    {commaSeparatedNumber(data?.revenue?.prevYear, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.revenue?.varPricePrevYear,
                          false
                        )}`}
                        type={
                          data?.revenue?.varPricePrevYear === 0
                            ? "grey"
                            : data?.revenue?.varPricePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] pr-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.revenue?.varPercentagePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.revenue?.varPercentagePrevYear === 0
                            ? "grey"
                            : data?.revenue?.varPercentagePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-[11px]   [&_td]:border-b [&_td]:border-b-neutral-300">
                  <td className="tracking-[-0.44px] uppercase whitespace-nowrap pl-1.5 1xl:pl-4">
                    Less: OTA Commissions
                  </td>
                  <td className="tracking-[-0.44px] text-right">
                    {commaSeparatedNumber(data?.otaCommission?.current, false)}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-right">
                    {commaSeparatedNumber(data?.otaCommission?.budget, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.otaCommission?.varPrice,
                          false
                        )}`}
                        type={
                          data?.otaCommission?.varPrice === 0
                            ? "grey"
                            : data?.otaCommission?.varPrice < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.otaCommission?.varPercentage || 0,
                          true
                        )}`}
                        type={
                          data?.otaCommission?.varPercentage === 0
                            ? "grey"
                            : data?.otaCommission?.varPercentage < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] text-right !text-[#635F66]">
                    {commaSeparatedNumber(data?.otaCommission?.prevYear, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.otaCommission?.varPricePrevYear,
                          false
                        )}`}
                        type={
                          data?.otaCommission?.varPricePrevYear === 0
                            ? "grey"
                            : data?.otaCommission?.varPricePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] pr-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.otaCommission?.varPercentagePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.otaCommission?.varPercentagePrevYear === 0
                            ? "grey"
                            : data?.otaCommission?.varPercentagePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-[11px]   [&_td]:border-b [&_td]:bg-neutral-300">
                  <td className="tracking-[-0.44px] !text-[#333134] text-2xs font-bold pl-1.5 1xl:pl-4">
                    NET REVENUE
                  </td>
                  <td className="tracking-[-0.44px] text-right">
                    {commaSeparatedNumber(data?.netRevenue?.current, false)}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-right">
                    {commaSeparatedNumber(data?.netRevenue?.budget, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.netRevenue?.varPrice,
                          false
                        )}`}
                        type={
                          data?.netRevenue?.varPrice === 0
                            ? "grey"
                            : data?.netRevenue?.varPrice < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.netRevenue?.varPercentage || 0,
                          true
                        )}`}
                        type={
                          data?.netRevenue?.varPercentage === 0
                            ? "grey"
                            : data?.netRevenue?.varPercentage < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] text-right !text-[#635F66]">
                    {commaSeparatedNumber(data?.netRevenue?.prevYear, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="min-w-[52px]"
                        text={`${commaSeparatedNumber(
                          data?.netRevenue?.varPricePrevYear,
                          false
                        )}`}
                        type={
                          data?.netRevenue?.varPricePrevYear === 0
                            ? "grey"
                            : data?.netRevenue?.varPricePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px] pr-1">
                    <div className="w-full flex justify-center">
                      <ColorChip
                        className="w-[45px]"
                        text={`${commaSeparatedNumber(
                          data?.netRevenue?.varPercentagePrevYear || 0,
                          true
                        )}`}
                        type={
                          data?.netRevenue?.varPercentagePrevYear === 0
                            ? "grey"
                            : data?.netRevenue?.varPercentagePrevYear < 0
                            ? "purple"
                            : "green"
                        }
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="px-2.5 pl-1.5 1xl:pl-4 pb-8 pt-3.5 qhd1:!pt-7 md:py-3.5 text-2xs text-[#333134] tracking-[-0.11px] italic rounded-b">
              OTA commissions are approximate and are calculated based on what is
              in your PMS.
            </div>
          </>
        )}
      </Panel>
    </>
  );
}
