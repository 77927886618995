import { useEffect, useRef, useState } from "react";
import { ReactComponent as Bell } from "../../../assets/icons/bell.svg";
import { api } from "../../../helpers/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import Spinner from "../../../components/Spinners";

type Notification = {
  id: string;
  isRead: boolean;
  message: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
};

function NotificationView() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const loadMoreRef = useRef<any>(null);

  const fetchNotifications = async ({ pageParam = 0 }) => {
    const res = await api.getNotifications({
      page: pageParam,
      limit: 10,
    });
    return { data: res, hasMore: res.length >= 10 };
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(["notifications"], fetchNotifications, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hasMore ? pages.length : undefined;
    },
  });

  const readNotifications = (list: Notification[]) => {
    const ids = list.filter((n) => !n.isRead).map((n) => n.id);
    if (ids.length > 0) {
      const promises = ids.map((id) => api.readNotifications(id));
      Promise.all(promises).then(() => refetch());
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (data) {
      const list = data.pages.flatMap((page) => page.data);
      setNotifications(list);
      readNotifications(list);
    }
  }, [data]);

  return (
    <div className="right-0 mt-1 z-[1] absolute w-[300px] md:w-[408px] max-h-[400px] overflow-auto py-1 bg-white rounded-lg shadow border border-[#E5E7EB] flex-col flex">
      {notifications?.length === 0 && !isFetching && !isLoading && (
        <div className="w-full flex justify-center items-center h-[100px]">
          <div className="text-neutral-500 text-sm">No notifications</div>
        </div>
      )}
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="self-stretch px-3 py-2.5 border-b border-[#E5E7EB] justify-start items-center gap-2.5 inline-flex"
        >
          <div className="grow shrink basis-0 text-black text-[13px] font-medium font-['Inter'] leading-[20.80px]">
            {notification.message}
          </div>
          {!notification.isRead && (
            <div className="text-[#000] text-2xs font-semibold font-['Inter'] leading-none">
              new
            </div>
          )}
        </div>
      ))}

      <div className="w-full flex justify-center items-center">
        <button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          ref={loadMoreRef}
        >
          {isFetchingNextPage || isFetching || isLoading ? (
            <div className="w-full my-2">
              <Spinner className="!text-primary" />
            </div>
          ) : hasNextPage ? (
            "Load More"
          ) : null}
        </button>
      </div>
    </div>
  );
}

export function Notification() {
  const [showNotificationView, setShowNotificationView] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowNotificationView(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={containerRef}>
      <button
        type="button"
        onClick={() => setShowNotificationView((p) => !p)}
        className="relative w-[34px] lg:w-[40px] h-[40px] bg-white hover:bg-[#F2F7F8] rounded-[8px] border-[1px] hover:border-[#DFE3E4] border-[#E5E7EB] justify-center items-center flex"
      >
        <Bell className="w-5 h-5" />
        {/* <div className="absolute right-3 top-1 lg:right-3.5 lg:top-3.5 w-[7px] h-[7px] bg-[#0B252A] rounded-full border border-white" /> */}
      </button>
      {showNotificationView && <NotificationView />}
    </div>
  );
}
