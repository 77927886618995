import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { OnboardingSidebar } from "../OnboardingSidebar";
import { OnboardingView } from "../OnboardingView";
import { Screen1 } from "./Screen1";
import { Screen2 } from "./Screen2";
import { Screen3 } from "./Screen3";
import { Screen4 } from "./Screen4";
import { Screen5 } from "./Screen5";
import { usePlaces } from "./usePlaces";

const SubSteps = {
  0: Screen1,
  1: Screen2,
  2: Screen3,
  3: Screen4,
  4: Screen5,
};

const determineStep = (form: any) => {
  if (!form || !form.competitors || form.competitors.length === 0) {
    return 0;
  }
  if (!form.usp || !form.amenities) {
    return 1;
  }
  if (!form.competitorLocationRating) {
    return 2;
  }
  if (!form.competitorQualityRating) {
    return 3;
  }
  return 4;
};

export function CompetitorSet({
  onNext,
  onBack,
  step,
  form,
  setForm,
  isDetermined,
  setIsDetermined,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
  form: any;
  setForm: (form: any) => void;
  isDetermined: boolean;
  setIsDetermined: any;
}) {
  const [subStep, setSubStep] = useState(0);
  const CurrentStep = SubSteps[subStep];

  const handleNext = () => {
    if (subStep === Object.values(SubSteps).length - 1) {
      onNext();
      return;
    } else {
      setSubStep(subStep + 1);
    }
  };

  const handleBack = () => {
    if (subStep === 0) {
      onBack();
      return;
    } else {
      setSubStep(subStep - 1);
    }
  };

  const handleChange = (data) => {
    setForm((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {
    setIsDetermined((prev: boolean) => {
      if (!prev) {
        setSubStep(determineStep(form));
        return true;
      }
      return prev;
    });
  }, []);

  return (
    <>
      <OnboardingSidebar
        title="Competitor Set"
        totalSteps={Object.values(SubSteps).length}
        currentStep={subStep}
      >
        <p className="text-xs leading-[16.8px] text-black font-['EZ-Allianz']">
          We create a bespoke strategy for your hotel based on many factors. One
          of these factors is your competitors, as that is how your prospective
          guests will likely shop & compare.
        </p>
        <p className="text-xs leading-[16.8px] text-black mt-2 font-['EZ-Allianz']">
          A good competitive set consists of 4-6 hotels with similar size,
          location, amenities, and décor.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Competitor Set"
        totalSteps={Object.values(SubSteps).length}
        currentStep={subStep}
        infoView={
          <>
            <p className="text-xs leading-[16.8px] text-black font-['EZ-Allianz']">
              We create a bespoke strategy for your hotel based on many factors.
              One of these factors is your competitors, as that is how your
              prospective guests will likely shop & compare.
            </p>
            <p className="text-xs leading-[16.8px] text-black mt-2 font-['EZ-Allianz']">
              A good competitive set consists of 4-6 hotels with similar size,
              location, amenities, and décor.
            </p>
          </>
        }
      >
        <div>
          <CurrentStep
            onNext={handleNext}
            onBack={handleBack}
            setForm={setForm}
            form={form}
          />
        </div>
      </OnboardingView>
    </>
  );
}
