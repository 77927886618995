import { Select } from "../../../components/Select";

const months = [
  { value: "1", label: "Jan" },
  { value: "2", label: "Feb" },
  { value: "3", label: "Mar" },
  { value: "4", label: "Apr" },
  { value: "5", label: "May" },
  { value: "6", label: "Jun" },
  { value: "7", label: "Jul" },
  { value: "8", label: "Aug" },
  { value: "9", label: "Sep" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];

export function MonthSelect({
  value,
  onChange,
  disabled,
  monthToSHow = [],
}: {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  monthToSHow?: string[];
}) {
  const filteredMonths = monthToSHow?.length
    ? months.filter((m) => monthToSHow.includes(m.value))
    : months;
  return (
    <Select
      data={filteredMonths}
      value={months.find((m) => m.value === value)}
      onChange={(month) => {
        onChange(month.value);
      }}
      className="w-[54px] !min-h-[24px] !h-7 !py-0 [&_input]:!h-5 bg-white hover:bg-white border !border-[#E5E7EB] !text-[#000] !rounded-[8px] px-3 py-[7px] h-auto font-normal text-sm flex items-center gap-1 hover:border-[#E5E7EB]"
      isDisabled={disabled}
      isSearchable={false}
    />
  );
}
