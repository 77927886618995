import CustomInput from "../../../components/Input";
import { PopupModal } from "../../../components/Modal";
import { ActionButtons } from "./ActionButtons";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";

export function PMSManualKeyModal({
  onClose,
  setToken,
  token,
  pmsInfo,
  onConnect,
}: {
  onClose: () => void;
  setToken: (token: string) => void;
  token: string;
  pmsInfo: { link: string; title: string; title2: string };
  onConnect: () => void;
}) {
  const handleModal = () => {
    onClose();
  };
  return (
    <PopupModal
      contentClassName="w-[90%] lg:w-[480px] self-center"
      onClose={handleModal}
      shouldStopPropagation={false}
    >
      <button
        className="absolute lg:[&_path]:stroke-white right-2 lg:-right-7 top-2 lg:top-0"
        type="button"
        onClick={onClose}
      >
        <CloseIcon className="w-6 h-6" />
      </button>
      <div>
        <div className="relative text-center flex justify-center items-center mb-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
          >
            <g clip-path="url(#clip0_7923_19598)">
              <path
                d="M56.3834 65.1302C56.3834 65.342 56.2117 65.5138 55.9998 65.5138C55.788 65.5138 55.6163 65.342 55.6163 65.1302C55.6163 64.9184 55.788 64.7466 55.9998 64.7466C56.2117 64.7466 56.3834 64.9184 56.3834 65.1302Z"
                fill="#FFCD42"
                stroke="#FFCD42"
                stroke-width="3.06849"
              />
              <path
                d="M56 46.9111V59.3769"
                stroke="#FFCD42"
                stroke-width="3.06849"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <path
              d="M56 0.500043C86.9279 0.500042 112 25.4004 112 56.1165C112 86.8326 86.9279 111.733 56 111.733C25.0721 111.733 -1.08843e-06 86.8326 -2.43107e-06 56.1165C-3.77372e-06 25.4004 25.072 0.500045 56 0.500043ZM56 109.508C85.6908 109.508 109.76 85.6039 109.76 56.1165C109.76 26.629 85.6908 2.7247 56 2.7247C26.3092 2.7247 2.24 26.629 2.24 56.1165C2.24 85.6039 26.3092 109.508 56 109.508Z"
              fill="#E1DDD2"
            />
            <path
              d="M56 0.500043C25.0721 0.500042 1.90325e-05 25.4004 1.76899e-05 56.1165C1.63472e-05 86.8326 25.0721 111.733 56 111.733C86.9279 111.733 112 86.8326 112 56.1165C112 25.4004 86.928 0.500045 56 0.500043ZM56 109.508C26.3092 109.508 2.24002 85.6039 2.24002 56.1165C2.24002 26.629 26.3092 2.7247 56 2.7247C85.6908 2.7247 109.76 26.629 109.76 56.1165C109.76 85.6039 85.6908 109.508 56 109.508Z"
              fill="#FFCD42"
            />
            <defs>
              <clipPath id="clip0_7923_19598">
                <rect
                  width="30.6849"
                  height="30.6849"
                  fill="white"
                  transform="translate(40.6572 41.1577)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <p className="text-[#646466] text-sm">
          We are not able to find your hotel name, please enter the{" "}
          {pmsInfo?.title} API key manually
        </p>

        <div className="flex-col justify-start items-start gap-5 flex">
          <CustomInput
            label=""
            placeholder="Enter key here"
            onChange={(e) => setToken(e.target.value)}
            value={token}
            // error={errors.validHotelWebsite}
            className="w-full mt-5"
            maxLength={3000}
          />
        </div>
        <div className="w-full flex justify-end">
          <a
            target="_blank"
            href={pmsInfo.link}
            className="text-[#2F3D37] text-xs font-normal leading-normal"
            rel="noreferrer"
          >
            Where to find?
          </a>
        </div>
        <ActionButtons onNext={onConnect} disabled={!token} />
      </div>
    </PopupModal>
  );
}
