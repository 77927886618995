export function Switch({ checked, onChange }) {
  return (
    <label className="flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
      />
      <div className="relative w-11 h-6 border border-[#DFE3E4] bg-[#F2F7F8] rounded-full peer peer-focus:ring-[1px] peer-focus:ring-primary/50 dark:peer-focus:ring-primary dark:bg-[#F2F7F8] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:start-[1px] after:bg-primary after:border-[#DFE3E4] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DFE3E4] peer-checked:bg-[#F2F7F8]"></div>
    </label>
  );
}
