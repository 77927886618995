import moment from "moment";
import { PopupModal } from "../../../components/Modal";
import { PriceInput } from "../../../components/PriceInput";
import { useState } from "react";
import CustomInput from "../../../components/Input";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import Button from "../../../components/Button";
import { validateEventsForm } from "../../onboarding/useValidation";
import { api } from "../../../helpers/api";
import { getDate, getUser } from "../../../helpers/utils";
import { set } from "date-fns";
import { ShowToast } from "../../../components/toast";
import { AxiosError } from "axios";
import { MobileSeasonAndEvents } from "../../onboarding/components/SeasonsAndEvents";

// SeasonFormModal
export function SeasonFormModal({
  isOpen,
  onClose,
  form: onboardingForm,
  onUpdate,
}) {
  const user = getUser();
  const [saving, setSaving] = useState(false); // saving state
  const [form, setForm] = useState<any>({}); // form state

  const handlePriceChange = (key, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleModal = () => {
    onClose();
    setForm({});
  };

  const handleCancel = () => {
    onClose();
    setForm({});
  };

  const handleSave = async () => {
    try {
      // Save logic
      const userId = user?.id;
      setSaving(true);
     //console.log("form", form, onboardingForm);
      const payload = {
        ...onboardingForm,
        seasons: [...onboardingForm.seasons, form],
      };
     //console.log("payload", payload);
      const response = await api.saveOnboarding(userId, payload);
      await api.updateAllSeasons(user?.id);
      console.log("Saving season", payload);
      for(const season of payload.seasons){
        await api.syncRatesGrouped(user?.id, {
          startDate: moment(season.startDate).subtract(1, "day").format("YYYY-MM-DD"),
          endDate: moment(season.endDate).add(1, "day").format("YYYY-MM-DD"),
        });
      }
      
     //console.log("response", response);
      if ([200, 201].includes(response.status)) {
        onUpdate(payload);
      }
      setSaving(false);
    } catch (error: any) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message,
      });
     //console.log(error);
    }
  };

  const validation = validateEventsForm({ seasons: [form] });
  return (
    <PopupModal
      contentClassName="w-[320px] !p-5 md:p-8 md:w-[unset] lg:w-[900px] self-center"
      onClose={handleModal}
    >
      <div className="font-bold">Add Season & Event</div>
      <table className="w-full relative hidden lg:table" cellSpacing={12}>
        <colgroup>
          <col style={{ width: "15%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <thead className="w-full">
          <tr className="sticky top-0 z-[1] text-neutral-950 bg-neutral-300 rounded [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 [&_td]:px-[14px]">
            <td>Season Name</td>
            <td>Duration</td>
            <td className="text-center">Weekday Price Floor</td>
            <td className="text-center">Weekend Price Floor</td>
            <td className="text-center">Price Ceiling</td>
          </tr>
        </thead>
        <tbody>
          <tr className="[&_td]:py-2.5 [&_td]:border-b-neutral-400">
            <td className="pl-3">
              <CustomInput
                label=""
                value={form.name}
                onChange={(e) => {
                  setForm((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
                className="max-w-[100px] [&_input]:h-[29px] [&_input]:!text-2xs"
              />
            </td>
            <td>
              <div className="flex items-center">
                <DatePicker
                  showIcon
                  // icon={CalendarIcon}
                  icon={<CalendarIcon className="!w-4 !h-4" />}
                  toggleCalendarOnIconClick
                  selected={form.startDate ? getDate(form.startDate) : null}
                  onChange={(date) => {
                    setForm((prev) => {
                      return {
                        ...prev,
                        startDate: moment(date).format("YYYY-MM-DD"),
                      };
                    });
                  }}
                  dateFormat="MMM d"
                  className="rounded-l px-3  py-1.5 text-2xs font-medium border-neutral-400 border border-r-0 items-center w-[87px]"
                />
                <DatePicker
                  showIcon
                  icon={<CalendarIcon className="!w-4 !h-4" />}
                  toggleCalendarOnIconClick
                  selected={form.endDate ? getDate(form.endDate) : null}
                  onChange={(date) => {
                    setForm((prev) => {
                      return {
                        ...prev,
                        endDate: moment(date).format("YYYY-MM-DD"),
                      };
                    });
                  }}
                  dateFormat="MMM d"
                  className="rounded-r px-3  py-1.5 text-2xs font-medium border-neutral-400 border  items-center w-[87px]"
                />
              </div>
            </td>
            <td>
              <div className="flex justify-center">
                <PriceInput
                  value={form.weekdayPriceFloor}
                  onChange={(val) =>
                    handlePriceChange("weekdayPriceFloor", val)
                  }
                />
              </div>
            </td>
            <td>
              <div className="flex justify-center">
                <PriceInput
                  value={form.weekendPriceFloor}
                  onChange={(val) =>
                    handlePriceChange("weekendPriceFloor", val)
                  }
                />
              </div>
            </td>
            <td>
              <div className="flex justify-center">
                <PriceInput
                  value={form.priceCeiling}
                  onChange={(val) => handlePriceChange("priceCeiling", val)}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="lg:hidden">
        <MobileSeasonAndEvents
          handlePriceChange={handlePriceChange}
          event={form}
          setForm={setForm}
          className="!border-0 !shadow-none !rounded-none !p-0"
          isSingle={true}
        />
      </div>

      <div
        className={`flex gap-3 justify-end w-full mt-[15px] lg:mt-0 lg:w-[unset]`}
      >
        <Button
          disabled={saving}
          variant="outline"
          className="w-fit"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!validation?.isValid}
          loading={saving}
          className="lg:flex-0 w-fit"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </PopupModal>
  );
}
