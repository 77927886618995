import { useEffect, useState } from "react";
import { GeneralSettingsForm } from "../onboarding/components/GeneralSettings";
import Button from "../../components/Button";
import {
  ReviewCompetitorSet1Item,
  ReviewCompetitorSet3Item,
  ReviewCompetitorSet4Item,
  ReviewCompetitorSet5Item,
} from "../onboarding/components/Review";
import {
  CompetitorQuestion1,
  CompetitorQuestion2,
} from "./components/CompetitorQuestions";
import { AnnualBudgetForm } from "../onboarding/components/AnnualBudget";
import {
  validateAnnualBudgetForm,
  validateCompetitorSet,
  validateGeneralquestionForm,
} from "../onboarding/useValidation";
import { Page } from "../../components/Page";
import { api } from "../../helpers/api";
import { getUser } from "../../helpers/utils";
import { useOnboardingSave } from "../onboarding/useOnboardingSave";
import { ShowToast } from "../../components/toast";
import { SeasonAndEvents } from "./SeasonAndEvents/SeasonAndEvents";
import Spinner from "../../components/Spinners";
import moment from "moment";
const tabs = [
  { title: "General settings", name: "general" },
  { title: "Competitor Set", name: "competitor" },
  { title: "Seasons & Events", name: "seasons" },
  { title: "Budget", name: "budget" },
];

export const pageTitles = {
  general: "General questions & settings",
  competitor: "Competitor Set",
  seasons: "Seasons & Events",
  budget: "Annual Budget",
};

const validate = (form: any, activeTab: string, roomTypes) => {
  switch (activeTab) {
    case "general":
      return validateGeneralquestionForm(form, roomTypes);
    case "competitor":
      return validateCompetitorSet(form);
    case "seasons":
      return { isValid: true };
    case "budget":
      return validateAnnualBudgetForm(form);
    default:
      return { isValid: true };
  }
};

function TabItem({ title, isActive, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`whitespace-nowrap px-5 py-2 text-[#626262] hover:text-[#000000] ${
        isActive ? '!text-[#000000] border-b border-[#0B252A] font-semibold' : ''
      } justify-start items-center flex leading-[16.80px] text-sm font-medium`}
    >
      {title}
    </button>
  );
}

export function Settings() {
  const user = getUser();
  const [form, setForm] = useState<any>({});
  const [roomTypes, setRoomTypes] = useState<any>([]);
  const [savingSeasons, setSavingSeasons] = useState(false);
  const [activeTab, setActiveTab] = useState("general");
  const validation = validate(form, activeTab, roomTypes);

  const onSaveSuccess = () => {
    ShowToast({
      type: "success",
      message: "Settings saved successfully",
    });
  };

  const { saving, onSave } = useOnboardingSave({ onNext: onSaveSuccess });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .getHotelData(user?.id)
      .then((res) => {
        setRoomTypes(
          (res?.roomTypes || []).map((roomType) => ({
            label: roomType.name,
            value: roomType.pmsId,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user?.id]);

  const fetchOnboardingData = async (
    userId: string,
    isUpdate: boolean = false
  ) => {
    !isUpdate && setLoading(true);
    const onboardingResponse = await api.getOnboarding(userId);
    const { userMetadata, userPms } = onboardingResponse;
    const otas = userMetadata?.otas?.filter((ota: any) => ota !== "[]");
    setForm(
      {
        ...userMetadata,
        seasons: userMetadata?.seasons?.map((season, index) => ({
          ...season,
          id: season.id || index + 4,
        })),
        userPms,
        otas,
      } || {}
    );
    !isUpdate && setLoading(false);
  };

  const handleCancel = () => {
    fetchOnboardingData(user.id);
  };
  const handleSave = () => {
    const { userPms, ...payload } = form;
    //console.log("payload settings", payload);
    delete payload.userPms;
    onSave(payload);
  };

  const handleSaveComps = async () => {
    const { userPms, ...payload } = form;
    console.log("saving Comps", payload);
    delete payload.userPms;
    onSave(payload);
    await api.saveOnboarding(user?.id, payload);
    await api.saveComps(user?.id);
    api.syncRatesGrouped(user?.id, {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(1, "year").format("YYYY-MM-DD"),
    });
  };

  const handleSaveSeasons = async () => {
    const { userPms, ...payload } = form;
    console.log("payload settings HEREEEE", payload);
    delete payload.userPms;
    onSave(payload);
    await api.saveOnboarding(user?.id, payload);
    console.log("payload save seasons", payload);
    await api.updateAllSeasons(user?.id);
    for(const season of payload.seasons){
      await api.syncRatesGrouped(user?.id, {
        startDate: moment(season.startDate).subtract(1, "day").format("YYYY-MM-DD"),
        endDate: moment(season.endDate).add(1, "day").format("YYYY-MM-DD"),
      });
      console.log("Updateing Season", season);
      
      console.log("Season updated");
    }
   

  };
  

  useEffect(() => {
    if (user?.id) {
      fetchOnboardingData(user.id);
    }
  }, [user?.id]);

  return (
    <Page>
      <div className="pr-8 pl-8 pt-4 pb-[10vh] flex-1 h-[100%] overflow-y-auto gap-4  1xl:w-[unset]">
        <h1 className="text-[16px] lg:text-[18px] tall:lg:text-[20px] tall:xl:text-[20px] font-semibold text-black">
          Settings
        </h1>
        <div className="mb-6 w-[calc(100vw-3rem)] overflow-auto md:w-fit p-1 justify-start items-start gap-1 flex">
          {tabs.map((tab) => (
            <TabItem
              key={tab.name}
              title={tab.title}
              isActive={tab.name === activeTab}
              onClick={() => setActiveTab(tab.name)}
            />
          ))}
        </div>
        <div className="[&_.label]:!text-neutral-700 w-full h-[calc(100dvh-180px)] lg:h-[calc(100dvh-160px)] flex justify-center lg:border-[0.5px] lg:border-[#E5E7EB] rounded-[10px] lg:bg-white lg:p-8">
          <div
            className={`flex-1 lg:flex-0 lg:max-w-[calc(40vw+350px)] lg:min-w-[606px]`}
          >
            {loading ? (
              <div className="w-full h-full flex justify-center items-center">
                <Spinner className="!text-primary" />
              </div>
            ) : (
              <>
                {activeTab !== "seasons" && (
                  <div className="w-full lg:w-[unset] flex-col lg:flex-row justify-between items-start flex mb-5">
                    <div className="flex flex-col gap-1">
                      <div className="text-black text-xl lg:text-2xl font-semibold font-['Inter']">
                        {pageTitles[activeTab]}
                      </div>
                      {activeTab === "budget" && (
                        <p className="hidden lg:block text-neutral-700 text-sm leading-[22px]">
                          Your annual budget or estimations for the respective
                          year
                        </p>
                      )}
                    </div>

                    <div
                      className={`flex gap-3 w-full lg:w-fit mt-[15px] lg:mt-0`}
                    >
                      <Button
                        disabled={saving}
                        variant="outline"
                        className="flex-1 w-full lg:w-fit"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!validation?.isValid}
                        loading={saving}
                        className="flex-1 w-full lg:w-fit"
                        onClick={
                          //handleSave
                          activeTab === "competitor"
                            ? handleSaveComps
                            : handleSave
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
                {activeTab === "general" && (
                  <div className="h-[calc(100dvh-290px)] overflow-auto flex flex-col gap-5">
                    <GeneralSettingsForm
                      setForm={setForm}
                      form={form}
                      isReview
                      roomTypes={roomTypes}
                    />
                  </div>
                )}
                {activeTab === "competitor" && (
                  <div className="h-[calc(100dvh-270px)] overflow-auto">
                    <ReviewCompetitorSet1Item
                      showCheckmark={false}
                      form={form}
                      setForm={setForm}
                    />
                    <CompetitorQuestion1
                      form={form}
                      setForm={setForm}
                      showCheckmark={false}
                    />
                    <CompetitorQuestion2
                      form={form}
                      setForm={setForm}
                      showCheckmark={false}
                    />
                    <ReviewCompetitorSet3Item
                      showCheckmark={false}
                      form={form}
                      setForm={setForm}
                    />
                    <ReviewCompetitorSet4Item
                      showCheckmark={false}
                      form={form}
                      setForm={setForm}
                    />
                    <ReviewCompetitorSet5Item
                      showCheckmark={false}
                      form={form}
                      setForm={setForm}
                    />
                  </div>
                )}
                {activeTab === "seasons" && (
                  <SeasonAndEvents
                    form={form}
                    setForm={setForm}
                    activeTab={activeTab}
                    saving={saving}
                    handleSave={async () => {
                      await handleSaveSeasons();
                      await fetchOnboardingData(user.id, true);
                    }}
                    handleCancel={handleCancel}
                    onUpdate={() => fetchOnboardingData(user.id, true)}
                  />
                )}
                {activeTab === "budget" && (
                  <div className="">
                    <AnnualBudgetForm
                      isSettings
                      form={form}
                      setForm={setForm}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}