import GoogleIcon from "../../../assets/images/180aeb61ed21098ac716bca94a207f63.png";

import CustomInput from "../../../components/Input";
import { PopupModal } from "../../../components/Modal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ShowToast } from "../../../components/toast";
import { api } from "../../../helpers/api";
import { useState } from "react";
import { getUser, setUser } from "../../../helpers/utils";
import { ActionButtons } from "../../onboarding/components/ActionButtons";

function Step({ children, number }) {
  return (
    <div className="justify-start items-start gap-2 flex">
      <div className="p-px justify-start items-center gap-2.5 flex">
        <div className="w-[22px] h-[22px] p-2.5 bg-[#E0EBE6] rounded-full flex-col justify-center items-center gap-2.5 flex">
          <div className="text-[#2F3D37] text-xs font-semibold leading-normal">
            {number}
          </div>
        </div>
      </div>
      <div className="grow shrink basis-0 text-[#525253] text-sm leading-normal">
        {children}
      </div>
    </div>
  );
}

function GoogleAnalyticsModal({
  onClose,
  onNext,
}: {
  onClose: () => void;
  onNext: () => void;
}) {
  const [propertyId, setPropertyId] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const user = getUser();

  const handleBack = () => {
    setPropertyId("");
    setSaving(false);
    onClose();
  };

  const handleNext = async () => {
    try {
      if (propertyId) {
        setSaving(true);
        const response = await api.putUserProfile(user.id, {
          googleAnalyticsPropertyId: propertyId,
        });
        if ([200, 201].includes(response.status)) {
          const userResponse = await api.getUserProfile(user.id);
          setUser({ ...user, ...userResponse });
          onNext();
          setPropertyId("");
          setSaving(false);
        } else {
          setSaving(false);
          ShowToast({
            type: "error",
            message: "Something went wrong",
          });
        }
      }
    } catch (error: any) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };
  const handleModal = () => {
    onClose();
  };
  return (
    <PopupModal contentClassName="w-[480px] self-center" onClose={handleModal}>
      <button
        className="absolute [&_path]:stroke-white -right-7 top-0"
        type="button"
        onClick={onClose}
      >
        <CloseIcon className="w-6 h-6" />
      </button>
      <div>
        <div className="flex items-center">
          <span className="text-2xl font-semibold text-black">
            Connect Google Analytics
          </span>
          <img
            src={GoogleIcon}
            alt="Google Icon"
            className="w-[50px] h-[30px]"
          />
        </div>
        <>
          <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
            To connect Google Analytics to Autumn, you'll need to provide the
            property ID. Follow the steps below to locate it:
          </p>
          <div className="my-7 flex flex-col gap-4">
            <Step number={1}>
              <div className="flex items-center gap-1">
                <a
                  href={"https://analytics.google.com/"}
                  target="_blank"
                  className=" text-primary hover:underline font-medium"
                  rel="noreferrer"
                >
                  Login into
                </a>
                <span>Login into Google analytics console</span>
              </div>
            </Step>
            <Step number={2}>Click on Admin menu at the bottom</Step>
            <Step number={3}>
              Click on Property Details under Property menu
            </Step>
            <Step number={4}>Copy the Property Id from top right side</Step>
          </div>
          <div className="[&_.label]:!text-[#646466]">
            <CustomInput
              label="Enter Property ID"
              placeholder="Enter here"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
              className="text-sm"
            />
          </div>
        </>
        <ActionButtons
          onBack={handleBack}
          onNext={handleNext}
          disabled={saving || !propertyId}
          loading={saving}
        />
      </div>
    </PopupModal>
  );
}

export function ConnectGoogleAnalytics() {
  return (
    <div className="absolute z-[1] top-0 left-0 w-full h-full flex justify-center items-center">
      <div className="flex flex-col items-center gap-2">
        <img
          src={GoogleIcon}
          alt="Google Icon"
          className="w-[50px] h-[30px]"
        />
        <button
          type="button"
          className="text-primary text-sm font-medium"
          onClick={async () => {
            try {
              const url = await api.getGoogleAuthUrl();
             //console.log("GOOGLE AUTH URL", url);
              
              // Open popup window
              const popup = window.open(
                url.data,
                "Google Login", 
                "width=500,height=600,left=200,top=200"
              );

              //Handle popup close
              const checkPopup = setInterval(() => {
                if (!popup || popup.closed) {
                  clearInterval(checkPopup);
                  // Handle popup closed
                }
              }, 1000);

            } catch (err) {
              ShowToast({
                message: "Failed to get Google auth URL",
                type: "error"
              });
            }
          }}
        >
          Connect Google Analytics
        </button>
      </div>
    </div>
  );
}
