import { useEffect, useRef, useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { YearSelect } from "./YearSelect";
import { MonthSelect } from "./MonthSelect";
import { Radio } from "../../../components/Radio";
import moment from "moment";
import CustomButton from "../../../components/Button";
import {
  commaSeparatedNumber,
  formatNumber,
  getPercentage,
  getUser,
} from "../../../helpers/utils";
import { api } from "../../../helpers/api";

export interface OnboardingData {
  hotelName?: string;
  // Add other onboarding fields as needed
}

const getYTDDates = () => {
  const startDate = moment().startOf("year").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");
  return { startDate, endDate };
};

const getYearDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return { startDate, endDate };
};

const getMonthDates = (month?: string | number) => {
  const currentYear = new Date().getFullYear();
  // month or current month
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return { startDate, endDate };
};

const Filters = {
  YTD: "YTD",
  Year: "Year",
  Month: "Month",
};

const FilterLabels = {
  [Filters.YTD]: "Year to date",
  [Filters.Year]: "Any year",
  [Filters.Month]: "Any month",
};

export function FilterView({ setFilter, filter, onApply }: {
  setFilter: (value: any) => void;
  filter: any;
  onApply: () => void;
}) {
  const ytdDates = getYTDDates();

  return (
    <div className="absolute z-[1] w-[290px] right-6 top-10 p-2 bg-white rounded shadow border border-[#E5E7EB] mt-1">
      <div>
        <div className="self-stretch h-24 flex-col justify-start items-start flex">
          <div
            className={`self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex`}
          >
            <div className="justify-start items-center gap-1.5 flex text-[#000]">
              <Radio
                checked={filter.type === Filters.YTD}
                onChange={() =>
                  setFilter((prev) => ({ type: Filters.YTD, ...getYTDDates() }))
                }
                label="Year to date"
              />
            </div>
            <div className="opacity-60 text-black text-xs font-medium  leading-3">
              {moment(ytdDates.startDate).format("MM/DD/YYYY")} -{" "}
              {moment(ytdDates.endDate).format("MM/DD/YYYY")}
            </div>
          </div>
          <div
            className={`self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex`}
          >
            <Radio
              checked={filter.type === Filters.Year}
              onChange={() =>
                setFilter((prev) => ({ type: Filters.Year, ...getYearDates() }))
              }
              label="Any year"
            />
            <YearSelect
              disabled={filter.type !== Filters.Year}
              value={moment(filter.startDate).format("YYYY")}
              onChange={(year) =>
                setFilter((prev) => ({
                  ...prev,
                  ...getYearDates(year),
                }))
              }
            />
          </div>
          <div
            className={`self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex`}
          >
            <Radio
              checked={filter.type === Filters.Month}
              onChange={() =>
                setFilter((prev) => ({
                  type: Filters.Month,
                  ...getMonthDates(),
                }))
              }
              label="Any month"
            />
            <div className="justify-start items-center gap-1.5 flex">
              <MonthSelect
                disabled={filter.type !== Filters.Month}
                value={moment(filter.startDate).format("M")}
                onChange={(month) =>
                  setFilter((prev) => ({
                    ...prev,
                    ...getMonthDates(month),
                  }))
                }
              />
              <YearSelect
                disabled={filter.type !== Filters.Month}
                value={moment(filter.startDate).format("YYYY")}
                onChange={(year) =>
                  setFilter((prev) => ({
                    ...prev,
                    ...getMonthDates(moment(prev.startDate).format("M")),
                    startDate: moment(prev.startDate).set("year", Number(year)).format("YYYY-MM-DD"),
                    endDate: moment(prev.endDate).set("year", Number(year)).format("YYYY-MM-DD")
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end p-2">
          <CustomButton
            onClick={onApply}
            className="!h-[30px] text-white text-[13px] font-medium"
          >
            Apply
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export function RevParSummary({ onboardingData }: { onboardingData?: OnboardingData }) {
  const user = getUser();
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState({ type: Filters.YTD, ...getYTDDates() });
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);

  const fetchMetrics = async () => {
    try {
      const userId = user?.id;
      const results = await api.getMetrics(userId, { 
        type: "TotalRevenue", 
        startDate: filter.startDate, 
        endDate: filter.endDate 
      });

      const { revenue, revpar, occupancy, adr } = results;
      setData({ revenue, revpar, occupancy, adr });
    } catch (error) {
      console.log(error);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter.startDate, filter.endDate]);

  const handleApply = () => {
    setShowFilter(false);
    fetchMetrics();
  };

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isRevparUp = Number(data?.revpar?.varPercentagePrevYear || 0) > 0;
  const isRevenueUp = Number(data?.revenue?.varPercentagePrevYear || 0) > 0;
  const isRevenueZero = Number(data?.revenue?.varPercentagePrevYear || 0) === 0;
  const isRevparZero = Number(data?.revpar?.varPercentagePrevYear || 0) === 0;
  const isOccupancyUp = Number(data?.occupancy?.varPercentagePrevYear || 0) > 0;
  const isADRUp = Number(data?.adr?.varPercentagePrevYear || 0) > 0;
  const isOccupancyZero = Number(data?.occupancy?.varPercentagePrevYear || 0) === 0;
  const isADRZero = Number(data?.adr?.varPercentagePrevYear || 0) === 0;

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "morning";
    if (hour < 17) return "afternoon";
    return "evening";
  };

  return (
    <div ref={containerRef} className="relative w-full px-5 pt-4">
      <div className="w-full flex flex-row justify-between items-center mb-2">
        <div className="flex flex-row gap-2 items-center">
          <h1 className="text-[16px] lg:text-[16px] tall:lg:text-[18px] tall:xl:text-[20px] font-semibold text-black">
            Good {getGreeting()}, {user.firstName}
          </h1>
          <div className="text-[14px] lg:text-[16px] tall:lg:text-[18px] text-[#000] font-medium">
            {onboardingData?.hotelName} is looking good.
          </div>
        </div>

        {/* Period filter */}
        <div className="justify-start items-center gap-1 flex">
          <CalendarIcon className="w-4 h-4 mt-1" />
          <button
            type="button"
            onClick={() => setShowFilter((p) => !p)}
            className="justify-start items-center gap-1 flex"
          >
            <div>
              <span className="text-[#646466] text-xs font-medium  leading-none">
                Period:
              </span>
              <span className="text-[#525253] text-xs font-medium  leading-none">
                {" "}
              </span>
              <span className="text-black text-xs font-medium  leading-none">
                {filter.type === Filters.YTD
                  ? "Year to date"
                  : filter.type === Filters.Year
                    ? moment(filter.startDate).format("YYYY")
                    : moment(filter.startDate).format("MMM. YYYY")}
              </span>
            </div>
            <ChevronDownIcon
              className={`w-3 h-3 ${showFilter ? "rotate-180 pb-1" : "pt-1"}`}
            />
          </button>
        </div>
      </div>

      {/* Revenue, Occupancy, ADR and RevPAR content */}
      <div className="w-full grid grid-cols-4 gap-4 bg-white p-2 border rounded-lg">
        {/* Revenue Card */}
        <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
          <div className="space-y-2 space-between w-[100%]">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <span className="text-xs text-muted-foreground">Rooms Revenue</span>
                <div className="flex flex-row items-center gap-2">
                  <span
                    className={`text-xs font-medium px-2 py-0.5 rounded-full ${isRevenueZero
                      ? "bg-neutral-50 text-[#000]"
                      : isRevenueUp
                        ? "bg-[#D8E6E0] text-[#303E38]"
                        : "bg-blue-50 text-blue-600"
                    }`}
                  >
                    {isRevenueUp ? "+" : ""}
                    {data?.revenue?.varPercentagePrevYear === undefined ? "0%" : commaSeparatedNumber(Number(data?.revenue?.varPercentagePrevYear), true)}
                  </span>
                  <p className="text-xs font-semibold">
                    {data?.revenue?.current === undefined ? "0" : commaSeparatedNumber(Number(data?.revenue?.current), false)}
                  </p>
                </div>
              </div>
              {/* <div className="flex items-baseline justify-between">
                <p className="text-sm font-semibold">
                  {commaSeparatedNumber(Number(data?.revenue?.current || 0), false)}
                </p>
                <p className="!text-[12px] text-muted-foreground">
                  Last year: {commaSeparatedNumber(Number(data?.revenue?.previous || 0), false)}
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {/* Occupancy Card */}
        <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
          <div className="space-y-2 space-between w-[100%]">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <span className="text-xs text-muted-foreground">Occupancy</span>
                <div className="flex flex-row items-center gap-2">
                  <span
                    className={`text-xs font-medium px-2 py-0.5 rounded-full ${isOccupancyZero
                      ? "bg-neutral-50 text-[#000]"
                      : isOccupancyUp
                        ? "bg-[#D8E6E0] text-[#303E38]"
                        : "bg-blue-50 text-blue-600"
                    }`}
                  >
                    {isOccupancyUp ? "+" : ""}
                    {data?.occupancy?.varPercentagePrevYear === undefined ? "0%" : commaSeparatedNumber(Number(data?.occupancy?.varPercentagePrevYear), true)}
                  </span>
                  <p className="text-xs font-semibold">
                    {data?.occupancy?.current === undefined ? "0%" : commaSeparatedNumber(Number(data?.occupancy?.current), true)}
                  </p>
                </div>
              </div>
              {/* <div className="flex items-baseline justify-between">
                <p className="text-sm font-semibold">
                  {commaSeparatedNumber(Number(data?.occupancy?.current || 0), true)}
                </p>
                <p className="!text-[12px] text-muted-foreground">
                  Last year: {commaSeparatedNumber(Number(data?.occupancy?.previous || 0), true)}
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {/* ADR Card */}
        <div className="p-3 rounded-md bg-[#F5F1F0] w-[100%] flex !justify-between my-auto">
          <div className="space-y-2 space-between w-[100%]">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <span className="text-xs text-muted-foreground">Average Daily Rate</span>
                <div className="flex flex-row items-center gap-2">
                  <span
                    className={`text-xs font-medium px-2 py-0.5 rounded-full ${isADRZero
                      ? "bg-neutral-50 text-[#000]"
                      : isADRUp
                        ? "bg-[#D8E6E0] text-[#303E38]"
                        : "bg-blue-50 text-blue-600"
                    }`}
                  >
                    {isADRUp ? "+" : ""}
                    {data?.adr?.varPercentagePrevYear === undefined ? "0%" : commaSeparatedNumber(Number(data?.adr?.varPercentagePrevYear), true)}
                  </span>
                  <p className="text-xs font-semibold">
                    {data?.adr?.current === undefined ? "0" : commaSeparatedNumber(Number(data?.adr?.current), false)}
                  </p>
                </div>
              </div>
              {/* <div className="flex items-baseline justify-between">
                <p className="text-sm font-semibold">
                  {commaSeparatedNumber(Number(data?.adr?.current || 0), false)}
                </p>
                <p className="!text-[12px] text-muted-foreground">
                  Last year: {commaSeparatedNumber(Number(data?.adr?.previous || 0), false)}
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {/* RevPAR Card */}
        <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
          <div className="space-y-2 space-between w-[100%]">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <span className="text-xs text-muted-foreground">RevPAR</span>
                <div className="flex flex-row items-center gap-2">
                  <span
                    className={`text-xs font-medium px-2 py-0.5 rounded-full ${isRevparZero
                      ? "bg-neutral-50 text-[#000]"
                      : isRevparUp
                        ? "bg-[#D8E6E0] text-[#303E38]"
                        : "bg-blue-50 text-blue-600"
                    }`}
                  >
                    {isRevparUp ? "+" : ""}
                    {data?.revpar?.varPercentagePrevYear === undefined ? "0%" : commaSeparatedNumber(Number(data?.revpar?.varPercentagePrevYear), true)}
                  </span>
                  <p className="text-xs font-semibold">
                    {data?.revpar?.current === undefined ? "0" : commaSeparatedNumber(Number(data?.revpar?.current), false)}
                  </p>
                </div>
              </div>
              {/* <div className="flex items-baseline justify-between">
                <p className="text-sm font-semibold">
                  {commaSeparatedNumber(Number(data?.revpar?.current || 0), false)}
                </p>
                <p className="!text-[12px] text-muted-foreground">
                  Last year: {commaSeparatedNumber(Number(data?.revpar?.previous || 0), false)}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {showFilter && (
        <FilterView
          onApply={handleApply}
          filter={filter}
          setFilter={setFilter}
        />
      )}
    </div>
  );
}