import { useEffect, useState } from "react";
import { Logo } from "../../components/Logo";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { Welcome } from "./components/Welcome";
import { PMSConnect } from "./components/PMSConnect";
import { GeneralSettings } from "./components/GeneralSettings";
import { CompetitorSet } from "./components/CompetitorSet/CompetitorSet";
import { SeasonsAndEvents } from "./components/SeasonsAndEvents";
import { AnnualBudget } from "./components/AnnualBudget";
import { Review } from "./components/Review";
import { Plan } from "./components/Plan";
import { GoLive } from "./components/GoLive";
import { api } from "../../helpers/api";
import { getUser } from "../../helpers/utils";
import { ConnectGoogleAnalytics } from "./components/ConnectGoogleAnalytics";
import useStripeSession from "../../helpers/hooks/useStripeSession";
import { OtaRates } from "./components/OtaRates/OtaRates";
import { useNavigate } from "react-router-dom";

const determineStep = (form: any, activeSubscription: any) => {
 //console.log(form, activeSubscription, "subscriptionResponse11");
  if (
    activeSubscription?.length > 0 &&
    activeSubscription?.[0]?.status === "Paid"  
  

  ) {
    return 9;
  }
  if (form.clickedContinueStripeCheckout) {
    return 9;
  }
  if (!form) {
    return 0;
  }
  if (!form.pms && !form.hotelName && !form.hotelWebsite && !form.title) {
    return 0;
  }
  if (!form.userPms) {
    return 1;
  }

  if (!form.otas || form.otas.length === 0) {
    return 2;
  }
  if (!form.roomTypes || !form.roomTypes.baseRoomType) {
    return 2;
  }
  if (
    !form.competitors ||
    form.competitors.length === 0 ||
    !form.usp ||
    !form.amenities ||
    !form.competitorLocationRating ||
    !form.competitorPrices ||
    !form.competitorQualityRating
  ) {
    return 3;
  }
  if (!form.seasons || form.seasons.length === 0) {
    return 4;
  }
  if (!form.annualBudget || form.annualBudget.length === 0) {
    return 6;
  }
  if (form.annualBudget && form.annualBudget.length > 0) {
    return 8;
  }
  return 10;
};

const steps = {
  0: Welcome,
  1: PMSConnect,
  2: OtaRates,
  3: GeneralSettings,
  4: CompetitorSet,
  5: SeasonsAndEvents,
  6: ConnectGoogleAnalytics,
  7: AnnualBudget,
  8: Plan,
  9: Review,
  10: GoLive,
};

export function Onboarding() {
  const navigate = useNavigate();
  const user = getUser();
  const { activeSubscription, isLoading } = useStripeSession();
  const [isDetermined, setIsDetermined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const Step = steps[step];
  const [form, setForm] = useState<any>({});

  const handleNext = () => {
    if (step === 8) {
      localStorage.setItem("staySignedIn", "true");
      setStep(9);
      api.postLog({
        s: "Onboarding Handle Next Step 8",
        s2: step
      });
    }
    if (step === 9) {
      localStorage.setItem("staySignedIn", "false");
      api.postLog({
        s: "Onboarding Handle Next Step 9",
        s2: step
      });
    }
    if (step < Object.keys(steps).length - 1) {
      if (step + 1 === 10) {
        try {
          // call complete onboarding api
         //console.log("complete onboarding");
          api.completeOnboarding(user?.id);
        } catch (error) {
         //console.log(error);
        }
      }
      setStep(step + 1);
    }
    api.postLog({
      s: "Onboarding Handle Next",
      s2: step
    });
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const fetchOnboardingData = async (userId: string) => {
    setLoading(true);

    const onboardingResponse = await api.getOnboarding(userId);
    const subscriptionResponse = await api.getUserSubscription(userId);
   //console.log(subscriptionResponse, "subscriptionResponse");
    const { userMetadata, userPms } = onboardingResponse;
    const newStep = determineStep(
      { ...userMetadata, userPms },
      subscriptionResponse
    );
    const otas = userMetadata?.otas?.filter((ota: any) => ota !== "[]");

    setForm({ ...userMetadata, userPms, otas } || {});
    setStep(newStep || 0);
    setLoading(false);
  };

  useEffect(() => {
    // Check for authentication
    api.postLog({
      s: "Use Effect Onoboarding"
    });
    const token = localStorage.getItem("token");
    if (!token) {
      api.postLog({
        s: "Token Bounced"
      });
      navigate("/login");
      return;
    }

    if (user?.id) {
      fetchOnboardingData(user.id);
    } else {
      api.postLog({
        s: "Made it without OB data"
      });
      navigate("/login");
    }
  }, [user?.id, activeSubscription, navigate]);

  if (loading || isLoading) {
    return <div />;
  }

  return (
    <div className="flex-1 flex items-center [&_.label]:!text-neutral-700 !bg-[#F5F4EE]">
      <div className="lg:hidden z-[1] h-[58px] fixed top-0 left-0 w-full flex items-center px-5 bg-white border border-neutral-300">
        <LogoIcon />
      </div>
      <Step
        onNext={handleNext}
        onBack={handleBack}
        step={step}
        form={form}
        setForm={setForm}
        isDetermined={isDetermined}
        setIsDetermined={setIsDetermined}
      />
    </div>
  );
}
