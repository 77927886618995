import { ChangeEvent, useState } from "react";
import CustomInput from "../../../components/Input";
import { PriceInput } from "../../../components/PriceInput";
import { ActionButtons } from "./ActionButtons";
import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { useOnboardingSave } from "../useOnboardingSave";
import { validateAnnualBudgetForm } from "../useValidation";
import { commaSeparatedNumber, getPercentage } from "../../../helpers/utils";

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function MobileMonthlyBudget({ form, handleChange, index }) {
  const budget = form?.annualBudget?.find((b) => b.month === index + 1) || {
    month: index + 1,
  };
  return (
    <div className="p-3.5 rounded bg-white border border-neutral-400 shadow-[0px_2px_6px_0px_rgba(20,20,43,0.06)]">
      <div className="text-black text-sm font-semibold mb-[18px] leading-[14px]">
        {month[budget.month - 1]}
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <CustomInput
              label="# of Available Rooms"
              type="number"
              name="availableRooms"
              value={`${budget.availableRooms || ""}`}
              onChange={handleChange(budget.month)}
              className="max-w-[102px] md:max-w-[unset] [&_label]:text-[#525254] [&_label]:!text-3xs flex-1 [&_input]:!h-[29px] [&_input]:text-center [&_input]:text-2xs [&_input]:!px-2 [&_input]:font-medium"
            />
          </div>
          <div className="flex-1">
            <CustomInput
              label="# of Sold Rooms"
              type="number"
              name="soldRooms"
              value={`${budget.soldRooms || ""}`}
              onChange={handleChange(budget.month)}
              className="max-w-[90px] md:max-w-[unset][&_label]:text-[#525254] [&_label]:!text-3xs flex-1 [&_input]:!h-[29px] [&_input]:text-center [&_input]:text-2xs [&_input]:!px-2 [&_input]:font-medium"
            />
          </div>
          <div className="flex-1">
            <div className="text-[#525254] pt-1 text-3xs mb-2 font-medium leading-[10px]">
              Occ.{" "}
            </div>
            <div className="max-w-[102px] md:max-w-[unset] h-[29px] text-center text-black text-2xs font-medium leading-[11px] px-2 py-1.5 bg-[#f9f9fb] rounded border border-[#f4f4f6]">
              {budget.occupancy
                ? `${getPercentage(budget.occupancy * 100)}%`
                : "-"}
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <div className="text-neutral-800 text-3xs mb-1.5 font-medium leading-[10px]">
              ADR
            </div>
            <PriceInput
              value={`${budget.adr || ""}`}
              valueLength={3}
              onChange={(val) =>
                handleChange(budget.month)({
                  target: { name: "adr", value: val },
                } as ChangeEvent<HTMLInputElement>)
              }
              className="w-[102px] md:w-[unset] !px-2.5 [&_input]:text-center [&_input]:text-2xs [&_input]:font-medium"
            />
          </div>
          <div className="flex-1">
            <div className="text-[#525254] text-3xs mb-2 font-medium leading-[10px]">
              RevPAR
            </div>
            <div className="w-[90px] md:w-[unset] h-[29px] text-center text-black text-2xs font-medium leading-[11px] px-2 py-1.5 bg-[#f9f9fb] rounded border border-[#f4f4f6]">
              {budget.revpar ? commaSeparatedNumber(budget.revpar) : "-"}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-[#525254] text-3xs mb-2 font-medium leading-[10px]">
              Rooms Revenue
            </div>
            <div className="w-full h-[29px] text-center text-black text-2xs font-medium leading-[11px] px-2 py-1.5 bg-[#f9f9fb] rounded border border-[#f4f4f6]">
              {budget.roomsRevenue
                ? commaSeparatedNumber(budget.roomsRevenue)
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AnnualBudgetForm({
  setForm,
  form,
  isReview = false,
  isSettings = false,
}) {
  const [monthWiseBudget, setMonthWiseBudget] = useState(
    month.map((m, i) => ({
      month: i,
      availableRooms: 0,
      soldRooms: 0,
      occupancy: 0,
      adr: 0,
      revpar: 0,
      roomsRevenue: 0,
    }))
  );

  const handleChange = (month: any) => (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (isNaN(Number(value)) || (name !== "adr" && Number(value) < 0)) {
      return;
    }
    setForm((prev) => {
      const newAnnualBudget = [...(prev.annualBudget || [])];
      let index = newAnnualBudget.findIndex((b) => b.month === month);
      if (index === -1) {
        newAnnualBudget.push({ month });
        index = newAnnualBudget.length - 1;
      }
      newAnnualBudget[index][name] = value;
      const prevMonth = newAnnualBudget[index];
      if (
        prevMonth.availableRooms &&
        prevMonth.soldRooms &&
        !isNaN(prevMonth.availableRooms) &&
        !isNaN(prevMonth.soldRooms)
      ) {
        prevMonth.occupancy =
          (Number(prevMonth.soldRooms) / Number(prevMonth.availableRooms)) *
          100;
        prevMonth.occupancy = isFinite(prevMonth.occupancy)
          ? prevMonth.occupancy
          : 0;
        if (prevMonth.adr && !isNaN(prevMonth.adr)) {
          prevMonth.revpar =
            Number(prevMonth.adr) * (prevMonth.occupancy / 100) || 0;
          prevMonth.roomsRevenue =
            prevMonth.revpar * Number(prevMonth.availableRooms) || 0;
        }
      }
      newAnnualBudget[index] = { ...prevMonth };
      return { ...prev, annualBudget: newAnnualBudget };
    });
  };

  return (
    <>
      <div className="flex flex-col [&_label]:!text-neutral-700">
        {!isReview && !isSettings && (
          <div className="text-xl lg:text-2xl font-[800] px-6 lg:px-8">
            Annual Budget
          </div>
        )}
        {!isSettings && (
          <p className=" text-neutral-700 mt-2 mb-5 text-sm leading-[22px] px-6 lg:px-8">
            Please enter in your annual budget or estimations for the respective
            year
          </p>
        )}
        {/** desktop */}
        <div
          className={`hidden lg:flex flex-col gap-3 pb-2 ${
            isSettings ? "h-[calc(100dvh-290px)] overflow-auto" : "px-6 lg:px-8"
          } ${
            !isReview && !isSettings
              ? "h-[calc(100dvh-400px)] tall:h-[calc(100dvh-1700px)] tall:xl:h-[calc(100dvh-1700px)] overflow-auto"
              : ""
          }`}
        >
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "26%" }} />
              <col style={{ width: "21%" }} />
              <col style={{ width: "3%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 z-[1] text-neutral-800 bg-neutral-300 rounded [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 [&_td]:px-[14px]">
                <td className="!text-[#000]">Month</td>
                <td className="text-center !text-[#000]"># of Available Rooms</td>
                <td className="text-center !text-[#000]"># of Sold Rooms</td>
                <td className="text-center !text-[#000]">Occ. </td>
                <td className="text-center !text-[#000]">ADR</td>
                <td className="text-center !text-[#000]">RevPAR</td>
                <td className="text-center !text-[#000]">Rooms Revenue</td>
              </tr>
            </thead>
            <tbody>
              {month.map((m, i) => {
                const budget = form?.annualBudget?.find(
                  (b) => b.month === i + 1
                ) || { month: i + 1 };
                return (
                  <tr
                    key={m}
                    className="text-[13px] font-semibold [&_td]:py-1.5 [&_td]:border-b [&_td]:border-b-neutral-400"
                  >
                    <td className="text-2xs font-medium leading-[11px] pl-3">
                      {month[budget.month - 1]}
                    </td>
                    <td>
                      <div className="w-full flex justify-center">
                        <CustomInput
                          label=""
                          type="number"
                          name="availableRooms"
                          value={`${budget.availableRooms || ""}`}
                          onKeyDown={(e) => {
                            if (e.key === "e" || e.key === "E") {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleChange(budget.month)}
                          className="max-w-[74px] [&_input]:!h-[29px] [&_input]:text-center [&_input]:text-2xs [&_input]:!px-2 [&_input]:font-medium"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="w-full flex justify-center">
                        <CustomInput
                          label=""
                          type="number"
                          name="soldRooms"
                          value={`${budget.soldRooms || ""}`}
                          onChange={handleChange(budget.month)}
                          onKeyDown={(e) => {
                            if (e.key === "e" || e.key === "E") {
                              e.preventDefault();
                            }
                          }}
                          className="max-w-[74px] [&_input]:!h-[29px] [&_input]:text-center [&_input]:text-2xs [&_input]:!px-2 [&_input]:font-medium"
                        />
                      </div>
                    </td>
                    <td className="text-center text-2xs font-medium">
                      {budget.occupancy
                        ? `${getPercentage(budget.occupancy)}%`
                        : "-"}
                    </td>
                    <td>
                      <div className="w-full flex justify-center">
                        <PriceInput
                          value={`${budget.adr || ""}`}
                          valueLength={3}
                          onChange={(val) =>
                            handleChange(budget.month)({
                              target: { name: "adr", value: val },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                          className="max-w-[51px] !px-2.5 [&_input]:text-center [&_input]:text-2xs [&_input]:font-medium"
                        />
                      </div>
                    </td>
                    <td className="text-center text-2xs font-medium">
                      {budget.revpar
                        ? commaSeparatedNumber(budget.revpar)
                        : "-"}
                    </td>
                    <td className="text-center text-2xs font-medium">
                      {budget.roomsRevenue
                        ? commaSeparatedNumber(budget.roomsRevenue)
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/** mobile */}
        <div className="flex flex-col gap-3 pb-2 lg:hidden">
          {month.map((m, i) => (
            <MobileMonthlyBudget
              key={m}
              form={form}
              handleChange={handleChange}
              index={i}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export function AnnualBudget({
  onNext,
  onBack,
  step,
  form,
  setForm,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
  form: any;
  setForm: (data: any) => void;
}) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const handleNext = async () => {
    onSave(form);
  };

  const { isValid, errors } = validateAnnualBudgetForm(form);

  const handleBack = () => {
    onBack();
  };

  const handleChange = (data) => {
    setForm((prev) => ({ ...prev, ...data }));
  };

  return (
    <>
      <OnboardingSidebar title="Budget">
        <p className="text-xs leading-[16.8px] text-black">
          Enter your annual budget or estimations for the respective year. This
          will show up in your reporting dashboard, as we always compare your
          actual performance to your budget.
        </p>
        <p className="text-xs leading-[16.8px] text-black">
          Autumn will try to maximize your revenue irrespective of your budget,
          so we encourage you to budget honestly & conservatively.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Budget"
        contentContainerClassName="md:!px-6"
        infoView={
          <>
            <p className="text-xs leading-[16.8px] text-black">
              Enter your annual budget or estimations for the respective year.
              This will show up in your reporting dashboard, as we always
              compare your actual performance to your budget.
            </p>
            <p className="text-xs leading-[16.8px] text-black">
              Autumn will try to maximize your revenue irrespective of your
              budget, so we encourage you to budget honestly & conservatively.
            </p>
          </>
        }
      >
        <Card className="w-full lg:max-w-[690px] lg:w-[690px] !px-0 !py-6 lg:!py-8">
          <AnnualBudgetForm setForm={setForm} form={form} />
          <div className="px-6 lg:px-8">
            <ActionButtons
              onBack={onBack}
              onNext={handleNext}
              disabled={!isValid}
              loading={saving}
            />
          </div>
        </Card>
      </OnboardingView>
    </>
  );
}
