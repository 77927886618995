import { useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { useApp } from "../../helpers/app-context";
import { getUser } from "../../helpers/utils";
import { Calendar } from "../calendar/components/Calendar";
import { CalendarView } from "./components/CalendarView";
import { DetailedRevenueSummary } from "./components/DetailedRevenueSummary";
import { Notification } from "./components/Notification";
import { PickupReportChart } from "./components/PickupReport/PickupReportChart";
import { RevParSummary } from "./components/RevParSummary";
import { RevenueChart } from "./components/RevenueSummary/RevenueChart";
import { SummaryChart } from "./components/SummaryChart";
import { TrendsLineChart } from "./components/TrendsLineChart/TrendsLineChart";
import { api } from "../../helpers/api";
import { Navigation } from "../../components/Navigation";

export interface OnboardingData {
  hotelName?: string;
  userMetadata?: {
    otas?: string[];
    seasons?: Array<{
      id: number;
      [key: string]: any;
    }>;
  };
  userPms?: any;
  otas?: string[];
  [key: string]: any;
}

export function Dashboard() {
  const user = getUser();
  const { isChatBotOpen } = useApp() as { isChatBotOpen: boolean };
  const [onboardingData, setOnboardingData] = useState<any>(null);

  const fetchOnboardingData = async (userId: string) => {
    const onboardingResponse = await api.getOnboarding(userId);
    const { userMetadata, userPms } = onboardingResponse;
    const otas = userMetadata?.otas?.filter((ota: any) => ota !== "[]");
    setOnboardingData(
      {
        ...userMetadata,
        seasons: userMetadata?.seasons?.map((season, index) => ({
          ...season,
          id: season.id || index + 4,
        })),
        userPms,
        otas,
      } || {}
    );
  };

  useEffect(() => {
    if (user?.id) {
      fetchOnboardingData(user?.id);
    }
  }, [user?.id]);

  console.log(onboardingData, "onboardingData");
  return (
    <Page>
      <div
        className={`pb-5 h-[calc(100vh-20px)] overflow-auto lg:h-[100vh] flex-1 ${isChatBotOpen ? "w-[calc(100vw-350px)]" : ""
          } 1xl:w-[unset]`}
      >

        <RevParSummary onboardingData={onboardingData} />
        <div className="hidden 1xl:block mt-4 tall3XL:mt-8 tall3XL:h-[84vh]">
          <div className="px-5 pb-1 flex flex-col items-stretch gap-4 3xl:gap-6 tall3XL:h-[84vh]">
            <div className="flex gap-6 flex-[4]">
              <div className={`flex-[2.5] overflow-hidden max-h-[310px] tall3:max-h-[410px] tall3XL:max-h-[410px] qhd1:max-h-[600px] ${!isChatBotOpen ? "tall3:flex-[2.5]" : ""}`}>
                <RevenueChart />
              </div>
              <div className={`flex-[1.5] qhd1:flex-[1.5] max-h-[310px] tall3:max-h-[410px] tall3XL:max-h-[410px] qhd1:max-h-[600px]`}>
                <TrendsLineChart />
              </div>
              <div className={`flex-[1.5] qhd1:flex-[1.5] max-h-[310px] tall3:max-h-[410px] tall3XL:max-h-[410px] qhd1:max-h-[600px]`}>
                <SummaryChart />
              </div>
            </div>
            <div className="flex gap-6 flex-[4]">
              <div className="flex-[2.5] max-h-[401px] tall3:max-h-[451px] tall3XL:max-h-[501px] qhd1:max-h-[601px]">
                <DetailedRevenueSummary />
              </div>
              <div className="flex-[1.5] qhd1:flex-[1.5] max-h-[401px] tall3:max-h-[451px] tall3XL:max-h-[501px] qhd1:max-h-[601px]">
                <PickupReportChart />
              </div>
              <div className="flex-[1.5] qhd1:flex-[1.5] max-h-[401px] tall3:max-h-[451px] tall3XL:max-h-[501px] qhd1:max-h-[601px]">
                <CalendarView />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "hidden md:hidden 1xl:hidden h-[calc(100vh-10px)] overflow-auto lg:flex flex-col gap-4"
          }
        >
          <div className="px-5 pb-1 relative flex items-stretch mt-4 gap-4">
            <div className="flex-1">
              <RevenueChart />
            </div>
            <TrendsLineChart />
          </div>
          <div className="px-5 pb-1 flex items-stretch mt-2 gap-4">
            <div className="flex-1">
              <DetailedRevenueSummary />
            </div>
          </div>
          <div className="px-5 pb-1 flex items-stretch mt-4 gap-4">
            <div className="flex-1">
              <PickupReportChart />
            </div>
            <div className="flex-1">
              <SummaryChart />
            </div>
            <div className="flex-1">
              <CalendarView />
            </div>
          </div>
        </div>
        <div className={"px-5 lg:hidden flex flex-col gap-4"}>
          <div className="flex-1 mt-4">
            <RevenueChart />
          </div>
          <div className="pb-1 relative flex md:flex-row flex-col items-stretch gap-4">
            <div className="flex-1">
              <TrendsLineChart />
            </div>
            <div className="flex-1">
              <SummaryChart />
            </div>
          </div>
          <div className="flex-1">
            <DetailedRevenueSummary />
          </div>
          <div className="pb-1 flex md:flex-row flex-col items-stretch gap-4">
            <div className="flex-1">
              <PickupReportChart />
            </div>
            <div className="flex-1">
              <CalendarView />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}