export const calendarTypes = {
  // BEATING_LAST_YEAR: "BEATING_LAST_YEAR",
  SPECIAL_EVENT: "SPECIAL_EVENT",
  // UNDER_PERFORMING_LY: "UNDER_PERFORMING_LY",
  PRICE_OVERRIDE: "PRICE_OVERRIDE",
};

export const calendarTypeTitles = {
  // [calendarTypes.BEATING_LAST_YEAR]: "Beating Last Year",
  [calendarTypes.SPECIAL_EVENT]: "Special Event",
  // [calendarTypes.UNDER_PERFORMING_LY]: "Under Performing LY",
  [calendarTypes.PRICE_OVERRIDE]: "Price Override",
};

export const calendarTypeColors = {
  // [calendarTypes.BEATING_LAST_YEAR]: "bg-green-100 border-green-250",
  [calendarTypes.SPECIAL_EVENT]: "bg-[#FAF3F0] border-[#F2ECE8]",
  // [calendarTypes.UNDER_PERFORMING_LY]: "bg-[#ECF3FF] border-[#3984FF]",
  [calendarTypes.PRICE_OVERRIDE]: "bg-[#E8F1F2] border-[#E5E7EB]",
};
