import {
  Bar,
  BarChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Panel } from "../../../components/Panel";
import moment from "moment";
import { gradientColors } from "./constant";
import { commaSeparatedNumber } from "../../../helpers/utils";

const CustomTooltip = ({ active, payload, label, field }: any) => {
  const data = payload?.[0]?.payload;
  console.log("data", data);
  return (
    <div className="p-2.5 w-[100px] bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-3 flex-col flex">
      <div className="flex-col justify-start items-start gap-0.5 flex">
        <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
          {moment(data?.name).format("MMM DD")}
        </div>
      </div>
      <div className="justify-start items-center gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-[#E5E7EB] rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']"></div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
          {data?.ceiling ? commaSeparatedNumber(data?.ceiling, false) : "-"}
          </div>
        </div>
      </div>
      <div className="justify-start items-center gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-black rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']"></div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
          {data?.floor ? commaSeparatedNumber(data?.floor, false) : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

function RenderChart({
  season,
  maxDomain,
  isSelected = false,
  styleConfig,
}: any) {
  const data = [
    {
      name: season.startDate,
      ceiling: season?.priceCeiling,
      floor: season?.weekdayPriceFloor,
    },
    {
      name: season.endDate,
      ceiling: season?.priceCeiling,
      floor: season?.weekdayPriceFloor,
    },
  ];
  console.log("styleConfig", styleConfig);
  return (
    <div className="w-[94px] flex flex-col items-center">
      <div className={`text-xs font-medium ${styleConfig.textStyle}`}>
        {season?.name}
      </div>
      <ResponsiveContainer width={94} height={154}>
        <BarChart data={data}>
          <Tooltip content={<CustomTooltip />} />
          <YAxis
            tick={false}
            type="number"
            domain={[0, maxDomain]}
            axisLine={false}
            tickLine={false}
            width={0}
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            fontSize={11}
            color="#8B8C91"
            height={15}
            interval={0}
            tickFormatter={(value) => {
              return moment(value).format("MMM DD");
            }}
          />
          <Bar
            dataKey="ceiling"
            fill="#E5E7EB"
            barSize={12}
            radius={[2, 2, 0, 0]}
          />
          <Bar dataKey="floor" fill="#000" barSize={12} radius={[2, 2, 0, 0]} />
          <defs>
            <linearGradient
              id={`MyGradient${styleConfig.id}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stop-color={styleConfig.stop1} />
              <stop offset="100%" stop-color={styleConfig.stop2} />
            </linearGradient>
          </defs>

          <ReferenceArea
            x1={season.startDate}
            x2={season.endDate}
            y1={0}
            stroke={styleConfig.stroke}
            strokeWidth={isSelected ? 2 : 0}
            radius={10}
            fill={`url(#MyGradient${styleConfig.id})`}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

interface ChartProps {
  seasons: any;
  selectedSeason: any;
  tooltip?: React.ReactElement;
}

export function Chart({ seasons, selectedSeason, tooltip }: ChartProps) {
  const numbers: number[] = [];
  seasons.forEach((season) => {
    numbers.push(
      Number(season?.priceCeiling || 0),
      Number(season?.weekdayPriceFloor || 0)
    );
  });
  const maxDomain = Math.max(...numbers);

  console.log("maxDomain", maxDomain, seasons);
  return (
    <Panel
      label=""
      className="py-0 px-4 lg:px-[34px] w-[calc(100vw-40px)] md:w-[50.5vw] lg:w-[41vw] 3xl:w-[40vw] overflow-auto"
      showHeader={false}
    >
      <div className="py-4 lg:py-6 flex gap-3.5 items-center">
        {seasons.map((season: any, index: number) => {
          // 4 colors then repeat
          const colorIndex = index % 4;
          return (
            <RenderChart
              key={season?.id}
              season={season}
              maxDomain={maxDomain + (maxDomain * 10) / 100}
              isSelected={selectedSeason?.id === season?.id}
              styleConfig={gradientColors[colorIndex]}
            />
          );
        })}
      </div>
    </Panel>
  );
}