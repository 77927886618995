import { useState } from "react";
import { ReactComponent as CheckMark } from "../assets/icons/check-mark.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";

export function Accordion({
  children,
  title,
  hasCheckMark,
  titleClassName = "",
}: {
  children: any;
  title: string;
  hasCheckMark?: boolean;
  titleClassName?: string;
}) {
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div className="w-full pt-3 pb-4 border-b border-[#F2F2F2] flex-col justify-start items-start gap-5 flex">
      <button
        onClick={() => setIsExpand((prev) => !prev)}
        className="justify-between items-center flex w-full gap-1.5"
      >
        <div className="justify-start items-center gap-2 flex">
          {hasCheckMark && (
            <div className="bg-primary w-5 h-5 rounded-full flex items-center justify-center">
              <CheckMark />
            </div>
          )}
          <div
            className={`text-black flex-1 text-lg text-left ${
              !hasCheckMark
                ? isExpand
                  ? "font-semibold !text-sm"
                  : "font-normal !text-sm !text-[#525254]"
                : "font-semibold"
            } ${titleClassName}`}
          >
            {title}
          </div>
        </div>
        <ChevronDown
          className={`min-w-4 min-h-4 w-4 h-4 ${isExpand ? "rotate-180" : ""}`}
        />
      </button>
      {isExpand && <div className="w-full">{children}</div>}
    </div>
  );
}
