import { useEffect, useRef, useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import moment from "moment";
import { YearSelect } from "../../dashboard/components/YearSelect";
import { MonthSelect } from "../../dashboard/components/MonthSelect";
import { Radio } from "../../../components/Radio";
import CustomButton from "../../../components/Button";
import { api } from "../../../helpers/api";

const Filters = {
    YTD: "YTD",
    Year: "Year",
    Month: "Month",
};

const getYTDDates = () => {
    const startDate = moment().startOf("year").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    return { startDate, endDate };
};

const getYearDates = (year?: string | number) => {
    const currentYear = year ? Number(year) : new Date().getFullYear();
    const startDate = moment()
        .set("year", currentYear)
        .startOf("year")
        .format("YYYY-MM-DD");
    const endDate = moment()
        .set("year", currentYear)
        .endOf("year")
        .format("YYYY-MM-DD");
    return { startDate, endDate };
};

const getMonthDates = (month?: string | number) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
    const startDate = moment()
        .set("year", currentYear)
        .set("month", currentMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
    const endDate = moment()
        .set("year", currentYear)
        .set("month", currentMonth)
        .endOf("month")
        .format("YYYY-MM-DD");
    return { startDate, endDate };
};

const FilterView = ({ setFilter, filter, onApply }) => {
    const ytdDates = getYTDDates();

    return (
        <div className="absolute z-[1] w-[290px] right-6 top-10 p-2 bg-white rounded shadow border border-[#E5E7EB] mt-1">
            <div>
                <div className="self-stretch h-24 flex-col justify-start items-start flex">
                    <div className="self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex">
                        <div className="justify-start items-center gap-1.5 flex text-[#000]">
                            <Radio
                                checked={filter.type === Filters.YTD}
                                onChange={() => setFilter((prev) => ({ type: Filters.YTD, ...getYTDDates() }))}
                                label="Year to date"
                            />
                        </div>
                        <div className="opacity-60 text-black text-xs font-medium leading-3">
                            {moment(ytdDates.startDate).format("MM/DD/YYYY")} - {moment(ytdDates.endDate).format("MM/DD/YYYY")}
                        </div>
                    </div>
                    <div className="self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex">
                        <Radio
                            checked={filter.type === Filters.Year}
                            onChange={() => setFilter((prev) => ({ type: Filters.Year, ...getYearDates() }))}
                            label="Any year"
                        />
                        <YearSelect
                            disabled={filter.type !== Filters.Year}
                            value={moment(filter.startDate).format("YYYY")}
                            onChange={(year) => setFilter((prev) => ({ ...prev, ...getYearDates(year) }))}
                        />
                    </div>
                    <div className="self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex">
                        <Radio
                            checked={filter.type === Filters.Month}
                            onChange={() => setFilter((prev) => ({ type: Filters.Month, ...getMonthDates() }))}
                            label="Any month"
                        />
                        <div className="justify-start items-center gap-1.5 flex">
                            <MonthSelect
                                disabled={filter.type !== Filters.Month}
                                value={moment(filter.startDate).format("M")}
                                onChange={(month) => setFilter((prev) => ({ ...prev, ...getMonthDates(month) }))}
                            />
                            <YearSelect
                                disabled={filter.type !== Filters.Month}
                                value={moment(filter.startDate).format("YYYY")}
                                onChange={(year) => setFilter((prev) => ({
                                    ...prev,
                                    ...getMonthDates(moment(prev.startDate).format("M")),
                                    startDate: moment(prev.startDate).set("year", Number(year)).format("YYYY-MM-DD"),
                                    endDate: moment(prev.endDate).set("year", Number(year)).format("YYYY-MM-DD")
                                }))}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end p-2">
                    <CustomButton
                        onClick={onApply}
                        className="!h-[30px] text-white text-[13px] font-medium"
                    >
                        Apply
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export function MarketingSummaryShort() {
    const user = getUser();
    const [data, setData] = useState<any>({});
    const [filter, setFilter] = useState({ type: "YTD", ...getYTDDates() });
    const [showFilter, setShowFilter] = useState(false);
    const containerRef = useRef(null);

    // Mock data - replace with actual API call
    const fetchMarketingMetrics = async () => {
        // Simulate API call
        //const averageTimePerVisit = await api.getAnalytics(user.id, { analyticsType: "UserEngagement", startDate: filter.startDate, endDate: filter.endDate })
        const response = await api.getMetrics(user.id, { type: "TotalRevenue", startDate: filter.startDate, endDate: filter.endDate })
        console.log("MARKETING HEADER", response)
        setData({
            revenue: response.revenue,
            roomsSold: response.bookings,
            websiteVisits: response.visits,
            avgTimePerVisit: response.userEngagement
        });
    };

    useEffect(() => {
        fetchMarketingMetrics();
    }, [filter.startDate, filter.endDate]);

    const handleApply = () => {
        setShowFilter(false);
        fetchMarketingMetrics();
    };

    // Outside click handler
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                containerRef.current &&
                !(containerRef.current as HTMLElement).contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div ref={containerRef} className="relative w-full pt-1">
            {/* Add header section */}
            <div className="flex items-center justify-between mb-2">
                <div className="flex flex-row gap-2 items-center">
                    <h1 className="text-[16px] lg:text-[18px] tall:lg:text-[20px] tall:xl:text-[20px] font-semibold text-black">
                        Marketing Dashboard
                    </h1>
                </div>

                {/* Period filter */}
                <div className="justify-start items-center gap-1 flex">
                    <CalendarIcon className="w-4 h-4 mt-1" />
                    <button
                        type="button"
                        onClick={() => setShowFilter((p) => !p)}
                        className="justify-start items-center gap-1 flex"
                    >
                        <div>
                            <span className="text-[#646466] text-xs font-medium leading-none">
                                Period:
                            </span>
                            <span className="text-[#525253] text-xs font-medium leading-none">
                                {" "}
                            </span>
                            <span className="text-black text-xs font-medium leading-none">
                                {filter.type === Filters.YTD
                                    ? "Year to date"
                                    : filter.type === Filters.Year
                                        ? moment(filter.startDate).format("YYYY")
                                        : moment(filter.startDate).format("MMM. YYYY")}
                            </span>
                        </div>
                        <ChevronDownIcon
                            className={`w-3 h-3 ${showFilter ? "rotate-180 pb-1" : "pt-1"}`}
                        />
                    </button>
                </div>
            </div>

            {/* Updated Marketing Metrics Grid */}
            <div className="w-full grid grid-cols-4 gap-4 bg-white p-2 border rounded-lg">
                {/* Revenue Card */}
                <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
                    <div className="space-y-2 space-between w-[100%]">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <span className="text-xs text-muted-foreground">Total Revenue</span>
                                <p className="text-xs font-semibold">
                                    {commaSeparatedNumber(Number(data?.revenue?.current || 0), false)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Rooms Sold Card */}
                <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
                    <div className="space-y-2 space-between w-[100%]">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <span className="text-xs text-muted-foreground">Total Rooms Sold</span>
                                <p className="text-xs font-semibold">
                                    {Number(data?.roomsSold?.current || 0).toLocaleString()}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Website Visits Card */}
                <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
                    <div className="space-y-2 space-between w-[100%]">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <span className="text-xs text-muted-foreground">Total Website Visits</span>
                                <p className="text-xs font-semibold">
                                    {Number(data?.websiteVisits?.current || 0).toLocaleString()}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Avg Time Per Visit Card */}
                <div className="p-3 rounded-md bg-[#F5F1F0] items-center flex justify-between my-auto">
                    <div className="space-y-2 space-between w-[100%]">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <span className="text-xs text-muted-foreground">Avg. Time Per Visit</span>
                                <p className="text-xs font-semibold">
                                    {data?.avgTimePerVisit?.current > 1 ? `${Math.floor(Number(data?.avgTimePerVisit?.current.toFixed(0) || 0) / 60).toFixed(0)}m ${Number(data?.avgTimePerVisit?.current.toFixed(0) || 0) % 60}s` : '<1s'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showFilter && (
                <FilterView
                    onApply={handleApply}
                    filter={filter}
                    setFilter={setFilter}
                />
            )}
        </div>
    );
}