import { ReactComponent as MewsIcon } from "../../../assets/icons/mews.svg";
import { ReactComponent as CloudBedsIcon } from "../../../assets/icons/cloud-beds.svg";
import { ReactComponent as ConnectArrow } from "../../../assets/icons/connect-arrow.svg";
import { ReactComponent as Success } from "../../../assets/icons/check.svg";
import { ReactComponent as AutumnLogo } from "../../../assets/icons/autumn-logo.svg";

import { Card } from "../../../components/Card";
import { ActionButtons } from "./ActionButtons";
import { useState } from "react";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { ShowToast } from "../../../components/toast";
import CustomInput from "../../../components/Input";
import { PMSManualKeyModal } from "./PMSManualKeyModal";
import moment from "moment";

const pmsConfig = {
  MEWS: {
    title: "MEWS",
    title2: "Mews",
    icon: <MewsIcon className="w-[100px] lg:!w-[119px] !h-6" />,
    signInLink: "https://app.mews.com/Commander/Home/SignIn",
    link: "https://help.mews.com/s/article/find-an-integration-s-access-token?language=en_US#find_the_access_token",
  },
  CLOUDBEDS: {
    title: "Cloudbeds",
    title2: "Cloudbeds",
    icon: <CloudBedsIcon className="w-[100px] lg:!w-[119px] !h-6" />,
    signInLink: "https://signin.cloudbeds.com",
    link: "https://myfrontdesk.cloudbeds.com/hc/en-us/articles/360003183494-Marketplace-Apps-integrated-with-Cloudbeds-PMS",
  },
};

function Step({ children, number }) {
  return (
    <div className="justify-start items-start gap-2 flex">
      <div className="p-px justify-start items-center gap-2.5 flex">
        <div className="w-[22px] h-[22px] p-2.5 bg-[#E0EBE6] rounded-full flex-col justify-center items-center gap-2.5 flex">
          <div className="text-[#2F3D37] text-xs font-semibold leading-normal">
            {number}
          </div>
        </div>
      </div>
      <div className="flex-1 grow shrink basis-0 text-[#525253] text-sm leading-normal">
        {children}
      </div>
    </div>
  );
}

function SuccessMews() {
  return (
    <>
      <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
        Congratulations! Your PMS is now connected.
      </p>
      <div className="flex flex-col mt-7 items-center gap-3 justify-center">
        <div className="relative text-center flex justify-center items-center">
          <Success className="w-[145px] h-[145px]" />
        </div>
        <div className="text-neutral-700 text-sm leading-[19.2px]">
          Gathering data from PMS
        </div>
      </div>
      <p className="mt-7 text-neutral-700 text-sm leading-[22px]">
        We'll gather the data in the background and let you know if anything
        looks off.
      </p>
    </>
  );
}

function MewsForm({ token, setToken }) {
  return (
    <>
      <div className="flex-col justify-start items-start gap-5 flex">
        <CustomInput
          label=""
          placeholder="Enter key here"
          onChange={(e) => setToken(e.target.value)}
          value={token}
          // error={errors.validHotelWebsite}
          className="w-full mt-5"
        />
      </div>
    </>
  );
}

function LoadingMews() {
  return (
    <>
      <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
        Please wait, while we stablish a direct connection with your PMS...
      </p>
      <div className="flex flex-col mt-7 items-center gap-3 justify-center">
        <div className="relative text-center flex justify-center items-center">
          <ConnectArrow className="absolute " />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
            width="146"
            height="145"
            viewBox="0 0 146 145"
            fill="none"
          >
            <path
              d="M73 -3.19093e-06C113.317 -4.95323e-06 146 32.4594 146 72.5C146 112.541 113.317 145 73 145C32.6832 145 -1.41884e-06 112.541 -3.16908e-06 72.5C-4.91931e-06 32.4594 32.6832 -1.42863e-06 73 -3.19093e-06ZM73 142.1C111.704 142.1 143.08 110.939 143.08 72.5C143.08 34.061 111.704 2.89999 73 2.89999C34.2959 2.89999 2.91999 34.061 2.91999 72.5C2.92 110.939 34.2959 142.1 73 142.1Z"
              fill="#E1DDD2"
            />
            <path
              d="M73 -3.19093e-06C87.8263 -2.54286e-06 102.301 4.48356 114.499 12.8542C126.696 21.2248 136.038 33.0856 141.282 46.8586L138.551 47.8843C133.517 34.6622 124.548 23.2758 112.839 15.24C101.129 7.20422 87.2332 2.89999 73 2.89999L73 -3.19093e-06Z"
              fill="#2F3D37"
            />
          </svg>
        </div>
        <div className="text-neutral-700 text-sm leading-[19.2px]">
          Connecting...
        </div>
      </div>
      <p className="mt-7 text-neutral-700 text-sm leading-[22px]">
        This may take a few minutes; we will email you when it’s done. You can
        continue with the onboarding in the meantime.
      </p>
    </>
  );
}

export function ConnectMews({ onBack, onNext, form, setForm }) {
  const [showMEWSForm, setShowMEWSForm] = useState(false);
  const [token, setToken] = useState("");
  const [connecting, setConnecting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState<
    "information" | "form" | "success"
  >("information");
  const user = getUser();

  const connect = async () => {
    try {
      // save the form data
      const userId = user.id;
      setConnecting(true);
      const response = await api.connectPMS(userId, {
        pms: form.pms,
        accessToken: token,
      });
      // const fireAndForgetSyncBookings = async () => {
      //   try {
      //     await api.syncBookings(user?.id, {
      //       startDate: moment().subtract(3, "year").format("YYYY-MM-DD"),
      //       endDate: moment().add(1, "year").format("YYYY-MM-DD"),
      //     });
      //    //console.log("Sync bookings initiated.");
      //   } catch (error) {
      //     console.error("Sync Bookings Error", error);
      //   }
      // };
      
      // fireAndForgetSyncBookings();
      setConnecting(false);
      if ([200, 201].includes(response.status)) {
        setSuccess(true);
        setCurrentStep("success");
      }
    } catch (error: any) {
      setConnecting(false);
      setShowMEWSForm(true);
      setCurrentStep("form");
      ShowToast({
        type: "error",
        message:
          error?.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const handleBack = () => {
    if (currentStep === "form") {
      setShowMEWSForm(false);
      setCurrentStep("information");
    } else if (currentStep === "success") {
      setSuccess(false);
      setShowMEWSForm(true);
      setCurrentStep("form");
    } else {
      onBack();
    }
  };

  const handleNext = () => {
    if (currentStep === "information") {
      setTimeout(() => {
        setShowMEWSForm(true);
        setCurrentStep("form");
      }, 2000);
      setConnecting(true);
    } else if (currentStep === "form") {
      connect();
      setShowMEWSForm(false);
      setConnecting(true);
      setCurrentStep("success");
    } else {
      onNext();
    }
  };
  const pmsInfo = pmsConfig[form.pms] || {};
  return (
    <Card className="lg:max-w-[670px] lg:w-[650px] !px-6 lg:!px-8">
      {showMEWSForm ? (
        <div className="justify-between items-center flex">
          <div className="text-black text-2xl font-semibold">
            Enter {pmsInfo.title} API key
          </div>
          <a
            target="_blank"
            href={pmsInfo.link}
            className="text-[#2F3D37] text-sm font-normal leading-normal"
            rel="noreferrer"
          >
            Where to find?
          </a>
        </div>
      ) : (
        <div className="flex items-center gap-2 lg:gap-3">
          <span className="text-xl flex gap-2 items-center lg:text-2xl font-semibold text-black">
            Connect <AutumnLogo className="w-[100px] lg:!w-[119px] !h-6" /> to
          </span>
          {pmsInfo.icon}
        </div>
      )}
      {connecting ? (
        <LoadingMews />
      ) : success ? (
        <>
          <SuccessMews />
        </>
      ) : (
        <>
          <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
            To connect your PMS to Autumn, you need to initiate a connection
            request from [{pmsInfo.title2}]. It's simple & below are the steps.
          </p>
          <div className="my-7 flex flex-col gap-4">
            <Step number={1}>
              <div>
                <a
                  href={pmsInfo.signInLink}
                  target="_blank"
                  className="text-[#2F3D37] text-sm font-normal font-['Inter'] leading-normal"
                  rel="noreferrer"
                >
                  Login to your {pmsInfo.title2} account
                </a>
                <span className="text-[#525253] text-sm font-normal font-['Inter'] leading-normal">
                  {" "}
                  and visit the 'Marketplace.
                </span>
              </div>
            </Step>
            <Step number={2}>Search for 'Autumn'.</Step>
            <Step number={3}>Click 'See More' and then 'Connect'</Step>
            <Step number={4}>Complete the form.</Step>
            <Step number={5}>Click 'Create', followed by 'OK'.</Step>
          </div>
        </>
      )}
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disabled={showMEWSForm ? !token : connecting}
      />
      {showMEWSForm && (
        <PMSManualKeyModal
          token={token}
          setToken={setToken}
          pmsInfo={pmsInfo}
          onClose={() => setShowMEWSForm(false)}
          onConnect={handleNext}
          
        />
      )}
    </Card>
  );
}
