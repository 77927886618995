import { useState } from "react";
import CustomInput from "../../../components/Input";
import { PriceInput } from "../../../components/PriceInput";
import { ActionButtons } from "./ActionButtons";
import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useOnboardingSave } from "../useOnboardingSave";
import { validateEventsForm } from "../useValidation";
import moment from "moment";
import { Tooltip } from "../../../components/tooltip";
import { getDate } from "../../../helpers/utils";

export function MobileSeasonAndEvents({
  handlePriceChange,
  event,
  setForm,
  className = "",
  isSingle = false,
  handleRemove,
}: {
  handlePriceChange: any;
  event: any;
  setForm: (data: any) => void;
  className?: string;
  isSingle?: boolean;
  handleRemove?: any;
}) {
  return (
    <div
      className={`p-3.5 relative rounded bg-white border border-neutral-400 shadow-[0px_2px_6px_0px_rgba(20,20,43,0.06)] ${className}`}
    >
      {handleRemove && (
        <button
          className="absolute top-1 right-1"
          onClick={() => handleRemove(event.id)}
        >
          <TrashIcon />
        </button>
      )}
      <div className="text-black text-sm font-semibold mb-[18px] leading-[14px]">
        <CustomInput
          label="Season Name"
          value={event.name}
          onChange={(e) => {
            if (isSingle) {
              setForm((prev) => {
                return { ...prev, name: e.target.value };
              });
            } else {
              setForm((prev) => {
                const newEvents = [...(prev.seasons || [])];
                const index = newEvents.findIndex((e) => e.id === event.id);
                newEvents[index].name = e.target.value;
                return { ...prev, seasons: newEvents };
              });
            }
          }}
          className="[&_label]:text-[#525254] [&_label]:!text-3xs [&_input]:h-[29px] [&_input]:!text-2xs"
        />
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <div className="text-[#525254] pt-1 text-3xs mb-2 font-medium leading-[10px]">
              Duration
            </div>
            <div className="flex items-center [&_.react-datepicker-wrapper]:!flex-1 [&_.react-datepicker-wrapper>div]:flex">
              <DatePicker
                showIcon
                // icon={CalendarIcon}
                icon={<CalendarIcon className="!w-4 !h-4" />}
                toggleCalendarOnIconClick
                selected={event.startDate ? getDate(event.startDate) : null}
                onChange={(date) => {
                  if (isSingle) {
                    setForm((prev) => {
                      return {
                        ...prev,
                        startDate: moment(date).format("YYYY-MM-DD"),
                      };
                    });
                  } else {
                    setForm((prev) => {
                      const newEvents = [...(prev.seasons || [])];
                      const index = newEvents.findIndex(
                        (e) => e.id === event.id
                      );
                      newEvents[index].startDate = date;
                      return { ...prev, seasons: newEvents };
                    });
                  }
                }}
                dateFormat="MMM d"
                className="rounded-l px-3 flex-1  py-1.5 text-2xs font-medium border-neutral-400 border border-r-0 items-center max-w-[75px] h-[30.5px]"
              />
              <DatePicker
                showIcon
                icon={<CalendarIcon className="!w-4 !h-4" />}
                toggleCalendarOnIconClick
                selected={event.endDate ? getDate(event.endDate) : null}
                onChange={(date) => {
                  if (isSingle) {
                    setForm((prev) => {
                      return {
                        ...prev,
                        endDate: moment(date).format("YYYY-MM-DD"),
                      };
                    });
                  } else {
                    setForm((prev) => {
                      const newEvents = [...(prev.seasons || [])];
                      const index = newEvents.findIndex(
                        (e) => e.id === event.id
                      );
                      newEvents[index].endDate = date;
                      return { ...prev, seasons: newEvents };
                    });
                  }
                }}
                dateFormat="MMM d"
                className="rounded-r px-3  py-1.5 text-2xs font-medium border-neutral-400 border  items-center max-w-[75px] flex-1 h-[30.5px]"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="text-[#525254] pt-1 text-3xs mb-2 font-medium leading-[10px]">
              Weekday Price Floor
            </div>
            <PriceInput
              value={event.weekdayPriceFloor}
              onChange={(val) =>
                handlePriceChange("weekdayPriceFloor", val, event.id)
              }
              className="w-full"
            />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <div className="text-neutral-800 text-3xs mb-1.5 font-medium leading-[10px]">
              Weekday Price Floor
            </div>
            <PriceInput
              value={event.weekendPriceFloor}
              onChange={(val) =>
                handlePriceChange("weekendPriceFloor", val, event.id)
              }
              className="w-full"
            />
          </div>
          <div className="flex-1">
            <div className="text-[#525254] text-3xs mb-2 font-medium leading-[10px]">
              Price Ceiling
            </div>

            <PriceInput
              value={event.priceCeiling}
              onChange={(val) =>
                handlePriceChange("priceCeiling", val, event.id)
              }
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SeasonsAndEventsForm({ setForm, form, isReview = false }) {
  const [events, setEvents] = useState<any[]>([
    {
      id: 1,
      name: "string",
      startDate: "2024-08-16T16:53:14.307Z",
      endDate: "2024-08-16T16:53:14.307Z",
      weekdayPriceFloor: 0,
      weekendPriceFloor: 0,
      priceCeiling: 0,
    },
  ]);
  const [text, setText] = useState("");

  const handleAdd = () => {
    setForm((prev) => {
      const newEvents = [...(prev.seasons || [])];

      const last = newEvents?.length
        ? newEvents[newEvents.length - 1].id + 1
        : 1;
      return { ...prev, seasons: [...newEvents, { id: last }] };
    });
  };

  const handleRemove = (eventId) => {
    setForm((prev) => {
      const newEvents = [...(prev.seasons || [])];
      return { ...prev, seasons: newEvents.filter((e) => e.id !== eventId) };
    });
  };

  const handlePriceChange = (key, value, id) => {
    setForm((prev) => {
      const newEvents = [...(prev.seasons || [])];
      const index = newEvents.findIndex((e) => e.id === id);
      if (index === -1) return prev;
      newEvents[index][key] = value;
      return { ...prev, seasons: newEvents };
    });
  };

  return (
    <>
      <div className="flex flex-col [&_label]:!text-neutral-700">
        {!isReview && (
          <div className="text-xl lg:text-2xl font-semibold px-4 lg:px-8">
            Seasons & events
          </div>
        )}
        <div className=" text-neutral-700 mt-2 mb-5 flex [&>div]:mt-1 md:[&>div]:mt-0 items-start md:items-center gap-1 text-sm leading-[22px] px-4 lg:px-8">
          <span>Create rules for each season or for special events</span>
          <Tooltip id="special-events-tooltip" className="!w-[300px]">
            This defines the main room category used for pricing calculations in
            the algorithm. You can select price differentials between this and
            other room categories, allowing you to set higher or lower prices
            for different room types.
          </Tooltip>
        </div>
        <div
          className={`hidden lg:flex flex-col gap-3 pb-2 px-4 lg:px-8 ${
            !isReview ? "h-[calc(100dvh-400px)] overflow-auto" : ""
          }`}
        >
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 z-[1] text-neutral-800 bg-neutral-300 rounded [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 [&_td]:px-[14px]">
                <td className="text-[#000]">Season Name</td>
                <td className="text-[#000]">Duration</td>
                <td className="text-[#000]">Weekday Price Floor</td>
                <td className="text-[#000]">Weekend Price Floor</td>
                <td className="text-[#000]">Price Ceiling</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {form?.seasons?.map((event) => (
                <tr
                  key={event.id}
                  className="[&_td]:py-2.5 [&_td]:border-b [&_td]:border-b-neutral-400"
                >
                  <td className="pl-3">
                    <CustomInput
                      label=""
                      value={event.name}
                      onChange={(e) => {
                        setForm((prev) => {
                          const newEvents = [...(prev.seasons || [])];
                          const index = newEvents.findIndex(
                            (e) => e.id === event.id
                          );
                          newEvents[index].name = e.target.value;
                          return { ...prev, seasons: newEvents };
                        });
                      }}
                      className="max-w-[100px] [&_input]:h-[29px] [&_input]:!text-2xs"
                    />
                  </td>
                  <td>
                    <div className="flex items-center">
                      <DatePicker
                        showIcon
                        // icon={CalendarIcon}
                        icon={<CalendarIcon className="!w-4 !h-4" />}
                        toggleCalendarOnIconClick
                        selected={
                          event.startDate ? getDate(event.startDate) : null
                        }
                        onChange={(date) => {
                          setForm((prev) => {
                            const newEvents = [...(prev.seasons || [])];
                            const index = newEvents.findIndex(
                              (e) => e.id === event.id
                            );
                            newEvents[index].startDate =
                              moment(date).format("YYYY-MM-DD");
                            return { ...prev, seasons: newEvents };
                          });
                        }}
                        dateFormat="MMM d"
                        className="rounded-l px-3  py-1.5 text-2xs font-medium border-neutral-400 border border-r-0 items-center w-[87px]"
                      />
                      <DatePicker
                        showIcon
                        icon={<CalendarIcon className="!w-4 !h-4" />}
                        toggleCalendarOnIconClick
                        selected={event.endDate ? getDate(event.endDate) : null}
                        onChange={(date) => {
                          setForm((prev) => {
                            const newEvents = [...(prev.seasons || [])];
                            const index = newEvents.findIndex(
                              (e) => e.id === event.id
                            );
                            newEvents[index].endDate =
                              moment(date).format("YYYY-MM-DD");
                            return { ...prev, seasons: newEvents };
                          });
                        }}
                        dateFormat="MMM d"
                        className="rounded-r px-3  py-1.5 text-2xs font-medium border-neutral-400 border  items-center w-[87px]"
                      />
                    </div>
                  </td>
                  <td>
                    <PriceInput
                      value={event.weekdayPriceFloor}
                      onChange={(val) =>
                        handlePriceChange("weekdayPriceFloor", val, event.id)
                      }
                    />
                  </td>
                  <td>
                    <PriceInput
                      value={event.weekendPriceFloor}
                      onChange={(val) =>
                        handlePriceChange("weekendPriceFloor", val, event.id)
                      }
                    />
                  </td>
                  <td>
                    <PriceInput
                      value={event.priceCeiling}
                      onChange={(val) =>
                        handlePriceChange("priceCeiling", val, event.id)
                      }
                    />
                  </td>
                  <td>
                    <button onClick={() => handleRemove(event.id)}>
                      <TrashIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            className="flex hover:bg-black/5 text-sm font-medium justify-center gap-2 items-center px-4 py-2.5 rounded border border-neutral-500"
            onClick={handleAdd}
          >
            <PlusIcon className="[&_path]:stroke-black" />
            <span>Add more</span>
          </button>
        </div>
        <div className="flex flex-col gap-3 pb-2 px-4 lg:hidden">
          {form?.seasons?.map((event) => (
            <MobileSeasonAndEvents
              key={event.id}
              setForm={setForm}
              handlePriceChange={handlePriceChange}
              event={event}
              handleRemove={handleRemove}
            />
          ))}
          <button
            className="flex hover:bg-black/5 text-sm font-medium justify-center gap-2 items-center px-4 py-2.5 rounded border border-neutral-500"
            onClick={handleAdd}
          >
            <PlusIcon className="[&_path]:stroke-black" />
            <span>Add more</span>
          </button>
        </div>
      </div>
    </>
  );
}

export function SeasonsAndEvents({
  onNext,
  onBack,
  step,
  form,
  setForm,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
  form: any;
  setForm: (data: any) => void;
}) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const handleNext = async () => {
    onSave(form);
  };
  const { isValid, errors } = validateEventsForm(form);

  const handleBack = () => {
    // clear invalid seasons object
    setForm((prev) => {
      const newSeasons = prev.seasons?.filter(
        (season) =>
          season.name &&
          season.startDate &&
          season.endDate &&
          (season.weekdayPriceFloor ||
            season.weekendPriceFloor ||
            season.priceCeiling)
      );
      return { ...prev, seasons: newSeasons };
    });
    onBack();
  };

  return (
    <>
      <OnboardingSidebar title="Seasons & events">
        <p className="text-xs leading-[16.8px] text-black">
          Seasonality affects demand and pricing at your property throughout the
          year, typically in 2-3 month periods influenced by factors like
          weather, holidays, and local events.
        </p>
        <p className="text-xs leading-[16.8px] text-black mt-2">
          We've designed these Seasonal Settings to allow you to customize
          certain rules during these varying times of the year.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Seasons & events"
        infoView={
          <>
            <p className="text-xs leading-[16.8px] text-black">
              Seasonality affects demand and pricing at your property throughout
              the year, typically in 2-3 month periods influenced by factors
              like weather, holidays, and local events.
            </p>
            <p className="text-xs leading-[16.8px] text-black mt-2">
              We've designed these Seasonal Settings to allow you to customize
              certain rules during these varying times of the year.
            </p>
          </>
        }
      >
        <Card className="w-full lg:max-w-[690px] lg:w-[690px] px-0 !py-6 lg:!py-8">
          <SeasonsAndEventsForm setForm={setForm} form={form} />
          <div className="px-8">
            <ActionButtons
              onBack={handleBack}
              onNext={handleNext}
              loading={saving}
              disabled={!isValid}
            />
          </div>
        </Card>
      </OnboardingView>
    </>
  );
}
