import { PopupModal } from "../../../components/Modal";
import Button from "../../../components/Button";
import { useState } from "react";

import Spinner from "../../../components/Spinners";
export function ConfirmDeleteSeason({
  onClose,
  onConfirm,
  season,
}: {
  onClose: () => void;
  onConfirm: () => void;
  season: any;
}) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to delete{" "}
        <span className="font-medium">{season?.name}</span> season? Once you
        confirm, this action can not be undone.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose} disabled={isLoading}>
          {isLoading ? <Spinner /> : "No, go back"}
          
        </Button>
        <Button className="px-8 text-[1rem]" onClick={async () => {
          setIsLoading(true);
          await onConfirm();
        }} disabled={isLoading}>
          {isLoading ? <Spinner /> : "Yes, delete"}
        </Button>
      </div>
    </PopupModal>
  );
}
