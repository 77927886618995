import { useEffect, useState } from "react";
import { Panel } from "../../../components/Panel";
import { PriceInput } from "../../../components/PriceInput";
import { CalendarView } from "./CalendarView";
import { Chart } from "./Chart";
import Checkbox from "./Checkbox";
import { SeasonSelect } from "./SeasonSelect";
import { SeasonFormModal } from "./SeasonFormModal";
import Button from "../../../components/Button";
import { validateEventsForm } from "../../onboarding/useValidation";
import moment from "moment";
import { api } from "../../../helpers/api";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import { pageTitles } from "../Settings";
import { ConfirmDeleteSeason } from "./ConfirmDeleteSeason";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash.svg";
import { Tooltip } from "recharts";

export function MobileSeason({
  season,
  setForm,
  className = "",
  weekdayFieldName,
  weekendFieldName,
  dynamicFieldName,
}) {
  return (
    <div
      className={`p-3.5 rounded bg-white border border-neutral-400 shadow-[0px_2px_6px_0px_rgba(20,20,43,0.06)] ${className}`}
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <div className="text-[#525254] pt-1 text-3xs mb-2 font-medium leading-[10px]">
              Weekday
            </div>
            <PriceInput
              onChange={(value) => {
                setForm((prev) => {
                  const seasons = prev?.seasons.map((s: any) => {
                    if (s.id === season.id) {
                      return {
                        ...s,
                        [weekdayFieldName]: value,
                      };
                    }
                    return s;
                  });
                  return {
                    ...prev,
                    seasons,
                  };
                });
              }}
              value={season?.weekdayPriceFloor}
              className="w-[66px] !h-6 !text-black"
              readOnly={season?.[dynamicFieldName]}
            />
          </div>
          <div className="flex-1">
            <div className="text-[#525254] pt-1 text-3xs mb-2 font-medium leading-[10px]">
              Weekend
            </div>
            <PriceInput
              onChange={(value) => {
                setForm((prev) => {
                  const seasons = prev?.seasons.map((s: any) => {
                    if (s.id === season.id) {
                      return {
                        ...s,
                        [weekendFieldName]: value,
                      };
                    }
                    return s;
                  });
                  return {
                    ...prev,
                    seasons,
                  };
                });
              }}
              value={season?.[weekendFieldName]}
              readOnly={season?.[dynamicFieldName]}
              className="w-[66px] !h-6 !text-black"
            />
          </div>
          <div className="flex-1">
            <div className="text-[#525254] pt-1 text-3xs mb-2 font-medium leading-[10px]">
              Dynamics
            </div>
            <Checkbox
              checked={season?.[dynamicFieldName]}
              onChange={(checked) => {
                setForm((prev) => {
                  const seasons = prev?.seasons.map((s: any) => {
                    if (s.id === season.id) {
                      return {
                        ...s,
                        [dynamicFieldName]: checked,
                      };
                    }
                    return s;
                  });
                  return {
                    ...prev,
                    seasons,
                  };
                });
              }}
              id={dynamicFieldName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SeasonAndEvents({
  form,
  setForm,
  activeTab,
  saving,
  handleSave,
  handleCancel,
  onUpdate,
}: {
  form: any;
  setForm: any;
  activeTab: string;
  saving: boolean;
  handleSave: () => void;
  handleCancel: () => void;
  onUpdate: () => void;
}) {
  const user = getUser();
  const [showDeleteSeasonModal, setShowDeleteSeasonModal] = useState({
    isOpen: false,
    season: null,
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [pricesLoading, setPricesLoading] = useState(false);
  const [season, setSeason] = useState<any>(form?.seasons ? form?.seasons[0] : null);
  const [showSeasonModal, setShowSeasonModal] = useState(false);

  // calendar view
  const [date, setDate] = useState<string | null>(null);
  const [prices, setPrices] = useState<any>({});

  const handleCreateSeason = () => {
    setShowSeasonModal(true);

  };
  useEffect(() => {
    console.log("form", form);
  }, [form]);
  useEffect(() => {
    const index = form?.seasons.findIndex((s: any) => s.id === season.id) || 0;
    if (form?.seasons && form?.seasons.length > 0) {
      setSeason(form?.seasons[index]);
    } else {
      setSeason(null);
    }
  }, [form]);

  const fetchPrices = async (date) => {
   //console.log(date, "effect");
    try {
      const userId = user?.id;
      setPricesLoading(true);
      // fetch prices
      const dayMetricsResponse = await api.getDayMetrics(userId, {
        type: "Summary",
        date: moment(date).format("YYYY-MM-DD"),
      });
      setPrices({
        adr: dayMetricsResponse?.adr || 0,
        totalRevenue: dayMetricsResponse?.totalRevenue || 0,
        dynamicPrice: dayMetricsResponse?.baseRoomTypeRate || 0,
      });
      setPricesLoading(false);
    } catch (error) {
      setPricesLoading(false);
     //console.log("error", error);
    }
  };

  const handleSetDate = (date) => {
    setDate(date);
    fetchPrices(date);
  };

  const handleDeleteSeason = async (season) => {
   //console.log("season", season);
    const prevSeasons = form.seasons;
    console.log("prevSeasons", form);
    await setForm((prev) => {
      const index = prev?.seasons.findIndex((s: any) => s.id === season.id);
      const seasons = prev?.seasons.filter((s: any) => s.id !== season.id);
      console.log("Filtered seasons", {...prev, seasons});
      const otherSeason =
        seasons[index - 1] || seasons[index + 1] || seasons[0] || null;
      setSeason(otherSeason);
      return {
        ...prev,
        seasons
      };
    })
    const seasons = form?.seasons.filter((s: any) => s.id !== season.id);
    const newForm = {...form, seasons}
    await api.saveOnboarding(user?.id, newForm);
    await api.updateAllSeasons(user?.id);
    console.log("Deleting season", newForm);
    for(const season of prevSeasons){
      await api.syncRatesGrouped(user?.id, {
        startDate: moment(season.startDate).subtract(1, "day").format("YYYY-MM-DD"),
        endDate: moment(season.endDate).add(1, "day").format("YYYY-MM-DD"),
      });
    }
    setShowDeleteSeasonModal({ isOpen: false, season: null });
  
  
    
    
  };
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload) return null;
    const data = payload?.[0]?.payload;
    return (
      <div className="!bg-[#F5F4EE] rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#F0F0F0]">
        <div className="text-xs text-muted-foreground mb-3 text-center bg-[#F4F4F4] p-2 bg-[#F5F4EE] flex flex-row w-[100%] items-center justify-center">
          {moment(data?.date).format("MMM DD, YYYY")}
        </div>
        <div className="space-y-2 p-4">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-black rounded-full" />
            <span className="text-xs">Price</span>
            <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
              {commaSeparatedNumber(data?.price, false, false)}
            </div>
          </div>
          {data?.adr && (
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-black rounded-full" />
              <span className="text-xs">ADR</span>
              <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
                {commaSeparatedNumber(data?.adr, false, false)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
   //console.log(date, season?.startDate, "effect");
    if (season && season?.startDate) {
      setDate(season.startDate);
      fetchPrices(season.startDate);
    }
  }, [season?.startDate]);

 //console.log("form", form);

  const seasons = (form?.seasons || []).map((s: any) => {
    if (season?.id === s.id) {
      return season;
    }
    return s;
  });
  const handleCalendar = () => {
    setShowCalendar(true);
  };
  const validation = validateEventsForm({ ...form, seasons });
 //console.log(form?.seasons, "ss");
  return (
    <>
      {/** header */}
      <div className="w-[calc(100vw-3rem)] lg:w-[unset] flex-col lg:flex-row justify-between items-start flex mb-5 relative">
        <div className="flex gap-1 justify-between w-full">
          <div className="text-black text-xl lg:text-2xl font-semibold font-['Inter']">
            {pageTitles[activeTab]}
          </div>

          <div>
            <button
              type="button"
              onClick={handleCalendar}
              className="text-primary text-xs font-semibold lg:hidden"
            >
              Check by date
            </button>
          </div>
          {/* <p className="hidden lg:block text-neutral-700 text-sm leading-[22px]">
                  Your annual budget or estimations for the respective year
                </p> */}
        </div>

        <div className={`flex gap-3 w-full lg:w-fit mt-[15px] lg:mt-0`}>
          <Button
            disabled={saving}
            variant="outline"
            className="flex-1 w-full lg:w-fit"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={!validation?.isValid}
            loading={saving}
            className="flex-1 w-full lg:w-fit"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
        {showCalendar && (
          <div className="absolute top-6 left-0 z-50 lg:hidden">
            <CalendarView
              season={season}
              prices={prices}
              setPrices={setPrices}
              date={date}
              setDate={handleSetDate}
              pricesLoading={pricesLoading}
              onClose={() => setShowCalendar(false)}
            />
          </div>
        )}
      </div>
      {/** header end */}
      <div className="w-[calc(100vw-3rem)] lg:w-[unset] flex items-start gap-4">
        <div className="flex-1">
        <Chart seasons={form?.seasons || []} selectedSeason={season} tooltip={
            <Tooltip 
              content={<CustomTooltip />}
              wrapperStyle={{ zIndex: 1000 }}
              allowEscapeViewBox={{ x: true, y: true }}
            />
          } />
          <div className="mt-4">
            <div className="mb-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <SeasonSelect
                  season={season}
                  onSeasonChange={(seasonId) => {
                    console.log("What season am i looking at SeasonSelect", seasonId, form);
                    setSeason(
                      form?.seasons.find((s: any) => s.id === seasonId)
                    );
                    console.log("What season am i looking at SeasonSelect POST", seasonId, form);
                  }}
                  list={
                    (form?.seasons || []).map((season: any, index) => ({
                      label: season?.name,
                      value: season?.id || index + 1,
                    })) || []
                  }
                />
                <button
                  onClick={() =>
                    setShowDeleteSeasonModal({ isOpen: true, season })
                  }
                >
                  <DeleteIcon />
                </button>
              </div>
              <button
                className="text-primary text-xs font-medium"
                onClick={handleCreateSeason}
              >
                Create new
              </button>
            </div>
            <table className="w-full relative hidden lg:table" cellSpacing={12}>
              <colgroup>
                <col style={{ width: "40%" }} />
                <col style={{ width: "30%" }} />
                <col style={{ width: "30%" }} />
                
              </colgroup>
              <thead className="w-full">
                <tr className="text-neutral-800 bg-neutral-300 [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 ">
                  <td></td>
                  <td className="text-center !text-[#000]">Weekday</td>
                  <td className="text-center !text-[#000]">Weekend</td>
                  {/* <td className="text-center !text-[#000] whitespace-nowrap">Dynamic</td> */}
                </tr>
              </thead>
              <tbody>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-2  [&_td]:border-b [&_td]:border-b-neutral-300">
                  <td className="tracking-[-0.44px] pl-4">Rate Floor</td>
                  <td className="tracking-[-0.44px] text-right">
                    <div className="flex justify-center w-full">
                      <PriceInput
                        onChange={(value) => {
                          setForm((prev) => {
                            const seasons = prev?.seasons.map((s: any) => {
                              if (s.id === season.id) {
                                return {
                                  ...s,
                                  weekdayPriceFloor: value,
                                };
                              }
                              return s;
                            });
                            return {
                              ...prev,
                              seasons,
                            };
                          });
                        }}
                        value={season?.weekdayPriceFloor}
                        className="w-[66px] !h-6 !text-black"
                        readOnly={season?.isPriceFloorDynamic}
                        disabled={season?.isPriceFloorDynamic}
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="flex justify-center w-full">
                      <PriceInput
                        onChange={(value) => {
                          console.log("What season am i looking at", value, season, form);
                          setForm((prev) => {
                            const seasons = prev?.seasons.map((s: any) => {
                              if (s.id === season.id) {
                                return {
                                  ...s,
                                  weekendPriceFloor: value,
                                };
                              }
                              return s;
                            });
                            return {
                              ...prev,
                              seasons,
                            };
                          });
                          
                          console.log("What season am i looking at POST", value, season, form);
                        }}
                        value={season?.weekendPriceFloor}
                        className="w-[66px] !h-6 !text-black"
                        readOnly={season?.isPriceFloorDynamic}
                        disabled={season?.isPriceFloorDynamic}
                      />
                    </div>
                  </td>
                  {/* <td className="tracking-[-0.44px]">
                    <div className="flex justify-center w-full">
                      <Checkbox
                        checked={season?.isPriceFloorDynamic}
                        onChange={(checked) => {
                          setForm((prev) => {
                            const seasons = prev?.seasons.map((s: any) => {
                              if (s.id === season.id) {
                                return {
                                  ...s,
                                  isPriceFloorDynamic: checked,
                                };
                              }
                              return s;
                            });
                            return {
                              ...prev,
                              seasons,
                            };
                          });
                        }}
                        id="isPriceFloorDynamic"
                      />
                    </div>
                  </td> */}
                </tr>
                <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-2  [&_td]:border-b [&_td]:border-b-neutral-300">
                  <td className="tracking-[-0.44px] pl-4">Rate Ceiling</td>
                  <td className="tracking-[-0.44px] text-right">
                    <div className="flex justify-center w-full">
                      <PriceInput
                        onChange={(value) => {
                          setForm((prev) => {
                            const seasons = prev?.seasons.map((s: any) => {
                              if (s.id === season.id) {
                                return {
                                  ...s,
                                  priceCeiling: value,
                                };
                              }
                              return s;
                            });
                            return {
                              ...prev,
                              seasons,
                            };
                          });
                        }}
                        value={season?.priceCeiling}
                        className="w-[66px] !h-6 !text-black"
                        readOnly={season?.isPriceCeilingDynamic}
                        disabled={season?.isPriceCeilingDynamic}
                      />
                    </div>
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="flex justify-center w-full">
                      <PriceInput
                        onChange={(value) => {
                          setForm((prev) => {
                            const seasons = prev?.seasons.map((s: any) => {
                              if (s.id === season.id) {
                                return {
                                  ...s,
                                  weekendPriceCeiling: value,
                                };
                              }
                              return s;
                            });
                            return {
                              ...prev,
                              seasons,
                            };
                          });
                        }}
                        value={season?.rateCeiling}
                        className="w-[66px] !h-6 !text-black"
                        placeholder="-"
                        readOnly={season?.isPriceCeilingDynamic}
                        disabled={true}
                      />
                    </div>
                  </td>
                  {/* <td className="tracking-[-0.44px]">
                    <div className="flex justify-center w-full">
                      <Checkbox
                        checked={season?.isPriceCeilingDynamic}
                        onChange={(checked) => {
                          setForm((prev) => {
                            const seasons = prev?.seasons.map((s: any) => {
                              if (s.id === season.id) {
                                return {
                                  ...s,
                                  isPriceCeilingDynamic: checked,
                                };
                              }
                              return s;
                            });
                            return {
                              ...prev,
                              seasons,
                            };
                          });
                        }}
                        id="isPriceCeilingDynamic"
                      />
                    </div>
                  </td> */}
                </tr>
              </tbody>
            </table>
            <div className="flex lg:hidden mb-3 flex-col gap-3">
              <MobileSeason
                season={season}
                setForm={setForm}
                weekdayFieldName="weekdayPriceFloor"
                weekendFieldName="weekendPriceFloor"
                dynamicFieldName="isPriceFloorDynamic"
              />
              <MobileSeason
                season={season}
                setForm={setForm}
                weekdayFieldName="priceCeiling"
                weekendFieldName="weekendPriceCeiling"
                dynamicFieldName="isPriceCeilingDynamic"
              />
            </div>
          </div>
        </div>
        <div className="min-w-[314px] w-[314px] hidden md:block">
          <CalendarView
            season={season}
            prices={prices}
            setPrices={setPrices}
            date={date}
            setDate={handleSetDate}
            pricesLoading={pricesLoading}
          />
        </div>
        {showSeasonModal && (
          <SeasonFormModal
            isOpen={showSeasonModal}
            onClose={() => setShowSeasonModal(false)}
            form={form}
            onUpdate={(updatedForm: any) => {
              setForm(updatedForm);
              setShowSeasonModal(false);
              onUpdate();
            }}
          />
        )}
      </div>
      {showDeleteSeasonModal.isOpen && (
        <ConfirmDeleteSeason
          onClose={() =>
            setShowDeleteSeasonModal({ isOpen: false, season: null })
          }
          onConfirm={async () => await handleDeleteSeason(showDeleteSeasonModal.season)}
          season={showDeleteSeasonModal.season}
        />
      )}
    </>
  );
}
