const TotalRating = 10;
export function Rating({
  labels = ["Worse", "Similar", "Better"],
  value = 0,
  onChange,
  className,
}: {
  labels: string[];
  value: number;
  onChange: (value: number) => void;
  className?: string;
}) {
  return (
    <div className={`flex flex-col gap-1.5 ${className}`}>
      <div className="flex items-center justify-between">
        {labels.map((label) => (
          <span className="text-2xs leading-[11px] text-neutral-700">
            {label}
          </span>
        ))}
      </div>
      <div className="flex items-center gap-[1px]">
        {Array.from({ length: TotalRating }, (_, i) => (
          <button
            className={`flex-1 hover:bg-neutral-500/70 text-2xs font-medium leading-[11px] py-1.5 px-2.5 ${
              value === i + 1
                ? "!bg-primary text-white "
                : "bg-neutral-400 text-neutral-900"
            } ${i === 0 ? "rounded-l-[3px]" : ""} ${
              i === TotalRating - 1 ? "rounded-r-[3px]" : ""
            }`}
            onClick={() => onChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}
