import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat-icon.svg";
import { ReactComponent as ResizeIcon } from "../../assets/icons/resize.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as AutumnLogo } from "../../assets/icons/autumn-logo.svg";
import { Logo } from "../../components/Logo";
import { useApp } from "../../helpers/app-context";
import CustomInput from "../../components/Input";
import { useEffect, useRef, useState } from "react";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import Spinner from "../../components/Spinners";
import useResponsive from "../../helpers/useResponsive";

function UserMessage({
  message,
  expanded,
}: {
  message: string;
  expanded: boolean;
}) {
  return (
    <div
      className={`pl-6 flex-col justify-start items-start gap-2.5 flex self-end ${
        expanded ? "max-w-[80%]" : "max-w-[80%]"
      }`}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: message,
        }}
        className="text-white text-[12px] leading-[1.5] px-2 py-2 bg-[#224C54] rounded-[16px] rounded-tr-none"
      />
    </div>
  );
}

function ChatbotMessage({
  message,
  expanded,
}: {
  message: string;
  expanded: boolean;
}) {
  return (
    <div
      className={`self-stretch pr-2 justify-start items-start gap-2.5 flex ${
        expanded ? "max-w-[80%]" : "max-w-[80%]"
      }`}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: message,
        }}
        className="custom-message [&_*]:!text-[11px] [&_ul]:font-normal [&_ul]:normal-case [&_ul]:text-black/60 [&_ul]:!list-disc [&_ul]:p-[revert] [&_ul]:m-[revert] w-fit text-[#1C1C1C] px-2 py-2 bg-[#F9FAFB] rounded-[16px] rounded-tl-none"
      />
    </div>
  );
}

export function Chatbot() {
  const { isMobile } = useResponsive();
  const { setIsChatBotOpen } = useApp() as any;
  const [text, setText] = useState("");
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSending(true);
      setMessages((prev) => [...prev, { message: text, isUserMessage: true }]);
      setText("");
      const response = await api.postMessage({ message: text });
      if (response?.data?.message) {
        setMessages((prev) => [
          ...prev,
          { message: response?.data?.message, isUserMessage: false },
        ]);
      }
      setIsSending(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const fetchMessages = async () => {
    // fetch message and push at start of messages array
    setIsLoading(true);
    const response = await api.getMessages(user?.id);
    setMessages((prev) => [...response, ...prev]);
    setIsLoading(false);
    setIsFetched(true);
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    setIsFetched(false);
  }, []);

  return (
    <>
      <div
        className={`lg:w-[350px] h-[calc(100dvh-35px)] bg-white rounded-[12px] mt-6 mr-5 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] border border-[#E5E7EB] flex-col justify-start items-start hidden lg:flex ${
          expanded ? "!fixed !w-full !bottom-0 z-[2]" : ""
        }`}
      >
        <div className="self-stretch h-[60px] bg-white shadow rounded-t-[12px] px-6 py-4 justify-between items-center flex">
          <div className="items-center gap-3 flex">
            <LogoIcon />
          </div>
          <div className="justify-start items-center gap-2 flex">
            <button type="button" onClick={() => setExpanded(!expanded)} className="p-2 hover:bg-gray-50 rounded-full transition-colors">
              <ResizeIcon className="w-5 h-5" />
            </button>
            <button type="button" onClick={() => setIsChatBotOpen(false)} className="p-2 hover:bg-gray-50 rounded-full transition-colors">
              <CloseIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
        <div className="self-stretch px-6 py-5 bg-white border-b border-[#E5E7EB]">
          <span className="text-[#1C1C1C] text-[20px] font-semibold tracking-[-0.02em]">
            Hello {user?.firstName},
          </span>
          <p className="text-[#6B7280] text-[16px] font-normal">
            How can I help you today?
          </p>
        </div>
        {!isLoading && !isFetched && (
          <div className="flex justify-center items-center w-full">
            <button
              className="text-primary text-2xs font-medium py-2"
              type="button"
              onClick={fetchMessages}
            >
              Show previous messages
            </button>
          </div>
        )}
        <div
          ref={messageContainerRef}
          className="h-[calc(100dvh-285px)] w-full overflow-y-auto px-3 pt-3.5 pb-[5px] flex-col justify-start items-start gap-3 flex"
        >
          {isLoading && (
            <div className=" w-full flex justify-center items-center">
              <Spinner className="!text-primary" />
            </div>
          )}
          {messages?.map((message, i) =>
            message.isUserMessage ? (
              <UserMessage
                key={i}
                message={message.message}
                expanded={expanded}
              />
            ) : (
              <ChatbotMessage
                key={i}
                message={message.message}
                expanded={expanded}
              />
            )
          )}
          {isSending && (
            <div className="flex items-center gap-2">
              <div className="text-[#646466] text-[11px] font-medium leading-none">
                Typing
              </div>
              <div className="chatbot-loader ml-3 mt-[3px]" />
            </div>
          )}
        </div>
        <div className="self-stretch relative p-4 border-t">
          <div className="flex items-center gap-2 px-2 py-1 rounded-[4px] border border-[#E7E5E4] hover:border-[#2A4D4C] transition-colors">
            <CustomInput
              label=""
              placeholder="Ask me anything..."
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
              className="w-full"
              maxLength={undefined}
              inputClassName="!border-0 !ring-0 !outline-none text-[14px] text-[#6B7280] placeholder:text-[#6B7280]"
            />
            <button
              type="button"
              onClick={handleSubmit}
              className="p-2"
            >
              <SendIcon className="w-[18px] h-[18px] text-[#1C1C1C]" />
            </button>
          </div>
        </div>
        {/* <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
            <div className="self-stretch p-3 bg-white rounded-lg border border-[#b5b6bd] justify-between items-center inline-flex">
              <div className="text-[#8b8b91] text-sm font-normal font-['Inter'] leading-tight">
                Ask me anything...
              </div>
              <div className="w-4 h-4 relative" />
            </div>
        </div> */}
      </div>
      <div className="lg:hidden z-[999] fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
        <div className="h-[calc(100dvh-35px)] w-full bg-white rounded-t-[6px] mt-12 shadow border border-[#E7E5E4] flex-col justify-start items-start flex">
          <div className="self-stretch h-[46px] bg-white shadow px-2.5 py-[9px] rounded-md justify-between items-center flex">
            <div className="items-center gap-[7.60px] flex">
              <ChatIcon className="w-3.5 h-4" />
              <AutumnLogo />
            </div>
            <div className="justify-start items-center gap-2.5 flex">
              {/* <DotsIcon className="w-4 h-4" />
                <ResizeIcon className="w-4 h-4" /> */}
              <button type="button" onClick={() => setIsChatBotOpen(false)}>
                <CloseIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
          <div className="self-stretch pl-4 pr-[11px] py-4 bg-[#F2F7F8] border-b border-[#f4f4f6] justify-start items-center gap-3 inline-flex">
            <div className="w-[237px]">
              <span className="text-[#2F3D37] text-sm font-semibold font-['Inter'] leading-none">
                Hello {user?.firstName},
                <br />
              </span>
              <span className="text-[#646466] text-sm font-medium font-['Inter'] leading-none">
                How can I help you today?
              </span>
            </div>
          </div>
          <div className="h-[calc(100dvh-285px)] w-full overflow-y-auto px-3 pt-3.5 pb-[5px] flex-col justify-start items-start gap-3 flex">
            {messages?.map((message, i) =>
              message.isUserMessage ? (
                <UserMessage
                  key={i}
                  message={message.message}
                  expanded={false}
                />
              ) : (
                <ChatbotMessage
                  key={i}
                  message={message.message}
                  expanded={false}
                />
              )
            )}
            {isLoading && (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            )}
            {isSending && (
              <div className="flex items-center gap-2">
                <div className="text-[#646466] text-[11px] font-medium leading-none">
                  Typing
                </div>
                <div className="chatbot-loader ml-3 mt-[3px]" />
              </div>
            )}
          </div>
          <div className="self-stretch relative h-[60px] pb-4 p-2 flex-col justify-start items-start gap-2.5 flex">
            <CustomInput
              placeholder="Ask me anything..."
              label=""
              onChange={(e) => {
                setText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              value={text}
              className="w-full"
              maxLength={undefined}
              inputClassName="pr-8 !border-[#B6B6BD]"
            />
            <button
              type="button"
              onClick={handleSubmit}
              className="absolute right-[9px] top-3 py-2 px-2"
            >
              <SendIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}