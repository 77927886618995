import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { ActionButtons } from "./ActionButtons";
import { useEffect, useState } from "react";
import { Select } from "../../../components/MultiSelect";
import { ChipInput } from "../../../components/ChipInput";
import { PriceInput } from "../../../components/PriceInput";
import { validateGeneralquestionForm } from "../useValidation";
import { useOnboardingSave } from "../useOnboardingSave";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";
import { Tooltip } from "../../../components/tooltip";

const otaList = ["Booking.com", "Expedia", "HotelTonight", "Airbnb", "Others"];

const filter = (otas, list) => {
  // Remove all the items that are present in the list with case-insensitive but keep the original case
  return otas?.filter(
    (ota) => !list.find((item) => item.toLowerCase() === ota.toLowerCase())
  );
};

const filterMainOtas = (otas, list) => {
  return otas?.filter((ota) =>
    list.find((item) => item.toLowerCase() === ota.toLowerCase())
  );
};
export function GeneralSettingsForm({
  setForm,
  form,
  isReview = false,
  roomTypes,
}) {
  const handleMainOtaChange = (otas) => {
    const values = otas.map((ota) => ota.value);
  
    // if others is not present then remove custom ota list
    if (!values.includes("Others")) {
      setForm((prev) => ({ ...prev, otas: values }));
    } else {
      // merge custom ota list with main ota list
      setForm((prev) => ({
        ...prev,
        otas: [...values, ...filter(form.otas || [], otaList)],
      }));
    }
  };
  const handleCustomChip = (value) => {
    // Filter duplicates with case-insensitive but keep the original case
    const filteredValue = value.filter(
      (v, i, a) => a.findIndex((t) => t.toLowerCase() === v.toLowerCase()) === i
    );
    // now filter if user added ota list item in custom chip with case-insensitive but keep the original case
    const customOtas = filter(filteredValue, otaList);

    const mainOtas = form.otas?.filter((ota) =>
      otaList.find((item) => item.toLowerCase() === ota.toLowerCase())
    );
    setForm((prev) => ({ ...prev, otas: [...mainOtas, ...customOtas] }));
  };
  const prices = form?.roomTypes?.prices || {};
  return (
    <>
      {!isReview && (
        <div className="text-xl lg:text-2xl font-semibold">
          General questions & settings
        </div>
      )}
      <div className={"flex flex-col gap-1.5"}>
        <label className="label text-[#161616] text-xs justify-between flex w-full">
          Which OTAs do you currently use?
        </label>
        <Select
          isMulti={true}
          data={otaList.map((item) => ({ value: item, label: item }))}
          value={filterMainOtas(form.otas || [], otaList)?.map((ota) => ({
            value: ota,
            label: ota,
          }))}
          onChange={(otas) => {
            handleMainOtaChange(otas);
          }}
        />
        {form.otas && form.otas.length > 0 && form.otas.includes("Others") && (
          <ChipInput
            value={filter(form.otas, otaList)}
            onChange={(value) => handleCustomChip(value)}
          />
        )}
      </div>
      <div className={"flex flex-col gap-1.5"}>
        <label className="label text-[#161616] text-xs justify-start gap-1 items-center flex w-full">
          Select your “base room” category
          <Tooltip id="base-room-tooltip" className="!w-[300px]">
            This defines the main room category used for pricing calculations in
            the algorithm. You can select price differentials between this and
            other room categories, allowing you to set higher or lower prices
            for different room types.
          </Tooltip>
        </label>
        <Select
          isMulti={false}
          data={roomTypes}
          value={
            form?.roomTypes?.baseRoomType
              ? roomTypes.find(
                  (item) => item.value === form?.roomTypes?.baseRoomType
                )
              : null
          }
          onChange={(type) => {
            setForm((prev) => ({
              ...prev,
              roomTypes: { ...form?.roomTypes, baseRoomType: type.value },
            }));
          }}
        />
      </div>
      {form?.roomTypes?.baseRoomType && (
        <div className={"flex flex-col gap-1.5"}>
          <label className="label text-[#161616] text-xs justify-between flex w-full">
            How much different should each room be relative to your base room?
          </label>
          <div className="border border-neutral-500 rounded p-3 flex flex-col max-h-[200px] overflow-y-auto gap-2.5">
            {roomTypes
              .filter(
                (roomType) => roomType.value !== form?.roomTypes?.baseRoomType
              )
              .map((roomType) => (
                <div className="flex justify-between items-center">
                  <span className="text-[#1D1E1B] text-sm leading-[20px]">
                    {roomType.label}
                  </span>
                  <PriceInput
                    isPercentage
                    placeholder="0%"
                    
                    onChange={(value) => {
                      // is valid number
                      if (!isNaN(Number(value))) {
                        setForm((prev) => ({
                          ...prev,
                          roomTypes: {
                            ...prev.roomTypes,
                            prices: {
                              ...prev.roomTypes.prices,
                              [roomType.value]: Number(value),
                            },
                          },
                        }));
                      }
                    }}
                    showPlus
                    value={`${prices[roomType.value] || ""}`}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export function GeneralSettings({
  onNext,
  step,
  onBack,
  form,
  setForm,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
  form: any;
  setForm: (form: any) => void;
}) {
  const user = getUser();
  const { saving, onSave } = useOnboardingSave({ onNext });
  const [roomTypes, setRoomTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [isMewsValid, setIsMewsValid] = useState(false);
  const [rates, setRates] = useState<any[]>([]);
  const handleNext = async () => {
    const roomTypes = Object.keys(form?.roomTypes?.prices || {});
    onSave({
      ...form,
      roomTypes: {
        ...form.roomTypes,
        roomTypes,
      },
    });
  };

  const { isValid } = validateGeneralquestionForm(form, roomTypes);

  useEffect(() => {
    api
      .getHotelData(user?.id)
      .then((res) => {
        if(form.pms === "MEWS"){
         //console.log("MEWS RES",res);
          // const Rateprices = res?.ratePlans?.map((roomType) => ({
          //   [roomType.pmsId]: Number(10)
          // }))
          ////console.log("RATE PRICES",Rateprices);
          // setRoomTypes(
          //   (res?.ratePlans || []).map((roomType) => ({
          //     label: roomType.name,
          //     value: roomType.pmsId,
          //     prices: Rateprices
              
          //   }))
          // );
          setRoomTypes(
            (res?.roomTypes || []).map((roomType) => ({
              label: roomType.name,
              value: roomType.pmsId,
            }))
          );
          setIsMewsValid(true);
        }
        else{
          setRoomTypes(
            (res?.roomTypes || []).map((roomType) => ({
              label: roomType.name,
              value: roomType.pmsId,
            }))
          );
        }
        
        
      })
      .catch((error) => {
       //console.log(error);
      });
     //console.log("Form General Settings",form);
  }, [user?.id]);

  return (
    <>
      <OnboardingSidebar title="General Settings">
        <p className="text-xs leading-[16.8px] text-black">
          General settings apply to your hotel as a whole, throughout the year.
          You will be able to adjust seasonal settings later.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="General Settings"
        infoView={
          <p className="text-xs leading-[16.8px] text-black">
            General settings apply to your hotel as a whole, throughout the
            year. You will be able to adjust seasonal settings later.
          </p>
        }
      >
        <Card className="w-full g:max-w-[670px] lg:w-[670px] !p-6 lg:!p-8">
          <div className="flex flex-col gap-5 [&_label]:!text-neutral-700">
            <GeneralSettingsForm
              form={form}
              setForm={setForm}
              roomTypes={roomTypes || []}
            />
          </div>

          <ActionButtons
            onBack={onBack}
            onNext={handleNext}
            disabled={!isValid && !isMewsValid}
            loading={saving}
          />
        </Card>
      </OnboardingView>
    </>
  );
}
