export function Card({
  children,
  className = "",
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={onClick}
      role={onClick ? "button" : undefined}
      className={`bg-white rounded-[10px] border-[0.5px] border-neutral-400 p-8 ${className}`}
    >
      {children}
    </div>
  );
}
