const types = {
  red: {
    style:
      "bg-[#DACEC1] text-[#453D34]",
  },
  green: {
    style:
      "bg-[#D8E6E0] text-[#2F3D37]",
  },
  blue: {
    style:
      "bg-[#FFF0C3] text-[#A77837]",
  },
  purple: {
    style:
      "bg-[#EFEEE9] text-[#6C6C6C]",
  },
  grey: {
    style:
      "border border-neutral-400 bg-[#F6F9F8] text-neutral-700",
  },
};
export function ColorChip({ text, type, className = "", isNegative = false }) {
  const style = types[type]?.style || "";
  return (
    <div
      className={`${style} ${className} rounded-[3px] flex items-center justify-center !h-5 px-[5px] text-center text-2xs`}
    >
      {text}
    </div>
  );
}
