import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ConfirmIcon from "../../../assets/icons/check.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { api } from "../../../helpers/api";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { Layout } from "../components/Layout";
import { ShowToast } from "../../../components/toast";
import { AutumnInfo } from "../components/AutumnInfo";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export default function ForgotPassword() {
  const [success, setSuccess] = useState(false);
  const toggleSuccess = () => setSuccess((prev) => !prev);

  return (
    <Layout>
      <div className="flex w-full items-center">
        <div className="hidden md:flex justify-center flex-1">
          <AutumnInfo />
        </div>
        <div className="flex-1">
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            isInitialValid={false}
            onSubmit={(values, actions) => {
              api
                .forgotPassword({
                  ...values,
                  resetPasswordUrl: `${window.location.origin}/reset-password`,
                })
                .then((res) => {
                  actions.setSubmitting(false);
                  toggleSuccess();
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  ShowToast({
                    message: "Invalid email address. Please try again.",
                    type: "error",
                  });
                });
            }}
          >
            {success ? (
              <ForgotPasswordSuccess toggleSuccess={toggleSuccess} />
            ) : (
              <ForgotPasswordFormContent />
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}

function ForgotPasswordSuccess({
  toggleSuccess,
}: {
  toggleSuccess: () => void;
}) {
  const { values }: { values: { email: string } } = useFormikContext();

  const handleResend = () => {
    toast.loading("Sending email...");
    api
      .forgotPassword({
        ...values,
        resetPasswordUrl: `${window.location.origin}/reset-password`,
      })
      .then((res) => {
        toast.dismiss();
        ShowToast({
          message: "Email sent successfully",
          type: "success",
        });
      })
      .catch((err) => {
        toast.dismiss();
        ShowToast({
          message: "Invalid email address. Please try again.",
          type: "error",
        });
      });
  };

  return (
    <div className="flex flex-col items-start h-full justify-start lg:w-[380px] w-[calc(100vw-2rem)] mx-auto">
      <div className="flex flex-col gap-[1.5rem]">
        <img src={ConfirmIcon} alt="confirm" className="w-[80px] h-[80px]" />
        <div className="grid gap-4">
          <h1 className="text-black text-[28px] font-bold tracking-[-1.12px] leading-[100%]">
            Check your email
          </h1>
          <p className="text-sm leading-[24px] text-[#646466]">
            Thank you! If{" "}
            <span className="text-primary">
              {values.email || "amit@micro1.ai"}
            </span>{" "}
            is registered with us, we’ve sent an email with instructions to
            reset your password. If you don’t see it within 5 minutes, please
            check your spam folder,{" "}
            <button className="text-primary" onClick={handleResend}>
              resend
            </button>{" "}
            the email, or{" "}
            <button className="text-primary" onClick={toggleSuccess}>
              try a different email address.
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

function ForgotPasswordFormContent() {
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="lg:w-[350px] w-[calc(100vw-2rem)] grid gap-[1.75rem] mb-5">
          <div className="grid gap-3">
            <h1 className="text-black text-[28px] font-bold tracking-[-1.12px] leading-[100%]">
              Reset your password
            </h1>
            <p className="text-sm text-[#646466]">
              Enter the email address linked to your account, and we'll send you
              a link to reset your password.
            </p>
          </div>
          <div className="grid gap-4">
            <FormField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
            />
          </div>
          <Button
            className="w-full"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            Continue <RightArrow />
          </Button>
        </Form>
        <Link
          to="/login"
          className=" text-primary text-sm font-medium hover:underline text-center"
        >
          Return to sign in
        </Link>

        <div className="relative border-b border-[#E7E5E4] w-full my-7"></div>
        <div className="text-center text-sm">
          <span>Don’t have an account?</span>{" "}
          <Link
            to="/register"
            className=" text-primary hover:underline font-medium"
          >
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
}
