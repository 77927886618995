import { ReactComponent as LogoIcon } from "../../../assets/icons/logo.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";

export function Stepper({
  currentStep,
  totalSteps,
}: {
  currentStep: number;
  totalSteps: number;
}) {
  return (
    <div className="flex items-center gap-2">
      <div className="w-[75px] h-[5px] rounded-full bg-neutral-400">
        <div
          className="bg-[#DACEC1]"
          style={{
            width: `${(currentStep / totalSteps) * 100}%`,
            height: "100%",
            borderRadius: "inherit",
          }}
        ></div>
      </div>
      <span className="text-2xs font-semibold tracking-[-0.22px] text-black">
        {currentStep} of {totalSteps}
      </span>
    </div>
  );
}

export function OnboardingSidebar({
  title,
  currentStep = 0,
  totalSteps,
  children,
}: {
  title: string;
  currentStep?: number;
  totalSteps?: number;
  children: React.ReactNode;
}) {
  const user = getUser();
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
    // api.deleteOnboarding(user.id).then(() => {
    // });
  };
  return (
    <div className="hidden bg-[#F0EEE8] w-[210px] p-6 lg:flex flex-col justify-between border-r border-r-[#E5E7EB] h-full">
      <div className="flex flex-col items-center">
        <LogoIcon />
        <div className="mt-20 text-base font-semibold self-start">{title}</div>
        <div className="mt-3">{children}</div>
        {totalSteps && (
          <div className="mt-6">
            <Stepper currentStep={currentStep + 1} totalSteps={totalSteps} />
          </div>
        )}
      </div>
      <div>
        <button
          onClick={handleLogout}
          className="flex gap-1.5 items-center group"
        >
          <Logout className="group-hover:[&_path]:stroke-black" />
          <span className="text-sm tracking-[-0.56px] text-neutral-600 group-hover:text-black">
            Logout
          </span>
        </button>
      </div>
    </div>
  );
}
