import { useCallback, useState } from "react";
import { api } from "../api";
import { getUser } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { ShowToast } from "../../components/toast";

export type PlanType = "ST" | "MX" | "PR" | "";

const useStripeSession = () => {
  const [stripeLoading, setStripeLoading] = useState(false);
  const user = getUser();

  const {
    data: activeSubscription,
    isLoading,
    refetch,
  } = useQuery(["subscription"], () => api.getUserSubscription(user.id));

  const handleGetStripeSession = useCallback(
    async ({ type }: { type: PlanType }) => {
      if (type === "") return;
      setStripeLoading(true);
     //console.log(activeSubscription?.[0]);
      if (activeSubscription?.[0]) {
        // api
        //   .patchStripe(user.id, type, activeSubscription[0].subscriptionId)
        //   .then(() => {
        //     toast({
        //       description: "Subscription upgraded successfully",
        //     });
        //     refetch();
        //     setStripeLoading(false);
        //   })
        //   .catch(() => {
        //     toast({
        //       description: "Failed to upgrade subscription. Please try again.",
        //       variant: "destructive",
        //     });
        //     refetch();
        //     setStripeLoading(false);
        //   });
        api.postLog({
          s: "Customer Stripe Portal",
          s2: "Handle get Stripe Session",
          s3: activeSubscription?.[0],
        });
        api
          .customerStripePortal(user.id)
          .then((res) => {
            window.location.href = res.url;
            setStripeLoading(false);
            refetch();
          })
          .catch(() => {
            ShowToast({
              message: "Failed to upgrade subscription. Please try again.",
              type: "error",
            });
            setStripeLoading(false);
            refetch();
          });
      } else {
        try {
          api.postLog({
            s: "Get Stripe",
            s2: "Handle get Stripe Session",
          });
          const session = await api.getStripe(user.id, type);
          
          window.location.href = session.url;
          api.clickStripe(user.id);
          setStripeLoading(false);
          refetch();
        } catch {
          ShowToast({
            message: "Failed to create Stripe session. Please try again.",
            type: "error",
          });
          setStripeLoading(false);
          refetch();
        }
      }
      refetch();
    },
    [activeSubscription, user.id, refetch]
  );

  const nextPackage = useCallback((): PlanType => {
    if (activeSubscription?.[0]?.tier === "ST") {
      return "PR";
    } else if (activeSubscription?.[0]?.tier === "PR") {
      return "MX";
    } else if (activeSubscription?.[0]?.tier === "MX") {
      return "";
    }
    return "ST";
  }, [activeSubscription]);

  return {
    handleGetStripeSession,
    stripeLoading,
    nextPackage,
    activeSubscription,
    isLoading,
    refetch,
  };
};

export default useStripeSession;
