import moment from "moment";
import { Panel } from "../../../components/Panel";
import { YearFilter } from "../../dashboard/components/YearFilter";
import { MonthSelect } from "../../dashboard/components/MonthSelect";
import { useEffect, useState, useCallback } from "react";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import Spinner from "../../../components/Spinners";

interface AdwordData {
  keyword: string;
  impressions: number;
  clicks: number;
  ctr: string;
}

const adwordsData: AdwordData[] = [
  // { keyword: "Adword 1", impressions: 1000, clicks: 50, ctr: "5.0%" },
  // { keyword: "Adword 2", impressions: 900, clicks: 40, ctr: "4.9%" },
  // { keyword: "Adword 3", impressions: 1000, clicks: 50, ctr: "5.0%" },
  // { keyword: "Adword 4", impressions: 900, clicks: 40, ctr: "4.9%" },
];

const getDates = (month?: string | number, year?: string | number) => {
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    month: `${currentMonth + 1}`,
    year: `${currentYear}`,
  };
};

export function AdwordsPerformance() {
  const [data, setData] = useState<AdwordData[]>(adwordsData);
  const [isLoading, setIsLoading] = useState(false);
  const user = getUser();
  const [filter, setFilter] = useState<any>({
    ...getDates(),
    type: "adwords",
  });

  const handleYearChange = (year: string) => {
    setFilter((prev: any) => ({ ...prev, ...getDates(undefined, year) }));
  };

  const handleMonthChange = (month: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(month, prev.year) }));
  };

  const fetchAdwordsData = useCallback(async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const response = await api.getAnalytics(userId, {
        startDate: filter.startDate,
        endDate: filter.endDate,
        analyticsType: "GoogleAdWordsPerformance"
      });
      setData(response?.length ? response : adwordsData);
    } catch (error) {
     //console.log(error);
      setData(adwordsData);
    } finally {
      setIsLoading(false);
    }
  }, [filter, user?.id]);

  useEffect(() => {
    fetchAdwordsData();
  }, [fetchAdwordsData]);

  return (
    <Panel
      label="Google Adwords Performance"
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={filter.month}
            onChange={handleMonthChange}
          />
          <YearFilter
            disabled={false}
            value={filter.year}
            onChange={handleYearChange}
          />
        </div>
      }
      className="h-full"
      contentClassName="flex flex-col relative h-[calc(100%-40px)]"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="p-4 flex-col justify-between h-full">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b border-[#EAEAEA]">
                <th className="text-left py-3 text-[12px] font-medium text-[#4A4A4A]">Keyword</th>
                <th className="text-right py-3 text-[12px] font-medium text-[#4A4A4A]">Impressions</th>
                <th className="text-right py-3 text-[12px] font-medium text-[#4A4A4A]">Clicks</th>
                <th className="text-right py-3 text-[12px] font-medium text-[#4A4A4A]">CTR</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan={4} className="py-4 text-center text-[12px] text-[#4A4A4A]">No data available</td>
                </tr>
              ) : data.map((row, index) => (
                <tr
                  key={row.keyword}
                  className={index !== data.length - 1 ? 'border-b border-[#EAEAEA]' : ''}
                >
                  <td className="py-4">
                    <span className="text-[12px] text-[#4A4A4A]">{row.keyword}</span>
                  </td>
                  <td className="py-4 text-right text-[12px] text-[#4A4A4A]">
                    {row.impressions.toLocaleString()}
                  </td>
                  <td className="py-4 text-right text-[12px] text-[#4A4A4A]">{row.clicks}</td>
                  <td className="py-4 text-right text-[12px] text-[#4A4A4A]">{row.ctr}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Panel>
  );
}
