export const setToken = (token: string) => {
  // set to local storage
  localStorage.setItem("token", token);
};

export const setUser = (user: any) => {
  // set to local storage
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  // get from local storage
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};
export const clearUser = () => {
  localStorage.removeItem('user');
};
export const getConsent = () => {
  // get from local storage
  const consent = localStorage.getItem("consent");
  return consent ? JSON.parse(consent) : null;
};

export const setConsent = (consent: any) => {
  // set to local storage
  localStorage.setItem("consent", JSON.stringify(consent));
};

export function checkPasswordStrength(password: any) {
  const requirements = [
    { regex: /[a-z]/, message: "lowercase letter" },
    { regex: /[A-Z]/, message: "uppercase letter" },
    { regex: /\d/, message: "digit" },
    { regex: /[@$!%*?&]/, message: "special character" },
    { regex: /.{8,}/, message: "at least 8 characters" },
  ];
  let score = 0;
  let message = "";
  requirements.forEach((requirement) => {
    if (requirement.regex.test(password)) {
      score++;
    } else {
      message += ` ${requirement.message},`;
    }
  });
  if (score === requirements.length) {
    message = "Your password is great. Nice work!";
  } else {
    message = `Your password needs ${message.slice(0, -1)}`;
  }
  return { score: (score / requirements.length) * 100, message };
}

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const clearTokenAndUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const validatePhone = (phone: string): boolean => {
  return !!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
};

export default function passwordValidation(password: string) {
  //   add special character
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return passwordRegex.test(password);
}

export function formatNumber(num: number, sign = "") {
  const getSign = (n: any, sign: string) => {
    if (sign === "%") {
      return `${n}%`;
    }
    if (sign === "$") {
      return `$${n}`;
    }
    return n;
  };
  if (num === 0 || !num) {
    return "-";
  }
  const absNum = Math.abs(num);

  // add decimal places only if it is a float number
  let formattedNum = "";
  if (absNum >= 1e9) {
    formattedNum = (num / 1e9).toFixed(0).replace(/\.0$/, "") + "B";
  } else if (absNum >= 1e6) {
    formattedNum = (num / 1e6).toFixed(0).replace(/\.0$/, "") + "M";
  } else if (absNum >= 1e3) {
    formattedNum = (num / 1e3).toFixed(0).replace(/\.0$/, "") + "k";
  } else {
    formattedNum = num.toFixed(0).toString().replace(/\.00$/, "");
  }
  if (num < 0) {
    formattedNum = `(${getSign(formattedNum, sign)})`;
  }
  return getSign(formattedNum, sign);
}

export function commaSeparatedNumber(
  num: number,
  isPercentage = false,
  isDollar = true,
  maxFractionDigits = 0,
  minFractionDigits = 0
) {

  const newNum = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  })
    .format(num)
    .toString()
    .replace(/\.00$/, "");

  if (num === 0 || Number(newNum) === 0) {
    return "-";
  }
  const value = isPercentage ? `${newNum}%` : isDollar ? `$${newNum}` : newNum;
  if (num < 0) {
    return `(${value.replace("-", "")})`;
  }
  return value;
}

export function getInitial(firstName: string, lastName: string) {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  } else if (firstName) {
    return firstName[0];
  } else if (lastName) {
    return lastName[0];
  } else {
    return "";
  }
}

export function convertCentToDollar(cent: number) {
  return cent / 100;
}

export function convertDollarToCent(dollar: number) {
  return dollar * 100;
}

export function calcCrow(lat1, lng1, lat2, lng2) {
  const toRadians = (degrees: number) => degrees * (Math.PI / 180);

  const R = 3958.8; // Radius of the Earth in miles
  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);
  const lat1Rad = toRadians(lat1);
  const lat2Rad = toRadians(lat2);

  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLng / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return (R * c).toFixed(2); // Distance in miles
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export function getNumberWithSign(num: number, isPercentage = false) {
  if (num === 0) {
    return "-";
  }
  if (num > 0) {
    return `+${commaSeparatedNumber(num, isPercentage)}`;
  }
  if (num < 0) {
    return `${commaSeparatedNumber(num, isPercentage)}`;
  }
  return "-";
}

export function getPercentage(num: number) {
  if (num && Number(num) === 0) {
    return "-";
  }
  if (num && Number(num) > 100) {
    return 100;
  }
  return Number(num.toFixed(0));
}

export const getHeightInVh = (height: number) => {
  const modelHeight = 853;
  return `${(height / modelHeight) * 100}vh`;
};

export const getDate = (date: string) => {
  // format: 2024-01-01
  // return: Month-Day-Year
  const [year, month, day] = date.split("-");
  if (!year || !month || !day) return date;
  return `${month}-${day}-${year}`;
};
