import { Panel } from "../../../components/Panel";
import { YearFilter } from "../../dashboard/components/YearFilter";
import { useEffect, useState } from "react";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import Spinner from "../../../components/Spinners";
import { ConnectGoogleAnalytics } from "./ConnectGoogleAnalytics";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { MonthSelect } from "../../dashboard/components/MonthSelect";
import moment from "moment";

type AnalyticsType = {
  type: "WebsiteTrafficByDevice" | "WebsiteTrafficByTime" | "VisitorDemographics" | "TopReferralSources" | "TopTrafficChannels" | "GoogleAdWordsPerformance" | "YTDSummary" | "FeederMarkets" | "PageViews";
}

const analyticsTypeLabels = {
  "WebsiteTrafficByDevice": "Website Traffic by Device",
  "WebsiteTrafficByTime": "Website Traffic by Time of Day",
  "VisitorDemographics": "Website Visitor Demographics",
  "TopReferralSources": "Top Referral Sources",
  "TopTrafficChannels": "Top Traffic Channels",
  "GoogleAdWordsPerformance": "Google AdWords Performance",
  "YTDSummary": "YTD Summary",
  "PageViews": "Page Views",
  "FeederMarkets": "Feeder Markets"
}

interface ReferralSource {
  name: string;
  value: number;
  percentage: number;
  color: string;
}
const colors = ["#E5DED8", "#8B6F6F", "#5C6560", "#9B9B9B"]
const defaultReferralSources: ReferralSource[] = [
  {name: "No Data Available", value: 0, percentage:0, color: "bg-[#E5DED8]"},


];
const CustomTooltip = ({ active, payload, label, data }: any) => {
    const summaryData = payload?.[0]?.payload;
    const totalBookings = data?.reduce(
      (acc, curr) => acc + Number(curr.value || 0),
      0
    );
    const percentage = ((summaryData?.value / totalBookings) * 100).toFixed(0);
    return (
      <div className="z-[9999] p-2.5 bg-white rounded-lg shadow border border-[#f4f4f6] justify-start items-center gap-1.5 inline-flex">
        <div
          className="w-2 h-2 bg-[#2F3D37] rounded-lg"
          style={{
            backgroundColor: summaryData?.color,
          }}
        />
        <div className="text-[#525253] text-xs font-medium font-['Inter']">
          {summaryData?.payload?.name} ({percentage}%)
        </div>
      </div>
    );
  };
const getDates = (month?: string | number, year?: string | number) => {
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    month: `${currentMonth + 1}`,
    year: `${currentYear}`,
  };
};

export function MarketingPieChart({ type   }: AnalyticsType) {
  const [data, setData] = useState<ReferralSource[]>(defaultReferralSources);
  const user = getUser();
  const isGoogleAnalyticsEnabled = !!user?.googleAnalyticsPropertyId;
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({ ...getDates() });

  const handleMonthChange = (month: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(month, prev.year) }));
  };

  const handleYearChange = (year: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(prev.month, year) }));
  };
  const fetchTrafficDeviceData = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      
      if (!userId) {
        console.error('No user ID available');
        setData(defaultReferralSources);
        return;
      }

      const response = await api.getAnalytics(userId, {
        startDate: filter.startDate,
        endDate: filter.endDate,
        analyticsType: type
      });

      console.log('device data API Response:', type, response);
      var colorsIndex = 0;
      console.log("RESPONSE", type, response);
      
      const sum = response.reduce((sum, r) => sum + Number(r.count), 0);
      console.log("SUM", type, sum);
      const transformedData = sum > 0 
        ? response.map(item => {
            const color = colors[colorsIndex];
            colorsIndex = (colorsIndex + 1) % colors.length;
            return {
              name: item.name,
              value: Number(item.count), 
              percentage: Math.round((Number(item.count) / sum) * 100),
              color: color
            };
          })
        : defaultReferralSources;
      var finalData = transformedData;
      if (transformedData.length > 4) {
        // Sort by value in descending order
        transformedData.sort((a, b) => b.value - a.value);
        
        // Take top 4 items
        const topItems = transformedData.slice(0, 4);
        
        // Combine remaining items into "Other"
        const otherItems = transformedData.slice(4);
        const otherValue = otherItems.reduce((sum, item) => sum + item.value, 0);
        const otherPercentage = otherItems.reduce((sum, item) => sum + item.percentage, 0);
        
        finalData = [
          ...topItems,
          {
            name: "Other",
            value: otherValue,
            percentage: otherPercentage,
            color: colors[3] // Use the 5th color for Other
          }
        ];
      }
      console.log("TRANSFORMED DATA", type, finalData);
      setData(finalData);
    } catch (error) {
      console.error('Error fetching', type, 'data:', error);
      setData(defaultReferralSources);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTrafficDeviceData();
  }, [filter]);

  return (
    <Panel
      label={analyticsTypeLabels[type]}
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={filter.month}
            onChange={handleMonthChange}
          />
          <YearFilter
            disabled={false}
            value={filter.year}
            onChange={handleYearChange}
          />
        </div>
      }
      className="h-full"
      contentClassName="flex flex-col relative h-[calc(100%-50px)]"
    >
      <>{isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div
          className="flex flex-col justify-between h-full p-4"
          style={!isGoogleAnalyticsEnabled ? { filter: "blur(16px)" } : {}}
        >
          <div className="h-[40vh]">
          {data[0].name === "No Data Available" ? (
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-gray-500">No Data Available</span>
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={60}
                      paddingAngle={2}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color.replace('bg-[', '').replace(']', '')}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip data={data} />} />
                  </PieChart>
                </ResponsiveContainer>
              )}
          </div>

          <div className="space-y-0">
            {data[0].name !== "No Data Available" && data.map((source, index) => (
              <div
                key={source.name}
                className={`flex items-center justify-between py-[8px] ${
                  index !== data.length - 1 ? "border-b border-[#EAEAEA]" : ""
                }`}
              >
                <div className="flex items-center gap-3">
                  <div
                    className="h-[14px] w-[14px] rounded-[1px]"
                    style={{ backgroundColor: source.color }}
                  />
                  <span className="text-[12px] font-normal text-[#4A4A4A]">
                    {source.name}
                  </span>
                </div>
                <div className="flex items-center gap-1.5">
                  <span className="text-[12px] font-medium text-[#4A4A4A]">
                    {source.value}
                  </span>
                  <span className="text-[12px] text-[#9B9B9B]">
                    ({source.percentage}%)
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
        {!isGoogleAnalyticsEnabled && <ConnectGoogleAnalytics />}
      </>
    </Panel>
  );
}