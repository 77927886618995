import { useState } from "react";
import { api } from "../../../helpers/api";
import { ShowToast } from "../../../components/toast";
import { PopupModal } from "../../../components/Modal";
import Button from "../../../components/Button";
import moment from "moment";

export function ConfirmResetModal({
  onClose,
  userId,
  refetch,
  dates,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  dates: {
    startDate: string;
    endDate?: string;
  };
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleReset = async () => {
    setIsLoading(true);
    await api
      .resetDayPrice(userId, dates);
      
    await api.resetOverride(userId, {
      startDate: dates.startDate,
      endDate: dates.endDate || "",
    });
    await api.syncRatesGrouped(userId, {
      startDate: moment(dates.startDate).format("YYYY-MM-DD"),
      endDate: moment(dates.endDate).format("YYYY-MM-DD"),
    }).then(() => {
      onClose();
      ShowToast({
        message: "Day price reset successfully",
        type: "success",
      });
      refetch && refetch();
      setIsLoading(false);
    })
    .catch(() => {
      ShowToast({
        message: "Failed to reset day price",
        type: "error",
      });
      setIsLoading(false);
    });
    
   
    // .then(() => {
    //   onClose();
    //   ShowToast({
    //     message: "Day price reset successfully",
    //     type: "success",
    //   });
    //   refetch && refetch();
    // });
  };

  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to reset this day's settings to match your
        seasonal settings?
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={handleReset}
          loading={isLoading}
        >
          Reset
        </Button>
      </div>
    </PopupModal>
  );
}
