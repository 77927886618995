import { ReactComponent as CheckMark } from "../../../assets/icons/check-mark.svg";
import useResponsive from "../../../helpers/useResponsive";

const steps = {
  0: "Welcome",
  1: "PMS Connect",
  2: "OTA Rates",
  3: "General Settings",
  4: "Competitor Set",
  5: "Seasons & Events",
  6: "Google analytics",
  7: "Budget",
  8: "Plan",
  9: "Review",
  10: "Go Live!",
};

function ProgressStep({
  isCurrent,
  isCompleted,
  title,
  isFirst,
  isLast,
}: {
  isCurrent: boolean;
  isCompleted: boolean;
  title: string;
  isFirst: boolean;
  isLast: boolean;
}) {
  const { isSmallDevice } = useResponsive();
  const isCurrentOrCompleted = isCurrent || isCompleted;
  return (
    <div className="flex-1 flex flex-col items-center gap-4">
      <div className="hidden lg:flex items-center gap-1">
        {isCompleted && (
          <div className="bg-white w-4 h-4 rounded-full flex items-center justify-center">
            <CheckMark />
          </div>
        )}
        <span
          className={`whitespace-nowrap tracking-[-0.11px] text-2xs font-['EZ-Allianz'] ${
            isCurrentOrCompleted ? "text-black font-bold" : "text-black/50"
          }`}
        >
          {title}
        </span>
      </div>
      <div
        className={`h-2.5 rounded-[1px] ${
          isCurrent ? "w-[80px] lg:w-full" : "w-full"
        } ${isFirst ? "rounded-l-full" : isLast ? "rounded-r-full" : ""} ${
          isCompleted
            ? "bg-primary"
            : isCurrent
            ? "bg-lightPurple"
            : "bg-neutral-400"
        } `}
      />
    </div>
  );
}

export function ProgressBar({ currentStep }: { currentStep: number }) {
  return (
    <div className="flex items-center gap-[2px] flex-wrap">
      {Object.keys(steps).map((step, index) => {
        const isCurrent = index === currentStep;
        const isCompleted = index < currentStep;
        return (
          <ProgressStep
            isFirst={index === 0}
            isLast={index === Object.keys(steps).length - 1}
            key={index}
            isCurrent={isCurrent}
            isCompleted={isCompleted}
            title={steps[step]}
          />
        );
      })}
    </div>
  );
}
