import React, { PureComponent, useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment, { min } from "moment";
import { FilterSelect, types } from "./components/FilterSelect";
import { api } from "../../../../helpers/api";
import {
  commaSeparatedNumber,
  formatNumber,
  getPercentage,
  getUser,
} from "../../../../helpers/utils";
import { transformData, type TransformedDataPoint } from "./utils";
import useResponsive from "../../../../helpers/useResponsive";
import { YearFilter } from "../YearFilter";
import Spinner from "../../../../components/Spinners";
import { fileURLToPath } from "url";

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  if(year === "l9n3"){
    return {
      startDate: moment().startOf("month").subtract(8, "months").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").add(3, "months").format("YYYY-MM-DD"),
      year: `${currentYear}`,
    };
  }
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const CustomBarWithTarget = (props) => {
  const {
    fill,
    x,
    y,
    width,
    height,
    budget,
    current,
    startDate,
    payload,
    name,
    isL9N3,
  } = props;

  // check start date for year and check name for month ["Jan", ... "Dec"]
  // If it's future or current month then use onTheBooks value else use current value for bar
  //const realStartDate = moment().startOf("month").format("YYYY-MM-DD");
  // For L9N3, treat 8 months before current month as past, rest as future
  const listOfPastMonths: string[] = [];
  const listOfFutureMonths: string[] = [];
  //console.log("L3N9 START DATE", startDate, props);
  const currentMonth = moment().format("MMM");
  const currentYear = moment().format("YYYY");
  for (let i = 1; i < 9; i++) {
    const month = moment().startOf("month").subtract(i, "months").format("MMM");
    listOfPastMonths.push(month);
  }
  for (let i = 0; i < 4; i++) {
    const month = moment().startOf("month").add(i, "months").format("MMM");
    listOfFutureMonths.push(month);
  }
  //console.log("L3N9 LIST OF PAST MONTHS", listOfPastMonths);
  //console.log("L3N9 LIST OF FUTURE MONTHS", listOfFutureMonths);
  const month = moment(name, "MMM").isSameOrAfter(moment(currentMonth, "MMM"));
  const year = moment(moment(startDate).format("YYYY"), "YYYY").isSameOrAfter(
    moment(currentYear)
  );
  const isFuture = isL9N3 ? listOfFutureMonths.includes(name) : month && year;
  //console.log("L3N9 PAYLOAD", payload, month, year, isFuture, startDate, currentYear, currentMonth);
  let totalHeight = y + height;
  let targetY = totalHeight - (height / current) * budget;

  let mainHeight = height;
  let mainY = y;
  if (isFuture) {
    const onTheBooks = payload?.onTheBooks;
    // use onTheBooks value
    mainHeight = (height / current) * onTheBooks;
    mainY = totalHeight - mainHeight;
  }
  return (
    <svg>
      <rect
        x={x}
        y={mainY}
        width={width}
        rx={2}
        ry={2}
        height={mainHeight}
        stroke="none"
        fill={isFuture ? "#B6B6BD" : fill}
      />
      <line
        x1={x} // 140
        x2={x + width} // 184
        y1={targetY || y}
        y2={targetY || y}
        stroke={"#E5E7EB"}
        strokeWidth={1.5}
      />
    </svg>
  );
};

const CustomTooltip = ({ active, payload, type, rooms_ooo }: any) => {
  const data = payload[0]?.payload || {};
  const label = rooms_ooo
    ? "Rooms OOO"
    : types.find((t) => t.value === type)?.label;
  return (
    <div className="p-2.5 bg-white rounded-[8px] border border-[#f4f4f6] justify-start items-start gap-5 flex">
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-neutral-400 rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            Last year
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {type === "occ" && !rooms_ooo
              ? `${commaSeparatedNumber(data?.previous || 0, true)}`
              : `${commaSeparatedNumber(data?.previous || 0, false)}`}
          </div>
        </div>
      </div>
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div
            className={`w-3 h-3 ${
              data?.actualCurrent ? "bg-black" : "bg-[#B6B6BD]"
            } rounded-full`}
          />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            {data?.actualCurrent ? "Total" : "On The Books"}
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {type === "occ" && !rooms_ooo
              ? `${commaSeparatedNumber(
                  data?.actualCurrent || data?.onTheBooks || 0,
                  true
                )}`
              : `${commaSeparatedNumber(
                  data?.actualCurrent || data?.onTheBooks || 0,
                  false
                )}`}
          </div>
        </div>
      </div>
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className={`w-3 h-3 bg-primary rounded-full`} />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            Budget
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {type === "occ" && !rooms_ooo
              ? `${commaSeparatedNumber(data?.budget || 0, true)}`
              : `${commaSeparatedNumber(data?.budget || 0, false)}`}
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomCursor = (props) => {
  const { isQHD } = useResponsive();
  const { x, y, width, height, stroke } = props;
  // make width 25px
  const newWidth = isQHD ? 60 : 34;
  // set x in center
  const remaining = (width - newWidth) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

export function RevenueChart() {
  const user = getUser();
  const { isMobile, isTall2XLDesktop, isTall2, isQHD } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
    type: string;
    year: string;
    rooms_ooo?: boolean;
    market_adj?: boolean;
  }>({ ...getDates("l9n3"), type: "revenue", year: "l9n3" });
  const [data, setData] = useState<any>([]);

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "RevenueSummary",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      //console.log("L3N9 METRICS", metrics);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const graph = useMemo(() => {
    let transformedData: TransformedDataPoint[] = [];
    //console.log("FILTER TYPE", filter.type);
    if (filter.type === "revenue") {
      transformedData = transformData({
        data: data || [],
        previousField: filter.market_adj
          ? "revenuePrevYearWithMarketingGrowth"
          : "revenuePrevYear",
        onTheBooksField: filter.market_adj
          ? "onBooksRevenueWithMarketingGrowth"
          : "onBooksRevenue",
        currentField: filter.market_adj
          ? "actualRevenueWithMarketingGrowth"
          : "actualRevenue",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "roomsRevenue",
      });
      console.log("L3N9 TRANSFORMED DATA", transformedData);
    } else if (filter.type === "revpar") {
      transformedData = transformData({
        data: data || [],
        previousField: filter.market_adj
          ? "revparPrevYearWithMarketingGrowth"
          : filter.rooms_ooo
          ? "revparPrevYearWithOOO"
          : "revparPrevYear",
        onTheBooksField: filter.market_adj
          ? "onBooksRevparWithMarketingGrowth"
          : filter.rooms_ooo
          ? "onBooksRevparWithOOO"
          : "onBooksRevpar",
        currentField: filter.market_adj
          ? "actualRevparWithMarketingGrowth"
          : filter.rooms_ooo
          ? "actualRevparWithOOO"
          : "actualRevpar",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "revpar",
      });
    } else if (filter.type === "adr") {
      transformedData = transformData({
        data: data || [],
        previousField: filter.market_adj
          ? "adrPrevYearWithMarketingGrowth"
          : "adrPrevYear",
        onTheBooksField: filter.market_adj
          ? "onBooksAdrWithMarketingGrowth"
          : "onBooksAdr",
        currentField: filter.market_adj
          ? "actualAdrWithMarketingGrowth"
          : "actualAdr",
        budgetField: "adr",
        startDate: filter.startDate,
        endDate: filter.endDate,
      });
    } else if (filter.type === "occ") {
      transformedData = transformData({
        data: data || [],
        previousField: filter.market_adj
          ? "occupancyPrevYearWithMarketingGrowth"
          : filter.rooms_ooo
          ? "occupancyWithOOOPrevYear"
          : "occupancyPrevYear",
        onTheBooksField: filter.market_adj
          ? "onBooksOccupancyWithMarketingGrowth"
          : filter.rooms_ooo
          ? "onBooksOccupancyWithOOO"
          : "onBooksOccupancy",
        currentField: filter.market_adj
          ? "actualOccupancyWithMarketingGrowth"
          : filter.rooms_ooo
          ? "actualOccupancyWithOOO"
          : "actualOccupancy",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "occupancy",
      });
    }
    else if(filter.type === "other_revenue"){
      transformedData = transformData({
        data: data || [],
        previousField: filter.market_adj
        ? "otherRevenuePrevYearWithMarketingGrowth"
        : "otherRevenuePrevYear",
        onTheBooksField: filter.market_adj
        ? "onBooksOtherRevenueWithMarketingGrowth"
        : "onBooksOtherRevenue",
        currentField: filter.market_adj
        ? "actualOtherRevenueWithMarketingGrowth"
        : "actualOtherRevenue",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "otherRevenue",
      });
    }
    else{
      console.log("OTHER REVENUE CHART", filter.type, data);
    }
    return transformedData;
  }, [data, filter]);

  return (
    <Panel
      label="Revenue Summary"
      actions={
        <div className="flex items-center gap-1.5">
          <FilterSelect filter={filter} setFilter={setFilter} />
          <YearFilter
            disabled={false}
            filter={filter}
            setFilter={setFilter}
            isSummary
          />
        </div>
      }
      className="h-[310px] relative tall3:h-[360px] tall3XL:h-[410px] qhd1:h-[600px] max-w-[calc(100vw-28px)] overflow-x-hidden tall3XL:[&_.header]:h-[50px]"
      contentClassName="h-[calc(100%-50px)]"
      showNavigation={isMobile}
    >
      {/* <div className="border-b border-[#E5E7EB] w-[97%] mx-auto"></div> */}
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="h-full w-[700px] md:w-[unset] flex justify-center items-center p-4 pt-6 pb-0 !pl-[2px]">
          <ResponsiveContainer
            width={isMobile ? 700 : undefined}
            // height={
            //   isQHD
            //     ? 500
            //     : isTall2 || isMobile
            //     ? 209
            //     : isTall2XLDesktop
            //     ? 250
            //     : 300
            // }
          >
            <BarChart
              width={500}
              // height={300}
              data={graph}
              margin={{
                top: 5,
                right: 5,
                left: 20,
                bottom: 5,
              }}
              barGap={4}
            >
              <CartesianGrid
                strokeDasharray="0"
                vertical={false}
                horizontal={{
                  color: "red",
                }}
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                className="!text-2xs !text-neutral-600 leading-[11px]"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                width={30}
                className="!text-2xs !text-neutral-600 leading-[11px]"
                domain={[0, filter.type === "occ" ? 100: (dataMax) => {
                  const maxValue = Math.ceil(Math.max(dataMax, Math.max(...graph.map(d => d.previous || 0))));
                  const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)));
                  return Math.ceil(maxValue * 1.1 / magnitude) * magnitude;
                }]}
                tickFormatter={(value) => {
                  // make value to k denotes thousands
                  const val = Number(value);
                  // If zero then show 0 without dollar sign
                  if (val === 0) {
                    return "0";
                  }
                  if (val > 1000) {
                    return filter.type === "occ"
                      ? `${commaSeparatedNumber(Number(val) / 1000, true)}`
                      : `${formatNumber(Number(val || 0), "$")}`;
                  }
                  return filter.type === "occ"
                    ? `${commaSeparatedNumber(Number(val), true)}`
                    : `$${val}`;
                }}
              />
              <Tooltip
                position={{ y: 0 }}
                cursorStyle={{ maxWidth: 25 }}
                content={
                  <CustomTooltip
                    type={filter.type}
                    rooms_ooo={filter.rooms_ooo}
                  />
                }
                cursor={graph?.length > 0 && <CustomCursor />}
              />
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stop-color="#E5E7EB" />
                  <stop offset="100%" stop-color="white" />
                </linearGradient>
              </defs>
              <Bar
                dataKey="onTheBooks"
                visibility={"hidden"}
                fill="#E5E7EB"
                barSize={0.01}
                radius={[0, 0, 0, 0]}
              />
              <Bar
                dataKey="previous"
                fill="#E5E7EB"
                barSize={isQHD ? 20 : 12}
                radius={[0, 0, 0, 0]}
              />
              <Bar
                dataKey="current"
                fill="#000"
                shape={<CustomBarWithTarget startDate={filter.startDate} isL9N3={filter.year === "l9n3"} />}
                isAnimationActive={false}
                barSize={isQHD ? 20 : 12}
                radius={[0, 0, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </Panel>
  );
}