import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../../../../../assets/icons/chevron-down.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../../assets/icons/check-mark.svg";
import { Checkbox } from "../../../../../components/Checkbox";

export const types = [
  { label: "Rooms Revenue", value: "revenue" },
  { label: "RevPAR", value: "revpar" },
  { label: "ADR", value: "adr" },
  { label: "Occ. %", value: "occ" },
  { label: "Other Revenue", value: "other_revenue" },
];

const disabledTypes = ["revenue", "adr"];

export function FilterSelect({ filter, setFilter }) {
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedType = types.find((type) => type.value === filter.type);
  return (
    <div ref={containerRef} className="relative">
      <button
        type="button"
        onClick={() => setShowFilter((p) => !p)}
        className="bg-white hover:bg-white border border-[#E5E7EB] text-[#111827] rounded-[8px] px-3 py-[7px] h-auto font-normal text-sm flex items-center gap-1 hover:border-[#E5E7EB]"
      >
        <div className="text-center flex-1 text-[#000000] text-[12px] font-medium leading-[11px]">
          {selectedType?.label}
        </div>
        <ChevronDownIcon
          className={`w-3 h-3 ${
            showFilter
              ? "rotate-180 pb-0.5 [&_path]:stroke-neutral-600"
              : "pt-0.5 [&_path]:stroke-neutral-600"
          }`}
        />
      </button>
      {showFilter && (
        <div className="absolute z-[99] left-1/2 -translate-x-1/2 mt-1 w-[200px] p-2 bg-white rounded shadow-[0_2px_8px_rgba(0,0,0,0.12)] rounded-lg border-[#E5E7EB] border border-[#E5E7EB] flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch h-[125px] flex-col justify-start items-start flex">
            {types.map((type) => {
              const disabled =
                filter.rooms_ooo && disabledTypes.includes(type.value);
              return (
                <button
                  onClick={() =>
                    filter.type !== type.value &&
                    setFilter({ ...filter, type: type.value })
                  }
                  type="button"
                  disabled={disabled}
                  key={type.value}
                  className={`self-stretch hover:bg-[#E8F1F2] h-[25px] p-2 rounded-sm justify-between items-center flex ${
                    disabled ? "opacity-50" : ""
                  }`}
                >
                  <div className="text-[#0B252A] text-xs font-medium leading-3">
                    {type.label}
                  </div>
                  {filter.type === type.value && (
                    <CheckMarkIcon className="[&_path]:stroke-primary w-3 h-3" />
                  )}
                </button>
              );
            })}
          </div>
          <div className="self-stretch h-[0px] border border-[#F2F2F2]" />
          <div className="self-stretch h-[50px] flex-col justify-start items-start flex">
            <div className="self-stretch [&_input]:!border-[#DEDEDE] [&_.checked]:min-w-4 [&_.checked]:min-h-4 [&_.checked]:w-4 [&_.checked]:h-4 [&_input]:w-4 [&_input]:h-4 h-[25px] px-2 py-1.5 rounded-sm justify-between items-center flex">
              <label
                htmlFor="rooms_ooo"
                className="text-[#0B252A] text-xs font-medium font-['Inter'] leading-3"
              >
                Market Adj.
              </label>
              <Checkbox
                name="market_adj"
                checked={filter.market_adj}
                onChange={() =>
                  setFilter((prev) => ({
                    ...prev,
                    market_adj: !prev.market_adj,
                  }))
                }
                id="market_adj"
                className="!border-[#DEDEDE]"
              />
            </div>
            <div className="self-stretch [&_input]:!border-[#DEDEDE] [&_.checked]:min-w-4 [&_.checked]:min-h-4 [&_.checked]:w-4 [&_.checked]:h-4 [&_input]:w-4 [&_input]:h-4 h-[25px] px-2 py-1.5 rounded-sm justify-between items-center flex">
              <label
                htmlFor="rooms_ooo"
                className="text-[#0B252A] text-xs font-medium font-['Inter'] leading-3"
              >
                Rooms OOO
              </label>
              <Checkbox
                name="rooms_ooo"
                checked={filter.rooms_ooo}
                onChange={() =>
                  setFilter((prev) => ({ ...prev, rooms_ooo: !prev.rooms_ooo }))
                }
                id="rooms_ooo"
                className="!border-[#DEDEDE]"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
