import moment from "moment";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const competitorColors = [  
  "#E0DAE5",

  "#E8CCBF",

  "#d7dedf",
  "#e2e8e9",
  "#d7dedf",
  "#ccd4d5",
  "#c1cacc",
  "#d4ceda",
  "#c8c1cf",
  "#bbb3c4",
  "#aea5b9"


  ]

export type TransformedDataPoint = {
  name: string;
  count: number;
};

/**
 * Transforms the data for the revenue chart
 * @param data - The data for the current year
 * @param prevData - The data for the previous year
 * @param forecastData - The data for the forecasted year
 *
 * @returns
 * [
 *  {
 *      name: "Jan",
 *      onTheBooks: 1000,
 *      previous: 1000,
 *      forecast: 1000,
 *  }
 *  ...
 *  {
 *      name: "Dec",
 *      onTheBooks: 1000,
 *      previous: 1000,
 *      forecast: 1000,
 *  }
 * ]
 */
export const transformData = ({
  data = [],
  currentField,
}: {
  data: any[];
  currentField: string;
}): TransformedDataPoint[] => {
  return data.map((month) => {
    return {
      name: months[Number(month?.month || 0) - 1],
      count: Number.parseFloat(Number(month[currentField] || 0).toFixed(0)),
    };
  });
};

export const transformDataReviews = ({
  data = [],
  currentField,
}: {
  data: any[];
  currentField: string;
}): TransformedDataPoint[] => {
  return data.map((month) => {
    return {
      name: months[Number(month?.month || 0) - 1],
      count: Number(Number(month[currentField] || 0).toFixed(2)),
    };
  });
};

/**
 * [
 * {
 *  name: "Caesars Palace",
 * rates: [{
 * month: "Feb",
 * year: 2025,
 * averageRate: "241.96774193548387"
 * }]
 * }
 * {
 *  name: "MGM Grand",
 * rates: [{
 * month: "Feb",
 * year: 2025,
 * averageRate: "166.28571428571428"
 * }]
 * }
 * ]
 *
 * Output:
 * [
 * {
 *  name: "Feb",
 *  caesars_palace: "242",
 *  mgm_grand: "166"
 * }
 * ]
 *
 */
export const transformCompetitorPrices = ({
  data = [],
  hotelRates = [],
}: {
  data: any;
  hotelRates: any;
}) => {
  let metaInfo = {
    my_hotel: {
      color: "#000000",
      label: "My Hotel",
    },
  };
 //console.log(data, "data");
  const newData: any[] = [];
  const names = data.map((e) => e?.name);
  names.forEach((name, i) => {
    const generateRandomColor = () => {
      let color = competitorColors[i % 7];
      return color;
    };
    metaInfo[name?.toLowerCase().replaceAll(" ", "_")] = {
      color: competitorColors[i % competitorColors.length],
      label: name,
    };
  });
  data[0]?.rates.forEach((rate) => {
    let newObj = {
      name: rate?.date,
    };
    names.forEach((name, index) => {
      const obj = data[index];
      const objRate = obj?.rates.find((e) => e?.date === rate?.date);
      newObj[name?.toLowerCase().replaceAll(" ", "_")] = objRate?.rate;
    });
    newObj["my_hotel"] =
      hotelRates.find((e) => e?.date === rate?.date)?.rate || 0;
    newData.push(newObj);
  });

  return { data: newData, metaInfo };
};

export const transformRevenueByChannelData = ({ data = {} }: { data: any }) => {
 //console.log("REVENUE BY CHANNEL DATA", data);
  const bookingDotCom = data?.bookingDotCom || [];
  const expedia = data?.expedia || [];
  const other = data?.other || [];
  const direct = data?.direct || [];
  const newData: any[] = [];
  bookingDotCom.forEach((month) => {
    const expediaMonth = expedia.find((e) => e?.month === month?.month);
    const otherMonth = other.find((e) => e?.month === month?.month);
    const directMonth = direct.find((e) => e?.month === month?.month);
    newData.push({
      name: months[Number(month?.month || 0) - 1],
      bookingDotCom: Number.parseFloat(
        Number(month?.percentage || 0).toFixed(0)
      ),
      expedia: Number.parseFloat(
        Number(expediaMonth?.percentage || 0).toFixed(0)
      ),
      other: Number.parseFloat(Number(otherMonth?.percentage || 0).toFixed(0)),
      direct: Number.parseFloat(
        Number(directMonth?.percentage || 0).toFixed(0)
      ),
    });
  });
  return newData;
};
